import React, { useEffect, useState, useRef, Suspense, lazy } from 'react'
import { useStyles } from './styles'
import { useSelector, useDispatch } from 'react-redux'
import _, { set, values } from 'lodash'
import FilterListIcon from '@mui/icons-material/FilterList'

import {
  useNavigate,
  createSearchParams,
  useSearchParams,
  useLocation
} from 'react-router-dom'
import { ROUTES } from '../../config/routes'
import {
  AutoCompleteSearch,
  TagFilter,
  Tabs,
  Loader,
  Button,
  Container,
  Section,
  SectionFixed,
  ImageGallery
} from '../../components'
import { Grid, Box, Tooltip, Dialog, IconButton } from '@mui/material'
import { initalizeS3, checkExpiry } from '../../utils/AWS'
import {
  checkUserRoleSuperUser,
  checkUserRoleAdmin,
  checkUserRoleAnnotator,
  checkDomainAccess,
  userIdToName
} from '../../utils/User'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'
import { useColor } from '../../ThemeContext'
import {
  getParticularMasterPeople,
  searchApi,
  getChatSuggestions,
  getAllFileNames,
  getProposalMetaData
} from '../../store/api'
import { ADD_RECENT_SEARCH } from '../../store/actionTypes'
import {
  processSearchData,
  addScreenshotsToSections,
  addVariationsToKnowledges
} from './searchHelper'
import InfiniteScroll from 'react-infinite-scroll-component'
import Result from '../../sections/DashboardResult'
import JustifiedLayout from '@codekraft-studio/react-justified-layout'
import ResourceFileView from '../../sections/ResourceFileView'
import { copyHTMLContent } from '../../utils/CopyHTML'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import { formatResponse } from '../../store/People/Actions/GetPeople/GetPeople'
import Button2 from '../../components/Button/Button2'
import { MagicWand } from '../../components/Icons/Icons'
import Pagination from '@mui/material/Pagination'

const ResumeResult = lazy(() => import('../../sections/ResumeResult'))

const DashboardResult = (props) => {
  const { parent = 'dashboard' } = props
  const isDashboard = parent === 'dashboard'
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const keywordParams = searchParams.get('keyword')
    ? JSON.parse(searchParams.get('keyword'))
    : ''
  const contentIdParams = searchParams.get('content_id')
  const peopleIdParams = searchParams.get('people')
  const page_num = searchParams.get('page_num') || 1
  const searchTypeParams = searchParams.get('searchType') || 'all'

  const isSuperUser = checkUserRoleSuperUser()
  const isAdmin = checkUserRoleAdmin()
  const isAnnotator = checkUserRoleAnnotator()
  const processPeople = checkDomainAccess('people_resume')
  const processClients = checkDomainAccess('client_resume')
  const processProjects = checkDomainAccess('project_resume')
  const tabs = [
    {
      label: 'All',
      key: 'all',
      children: <></>
    },
    {
      label: 'Documents',
      key: 'document',
      children: <></>
    },
    {
      label: 'Knowledge',
      key: 'knowledge',
      children: <></>
    },
    {
      label: 'Assets',
      key: 'asset',
      children: <></>
    }
  ]

  processPeople &&
    tabs.push({
      label: 'People',
      key: 'people',
      children: <></>
    })

  processProjects &&
    tabs.push({
      label: 'Projects',
      key: 'project',
      children: <></>
    })

  const { DASHBOARD_RESULT } = ROUTES
  const classes = useStyles()
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.authenticate)
  const domain = auth?.user?.domain
  const { domain_id = '' } = domain || {}
  const [s3Obj, sets3Obj] = useState({})

  const [imageGallery, setImageGallery] = useState([])
  const [imageGalleryIndex, setImageGalleryIndex] = useState(0)
  const [selectedFile, setSelectedFile] = useState(null)
  const [selectedTags, setSelectedTags] = useState(null)
  const tagsCenterStateTags = useSelector((state) => state?.tagCenter?.tags)
  const [tagsOptions, setTagsOptions] = useState(null)
  const [documentTagCenter, setDocumentTagCenter] = useState(null)
  const [openFileViewer, setOpenFileViewer] = useState(false)
  const [chatSuggestions, setChatSuggestions] = useState([])

  const libraryState = useSelector((state) => state.library)
  const { libraryCollections = {} } = libraryState

  const [activeTab, setActiveTab] = useState(null)
  const [tabOptions, setTabOptions] = useState(tabs)
  const [pageOptions, setPageOptions] = useState({})
  const [error, setError] = useState(null)

  const [currentPageData, setCurrentPageData] = useState({})

  const [page, setPage] = useState(page_num ? parseInt(page_num) : 1)
  const [resultCount, setResultCount] = useState({})

  const resultCountRef = useRef({})
  const abortController = useRef([])

  const [searchLoading, setSearchLoading] = useState(true)

  const [searchKey, setSearchKey] = useState(keywordParams)
  const [searchType, setSearchType] = useState(searchTypeParams)
  const [loadedInitalQuery, setLoadedInitalQuery] = useState(false)
  const [dateTypeTags, setDateTypeTags] = useState([])
  const pageSize = 50

  useEffect(() => {
    const tagsValues = {}
    let documentTags = []
    const dateTypeTags = []
    Object.keys(tagsCenterStateTags || {}).forEach((key, index) => {
      const {
        data = [],
        type,
        value_type,
        ...rest
      } = tagsCenterStateTags[key] || {}
      if (value_type?.includes('document_type')) {
        documentTags = data
      }
      if (type.includes('date')) {
        dateTypeTags.push(key)
      }
      data.forEach((element) => {
        if (element.value) {
          if (tagsValues[key]?.values) {
            tagsValues[key].values.push({
              value: element.id,
              label: element.value
            })
          } else {
            if (!tagsValues[key]) tagsValues[key] = {}
            tagsValues[key].values = [
              {
                value: element.id,
                label: element.value
              }
            ]
          }
        }
        tagsValues[key] = {
          ...tagsValues[key],
          type,
          value_type,
          ...rest
        }
      })
    })

    const collections = []
    const { public_collections = [], private_collections = [] } =
      libraryCollections || {}
    public_collections.forEach((collection) => {
      collections.push({
        value: collection.id,
        label: collection.name,
        type: 'public'
      })
    })
    private_collections.forEach((collection) => {
      collections.push({
        value: collection.id,
        label: collection.name,
        type: 'private'
      })
    })
    tagsValues.knowledge_collections = {
      values: collections,
      type: 'default',
      editable: false
    }
    setDateTypeTags(dateTypeTags)
    setTagsOptions(tagsValues)
    setDocumentTagCenter(documentTags)
  }, [tagsCenterStateTags, libraryCollections])

  const findDocumentTypeTag = (result) => {
    const { tag_center = [], result_type } = result
    let fallbackTag = result_type
    if (result_type === 'section') {
      fallbackTag = result?.document_id ? 'Document' : 'Proposal'
      if (documentTagCenter) {
        fallbackTag = _.get(
          _.find(documentTagCenter, (obj) => _.includes(tag_center, obj.id)),
          'value',
          fallbackTag
        )
      }
    } else if (result_type === 'narrative') {
      fallbackTag = 'Knowledge'
    }
    return fallbackTag
  }
  useEffect(() => {
    console.log('selectedTags', selectedTags)
  }, [selectedTags])

  const handleTagsValueChange = (key, value, mode) => {
    if (mode === 'value') {
      if (_.isEmpty(value)) {
        const temp = { ...selectedTags }
        delete temp[key]
        setSelectedTags(temp)
      } else {
        if (selectedTags) {
          setSelectedTags({
            ...selectedTags,
            [key]: {
              ...selectedTags[key],
              values: value
            }
          })
        } else {
          setSelectedTags({
            [key]: {
              values: value
            }
          })
        }
      }
    }
    if (mode === 'condition') {
      if (selectedTags) {
        setSelectedTags({
          ...selectedTags,
          [key]: {
            ...selectedTags[key],
            condition: value
          }
        })
      } else {
        setSelectedTags({
          [key]: {
            condition: value
          }
        })
      }
    }
  }

  useEffect(() => {
    async function initalizeData() {
      const s3 = await initalizeS3()
      sets3Obj(s3)
    }

    initalizeData()
    return () => {
      clearControllers()
    }
  }, [])

  useEffect(() => {
    if (!_.isEmpty(s3Obj)) {
      if (keywordParams && !loadedInitalQuery) {
        handleChangeTab(searchType, page)
        setLoadedInitalQuery(true)
      } else {
        setSearchLoading(false)
      }
    }
  }, [s3Obj])

  const addSearchParams = (page, searchType) => {
    if (isDashboard) {
      let keyword = ''
      if (searchKey) {
        keyword = JSON.stringify(searchKey)
      }
      if (searchKey) {
        navigate({
          pathname: DASHBOARD_RESULT,
          search: createSearchParams({
            keyword,
            searchType,
            page_num: page,
            ...(contentIdParams ? { content_id: contentIdParams } : {}),
            ...(peopleIdParams ? { people: peopleIdParams } : {})
          }).toString()
        })
      } else {
        navigate({
          pathname: DASHBOARD_RESULT
        })
      }
    }
  }

  const handleNewSearch = () => {
    setCurrentPageData({})
    setResultCount({})
    setChatSuggestions([])
    resultCountRef.current = {}
    handleSearch(1)
  }

  const handleKeyDown = (e, val) => {
    if (e.key !== 'Enter') return
    e.preventDefault()
    e.target.blur()
    if (val?.label) {
      setSearchKey(val?.label)
      handleNewSearch()
      return
    }
    handleNewSearch()
  }

  const clearControllers = () => {
    setError(null)
    setSelectedTags(null)
    setCurrentPageData({})
    setResultCount({})
    setChatSuggestions([])
    resultCountRef.current = {}
    abortController.current.forEach((controller) => {
      controller.abort()
    })
  }

  const addRecentSearch = (searchKey, searchType) => {
    dispatch({
      type: ADD_RECENT_SEARCH,
      payload: { search_keyword: searchKey, search_type: searchType }
    })
  }

  useEffect(() => {
    if (resultCount && !_.isEmpty(resultCount)) {
      const newTabs = [...tabs]
      const pageOptions = {}
      newTabs.forEach((tab, index) => {
        const { key, label } = tab
        newTabs[index].label = `${label} (${resultCount[key]})`
        pageOptions[key] = Math.ceil(resultCount[key] / pageSize)
      })
      setPageOptions(pageOptions)
      setTabOptions(newTabs)
    }
  }, [resultCount])

  useEffect(() => {
    setResultCount({})
    setChatSuggestions([])
    resultCountRef.current = {}
    handleSearch(1)
  }, [selectedTags])

  const handleSearch = async (
    new_page = page,
    searchTypeValue = searchType
  ) => {
    abortController.current.forEach((controller) => {
      controller.abort()
    })

    setError(null)
    setCurrentPageData({})
    setPage(new_page)
    setSearchLoading(true)

    if (searchKey) {
      addSearchParams(new_page, searchTypeValue)
      addRecentSearch(searchKey, searchTypeParams === 'asset' ? 'asset' : 'all')
      const getCount = _.isEmpty(resultCountRef.current)
      const filters = {}
      Object.keys(selectedTags || {}).forEach((key) => {
        const isDate = dateTypeTags.includes(key)
        const data = isDate
          ? selectedTags[key]?.values[0]
          : {
              ...selectedTags[key],
              values: selectedTags[key]?.values?.map((val) => val.value)
            }
        if (key === 'collections_selected') {
          filters.file_collections = {
            ...selectedTags[key],
            values: selectedTags[key]?.values?.map((val) => val.id)
          }
        } else if (key === 'knowledge_collections') {
          filters.library_collections = data
        } else if (isDate) {
          if (filters.tag_dates) {
            filters.tag_dates.push(data)
          } else {
            filters.tag_dates = [data]
          }
        } else {
          if (filters.tags) {
            filters.tags.push(data)
          } else {
            filters.tags = [data]
          }
        }
      })
      const req = {
        search_type: searchTypeValue,
        search_keyword: searchKey,
        page_num: new_page,
        page_size: pageSize
      }
      if (!_.isEmpty(filters)) {
        req.filter_options = filters
      }
      const newController = new AbortController()
      let resultCountObj = resultCountRef.current
      abortController.current = [...abortController.current, newController]
      const response = await searchApi(req, { signal: newController.signal })
      if (response.status === 200) {
        if (getCount && page_num === 1 && searchTypeValue === 'all') {
          const { count: resCount } = response.data
          resultCountRef.current = resCount
          setResultCount(resCount)
          resultCountObj = resCount
        } else if (getCount) {
          const newController2 = new AbortController()
          abortController.current = [...abortController.current, newController2]
          const responseCount = await searchApi(
            {
              ...req,
              search_type: 'all',
              page_num: 1,
              page_size: 1
            },
            { signal: newController2.signal }
          )
          if (responseCount.status === 200) {
            const { count: resCount } = responseCount.data
            resultCountRef.current = resCount
            setResultCount(resCount)
            resultCountObj = resCount
          }
        }
        const data = await processSearchData(response.data, s3Obj)
        console.log('search data', data)
        const proposalIds = []

        data.resultsets.map((result) => {
          if (result.proposal_id) {
            proposalIds.push(result.proposal_id)
          }
          return null
        })

        const proposalMetaResponse = await getProposalMetaData({
          proposal_ids: proposalIds
        })

        const proposalMeta = proposalMetaResponse?.data?.data ?? []

        const proposalMetaObj = {}

        proposalMeta.forEach((proposal) => {
          proposalMetaObj[proposal.proposal_id] = proposal
        })

        console.log('first proposal meta', proposalMetaObj)
        await removeDuplicates(data, resultCountObj?.all, proposalMetaObj)
        if (isDashboard) {
          if (new_page === 1 && searchTypeValue === 'all') {
            trackEvent(mixpanelEvents.DASHBOARD_SEARCH_TEXT, 'SUCCESS', {}, req)
          }
        }
      } else if (response?.code === 'ERR_CANCELED') {
        return
      } else {
        const apiError = response?.response?.data?.message
          ? response.response.data.message
          : 'Something went wrong. Try Again!'
        setError(apiError)
        if (isDashboard) {
          trackEvent(mixpanelEvents.DASHBOARD_SEARCH_TEXT, 'FAILED', {}, req)
        }
      }
      const { resultsets = [], resumeData = [] } = response.data
      if (
        (!_.isEmpty(resultsets) || !_.isEmpty(resumeData)) &&
        new_page === 1 &&
        searchTypeValue === 'all'
      ) {
        const chatSuggestionResponse = await getChatSuggestions({
          search_term: searchKey,
          num_suggestions: 3
        })
        if (chatSuggestionResponse.status === 200) {
          setChatSuggestions(chatSuggestionResponse?.data?.suggestions)
        }
      }
    }
    setSearchLoading(false)
  }

  const removeDuplicates = async (processedData, count, proposalMeta) => {
    return new Promise((resolve, reject) => {
      try {
        const { resultsets, resumesets, duplicates_hidden = 0 } = processedData
        let hidden = duplicates_hidden
        const similar_results = []
        const duplicateIds = new Set()
        const resultsetsProcessed = resultsets.reduce((acc, obj1) => {
          const matchingArrs = similar_results.filter((similar_results) =>
            similar_results.includes(obj1.content_id)
          )
          if (matchingArrs.length > 0) {
            const duplicates = matchingArrs.reduce(
              (duplicates, matchingArr) => {
                const matchingObjs = matchingArr
                  .map((matchingId) =>
                    resultsets.find((obj) => obj.content_id === matchingId)
                  )
                  .filter(
                    (matchingObj) =>
                      matchingObj && matchingObj.content_id !== obj1.content_id
                  )
                return duplicates.concat(matchingObjs)
              },
              []
            )
            const filtered = acc.filter(
              ({ content_id }) =>
                !duplicates.some(({ content_id: id }) => id === content_id)
            )
            filtered.push({
              ...obj1,
              variationsInResult: duplicates
            })
            duplicates.forEach((duplicate) => {
              duplicateIds.add(duplicate.content_id)
              hidden += 1
            })
            return filtered
          } else {
            if (!duplicateIds.has(obj1.content_id)) {
              acc.push(obj1)
            }
            return acc
          }
        }, [])
        const imageFilterData = _.filter(
          resultsetsProcessed,
          (fil) =>
            ['image', 'video'].includes(fil?.result_type) &&
            fil?.aspectRatio &&
            fil?.aspectRatio !== undefined
        )
        const imageAspectRatioArray = imageFilterData.map(
          (asset) => asset?.aspectRatio
        )
        const otherData = _.filter(
          resultsetsProcessed,
          (fil) => !['image', 'video'].includes(fil?.result_type)
        )

        otherData.forEach((result, index) => {
          const { result_type = '', addedScreenShot, addedVariation } = result
          if (proposalMeta[result.proposal_id]) {
            otherData[index].proposalMeta = proposalMeta[result.proposal_id]
          }
          if (result_type === 'section' && !addedScreenShot) {
            const displayTag = findDocumentTypeTag(result)
            otherData[index].displayTag = displayTag
            otherData[index].addedScreenShot = true
            addScreenshotsToSections(
              result,
              index,
              s3Obj,
              domain_id,
              setCurrentPageData
            )
          } else if (result_type === 'narrative' && !addedVariation) {
            otherData[index].addedVariation = true
            addVariationsToKnowledges(
              result,
              index,
              domain_id,
              setCurrentPageData,
              abortController
            )
          }
        })
        const resumeAdded = []
        const newResumeSets = []
        resumesets.forEach((result) => {
          const { master_group_id } = result
          if (newResumeSets) {
            if (!resumeAdded.includes(master_group_id)) {
              resumeAdded.push(master_group_id)
              newResumeSets.push(result)
            }
          } else {
            newResumeSets.push(result)
          }
        })
        const allcount = resultsets?.length + newResumeSets?.length + hidden
        let queryDone = false
        if (allcount >= count) {
          queryDone = true
        }
        const data = {
          assets: imageFilterData,
          assetsAspectRatio: imageAspectRatioArray,
          results: otherData,
          resumes: newResumeSets,
          keyword: searchKey,
          allcount,
          duplicates_hidden: hidden,
          queryDone
        }
        setCurrentPageData(data)
        resolve(data)
      } catch (error) {
        console.log(error)
        reject(null)
      }
    })
  }

  const handleChangeTab = (e, page = 1) => {
    const index = tabOptions.findIndex((tab) => tab.key === e)
    if (index !== -1) {
      changeTab(index, page)
    }
  }

  const changeTab = (e, page) => {
    setSearchLoading(true)
    const tab = tabOptions[e]
    const type = tab.key
    setActiveTab(e)
    setSearchType(type)
    setError(null)
    handleSearch(page, type)
  }

  const handleFilterByProposal = (data) => {}

  const bricks = (array, filteredResults, resultKeyword) => {
    return array.map((item, index) => (
      <Result
        assetStyleProps={item}
        res={filteredResults[index]}
        index={index}
        keyword={resultKeyword}
        key={index}
        s3Obj={s3Obj}
        result_type={filteredResults[index]?.result_type ?? ''}
        overWriteAsset
        disableEdit={isAnnotator}
        showTempDataTag={isSuperUser || isAnnotator}
        handleShowImage={handleImageOpenInGallery}
        setSelectedFile={setSelectedFile}
        selectedFile={selectedFile}
        handleCopy={handleCopy}
        src={parent}
        handleFilterByProposal={handleFilterByProposal}
      />
    ))
  }

  const handleCopy = async (content = '', title = '') => {
    trackEvent(
      mixpanelEvents.DASHBOARD_RESULT_COPIED,
      'SUCCESS',
      {},
      { search_key: JSON.stringify(searchKey) }
    )
    const newContent = `<html><body><b>${title}</b><br>${content}</body></html>`
    copyHTMLContent(newContent)
  }

  const handleImageOpenInGallery = async (screenshots, index) => {
    const { keyword } = currentPageData || {}
    await Promise.all(
      screenshots.map(async (img, index) => {
        const signedSrc = await checkExpiry(img.src, s3Obj)
        screenshots[index].src = signedSrc
      })
    )
    setImageGallery(screenshots)
    setImageGalleryIndex(index)
    trackEvent(
      mixpanelEvents.DASHBOARD_SCREENSHOT_OPENED,
      'SUCCESS',
      {},
      { search_key: keyword }
    )
  }

  const handlePersonSelect = async (person) => {
    const resumeVariations = [person, ...person?.variations]
    const file = {
      id: person?.id,
      file_name: person?.name,
      other_data: {
        resumes: resumeVariations,
        content_id: person?.master_group_id,
        content_type: 'people'
      },
      file_type: 'people'
    }
    setSelectedFile(file)
  }

  const checkAccess = (res) => {
    if (res?.result_type === 'project' && !processProjects) {
      return false
    }
    if (res?.result_type === 'client' && !processClients) {
      return false
    }
    return true
  }

  const renderResults = () => {
    const {
      results = [],
      assets = [],
      assetsAspectRatio = [],
      keyword: resultKeyword = ''
    } = currentPageData || {}
    let { resumes = [] } = currentPageData || {}
    resumes = processPeople ? resumes : []

    if (error) {
      return <Box className={classes.noResultsContainer}>{error}</Box>
    }
    if (searchType === 'all' || searchType === 'text') {
      return (
        <>
          {!_.isEmpty(chatSuggestions) && (
            <>
              <div
                id="suggestions"
                style={{
                  padding: '0px 10px 10px 0px',
                  margin: 'auto'
                }}
                className="flex gap-1 items-center"
              >
                {chatSuggestions?.map((item, index) => (
                  <Tooltip title={item} key={index}>
                    <div
                      id="search-suggestions-options"
                      className={classes.promptbtn}
                      onClick={() => {
                        trackEvent(
                          mixpanelEvents?.DASHBOARD_SEARCH_GENERATIVE_EXPERIENCE_USED,
                          'SUCCESS',
                          {},
                          { search_key: item }
                        )
                        navigate(`/chat/${'new'}`, {
                          state: {
                            searchKey: JSON.stringify(item).replaceAll('"', '')
                          }
                        })
                      }}
                    >
                      {item}
                    </div>
                  </Tooltip>
                ))}
              </div>
              <div
                style={{
                  height: '0px'
                }}
                className="w-full mb-2 border-b-1"
              ></div>
            </>
          )}

          <Suspense fallback={null}>
            <ResumeResult
              people={resumes}
              s3Obj={s3Obj}
              handlePersonSelect={handlePersonSelect}
              showAllPeopleDefault={!!(_.isEmpty(results) && _.isEmpty(assets))}
              viewAllPeople={() => handleChangeTab('people')}
              pageType="dashboard"
            />
          </Suspense>
          {!_.isEmpty(results)
            ? results.map((res, filteredResultsIndex) => {
                return (
                  checkAccess(res) && (
                    <>
                      {['all'].includes(searchType) &&
                        !_.isEmpty(assets) &&
                        ((results.length > 3 && filteredResultsIndex === 3) ||
                          results.length < 3) && (
                          <Box>
                            <Box
                              sx={{
                                marginTop: '15px',
                                fontSize: '18px',
                                fontWeight: '600'
                              }}
                            >
                              Assets for{' '}
                              <Box
                                component={'span'}
                                sx={{ fontStyle: 'italic' }}
                              >
                                {resultKeyword}
                              </Box>
                            </Box>
                            <JustifiedLayout
                              items={assetsAspectRatio}
                              options={{
                                containerPadding: 20,
                                boxSpacing: 10,
                                targetRowHeight: 200,
                                targetRowHeightTolerance: 0.1,
                                maxNumRows: 1
                              }}
                            >
                              {(items) => bricks(items, assets, resultKeyword)}
                            </JustifiedLayout>
                            <Box
                              sx={{
                                marginTop: '5px',
                                display: 'flex',
                                alignItems: 'center'
                              }}
                            >
                              <Box
                                sx={{
                                  flexGrow: 1,
                                  height: '1px',
                                  backgroundColor: '#ccc'
                                }}
                              />
                              <Button
                                variant="outlined"
                                onClick={() => handleChangeTab('asset')}
                              >
                                View all
                              </Button>
                              <Box
                                sx={{
                                  flexGrow: 1,
                                  height: '1px',
                                  backgroundColor: '#ccc'
                                }}
                              />
                            </Box>
                          </Box>
                        )}
                      <Result
                        res={res}
                        result_type={res?.result_type ?? ''}
                        index={filteredResultsIndex}
                        keyword={resultKeyword}
                        key={filteredResultsIndex}
                        s3Obj={s3Obj}
                        disableEdit={isAnnotator}
                        showTempDataTag={isSuperUser || isAnnotator}
                        handleShowImage={handleImageOpenInGallery}
                        setSelectedFile={setSelectedFile}
                        selectedFile={selectedFile}
                        handleCopy={handleCopy}
                        handleFilterByProposal={handleFilterByProposal}
                        src={parent}
                        documentTagCenter={documentTagCenter}
                      />
                    </>
                  )
                )
              })
            : !_.isEmpty(assets) && (
                <Box>
                  <Box
                    sx={{
                      marginTop: '15px',
                      fontSize: '18px',
                      fontWeight: '600'
                    }}
                  >
                    Assets for{' '}
                    <Box component={'span'} sx={{ fontStyle: 'italic' }}>
                      {resultKeyword}
                    </Box>
                  </Box>
                  <JustifiedLayout
                    items={assetsAspectRatio}
                    options={{
                      containerPadding: 20,
                      boxSpacing: 10,
                      targetRowHeight: 200,
                      targetRowHeightTolerance: 0.1,
                      maxNumRows: 1
                    }}
                  >
                    {(items) => bricks(items, assets, resultKeyword)}
                  </JustifiedLayout>
                  <Box
                    sx={{
                      marginTop: '5px',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <Box
                      sx={{
                        flexGrow: 1,
                        height: '1px',
                        backgroundColor: '#ccc'
                      }}
                    />
                    <Button
                      variant="outlined"
                      onClick={() => handleChangeTab('asset')}
                    >
                      View all
                    </Button>
                    <Box
                      sx={{
                        flexGrow: 1,
                        height: '1px',
                        backgroundColor: '#ccc'
                      }}
                    />
                  </Box>
                </Box>
              )}
          {_.isEmpty(results) && _.isEmpty(assets) && _.isEmpty(resumes) && (
            <Box className={classes.noResultsContainer}>No results found</Box>
          )}
        </>
      )
    } else if (
      ['document', 'project', 'client', 'knowledge'].includes(searchType)
    ) {
      return (
        <>
          {results.map((res, filteredResultsIndex) => {
            return (
              checkAccess(res) && (
                <>
                  <Result
                    res={res}
                    result_type={res?.result_type ?? ''}
                    index={filteredResultsIndex}
                    keyword={resultKeyword}
                    key={filteredResultsIndex}
                    s3Obj={s3Obj}
                    disableEdit={isAnnotator}
                    showTempDataTag={isSuperUser || isAnnotator}
                    handleShowImage={handleImageOpenInGallery}
                    setSelectedFile={setSelectedFile}
                    selectedFile={selectedFile}
                    handleCopy={handleCopy}
                    src={parent}
                    handleFilterByProposal={handleFilterByProposal}
                    documentTagCenter={documentTagCenter}
                  />
                </>
              )
            )
          })}
          {_.isEmpty(results) && (
            <Box className={classes.noResultsContainer}>No results found</Box>
          )}
        </>
      )
    } else if (searchType === 'asset') {
      return (
        <>
          <Box sx={{ minHeight: '100%' }}>
            <JustifiedLayout
              items={assetsAspectRatio}
              options={{
                containerPadding: 20,
                boxSpacing: 10,
                targetRowHeight: 200,
                targetRowHeightTolerance: 0.1
              }}
            >
              {(items) => bricks(items, assets, resultKeyword)}
            </JustifiedLayout>
          </Box>
          {_.isEmpty(assets) && (
            <Box className={classes.noResultsContainer}>No results found</Box>
          )}
        </>
      )
    } else if (searchType === 'people') {
      return (
        <Suspense fallback={null}>
          <ResumeResult
            people={resumes}
            s3Obj={s3Obj}
            handlePersonSelect={handlePersonSelect}
            showAllPeopleDefault={true}
            viewAllPeople={() => handleChangeTab('people')}
            pageType="dashboard"
          />
          {_.isEmpty(resumes) && (
            <Box className={classes.noResultsContainer}>No results found</Box>
          )}
        </Suspense>
      )
    }
  }

  const onClose = () => {
    setOpenFileViewer(false)
    setTimeout(() => {
      setSelectedFile(null)
    }, 500)
  }

  useEffect(() => {
    if (selectedFile) setOpenFileViewer(true)
  }, [selectedFile])

  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100'
      }}
    >
      <Container
        className={
          isDashboard ? classes.rootContainer : classes.rootContainerFullWidth
        }
      >
        <SectionFixed>
          <Box
            id="dashboard-searchbar"
            style={{
              position: 'sticky',
              top: 0,
              background: 'white',
              zIndex: '1000'
            }}
          >
            <Box id="dashboard-search-input" className={classes.field}>
              <AutoCompleteSearch
                s3Obj={s3Obj}
                setSearchKey={setSearchKey}
                searchKey={searchKey}
                handleKeyDown={handleKeyDown}
                handleSearch={handleNewSearch}
                showSearchIcon={false}
                disabled={searchLoading}
                initalState={false}
                endAdornment={
                  <Button2
                    primary
                    onClick={() => handleNewSearch()}
                    disabled={searchLoading}
                  >
                    <MagicWand className="size-4" />
                    Search
                  </Button2>
                }
              />
            </Box>
            <Box
              id="dashboard-filter"
              sx={{
                marginBottom: '-10px'
              }}
            >
              <div
                id="search-tabs"
                style={{
                  position: 'relative',
                  overflow: 'auto'
                  // visibility: searchLoading ? 'hidden' : 'visible'
                }}
              >
                <Tabs
                  isFlex
                  activeTab={activeTab}
                  disabled={searchLoading}
                  sectionOverFlow
                  data={tabOptions}
                  tabChangeCallBack={(e) => changeTab(e)}
                  extraContent={
                    <Box className={classes.tabButton}>
                      <TagFilter
                        showButton
                        showTags={false}
                        tags={{ ...tagsOptions }}
                        disabled={searchLoading}
                        selectedTags={selectedTags}
                        onChange={handleTagsValueChange}
                        showCollectionOptions
                        filterIcon={
                          _.isEmpty(selectedTags) && (
                            <Button2
                              secondary
                              style={{
                                margin: '5px 0px',
                                fontSize: '10px',
                                padding: '5px 10px'
                              }}
                            >
                              <FilterListIcon className="size-4 " />
                              Filter
                            </Button2>
                          )
                        }
                        clearFilter={
                          !_.isEmpty(selectedTags) && (
                            <Tooltip title="Clear Filter">
                              <IconButton
                                sx={{ padding: '0px', margin: '5px 0px' }}
                                disabled={searchLoading}
                                disableRipple
                                onClick={() => setSelectedTags({})}
                              >
                                <Box className={classes.filterButton}>
                                  Clear Filter
                                </Box>
                              </IconButton>
                            </Tooltip>
                          )
                        }
                      />
                    </Box>
                  }
                />
              </div>
              {!searchLoading && (
                <Box className={classes.filterContainer}>
                  <TagFilter
                    showButton={false}
                    showCollectionOptions
                    showTags
                    tags={{ ...tagsOptions }}
                    disabled={searchLoading}
                    selectedTags={selectedTags}
                    onChange={handleTagsValueChange}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </SectionFixed>
        <Section overFlow>
          {searchLoading ? (
            <Box className={classes.loaderContainer}>
              <Loader
                loading={searchLoading}
                caption={'loading your search'}
                flex
              />
            </Box>
          ) : (
            <Box
              sx={{
                paddingRight: '10px',
                overflow: 'auto',
                height: '100%'
              }}
            >
              <Box
                sx={{
                  flexGrow: '1',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <Box className={classes.resultWrapper}>{renderResults()}</Box>
              </Box>
            </Box>
          )}
        </Section>
        <SectionFixed>
          <Pagination
            sx={{
              margin: '10px',
              paddingTop: '5px',
              borderTop: 'solid 1px #E5E5E5'
            }}
            siblingCount={1}
            boundaryCount={0}
            page={page}
            count={pageOptions?.[searchType] || 1}
            onChange={(e, value) => {
              setPage(value)
              handleSearch(value, searchType)
            }}
            disabled={searchLoading}
          />
        </SectionFixed>
        <Dialog
          fullScreen
          sx={{ margin: '5vh' }}
          disableEnforceFocus={true}
          open={openFileViewer}
          onClose={onClose}
          PaperProps={{
            style: {
              backgroundColor: 'transparent',
              boxShadow: 'none'
            }
          }}
        >
          <Box className={classes.fileViwerWrapper}>
            <Box sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
              <ResourceFileView
                s3Obj={s3Obj}
                file={selectedFile}
                setFile={setSelectedFile}
                onClose={onClose}
                isSuperUser={isSuperUser}
                isAdmin={isAdmin}
                setImageGallery={setImageGallery}
                onDeleteCallback={(e) => {
                  console.log(e)
                }}
                isModelOpen={openFileViewer}
                onUpdateCallback={(e) => {
                  console.log(e)
                }}
                appendToURL
              />
            </Box>
          </Box>
        </Dialog>
        {imageGallery.length > 0 && (
          <ImageGallery
            images={imageGallery}
            onClose={() => setImageGallery([])}
            options={{ initialViewIndex: imageGalleryIndex }}
          />
        )}
      </Container>
    </Box>
  )
}
export default DashboardResult
