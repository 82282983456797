import { FETCH_LIBRARY_COLLECTIONS } from '../../../actionTypes'
import { getNarrativeCollections } from '../../../api'

const fetchCollections =
  (callback = () => {}, refreshActive = true) =>
  async (dispatch) => {
    if (refreshActive) {
      dispatch({
        type: FETCH_LIBRARY_COLLECTIONS,
        payload: {
          libraryCollections: null
          // activeTag: { name: '', visibility: '' }
        }
      })
    }
    const res = await getNarrativeCollections()
    if (res.status === 200) {
      const {
        public_collections = [],
        private_collections = [],
        suggested_count = 0
      } = res.data
      dispatch({
        type: FETCH_LIBRARY_COLLECTIONS,
        payload: {
          libraryCollections: { public_collections, private_collections },
          suggestedCount: suggested_count
        },
        callback
      })
    }
  }

export { fetchCollections }
