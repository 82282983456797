import { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import RfxPDFView from '../../sections/RfxPDFView'
import { ROUTES } from '../../config/routes'
import { fetchRFx } from '../../store/RFx/Actions'
import Loader from '../../components/Loader'
import { initalizeS3, getSignedUrl } from '../../utils/AWS'
import { isEmpty, cloneDeep } from 'lodash'

const RfxPDFViewer = () => {
  const rfx = useSelector((state) => state?.rfxDocumentsList?.rfx)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { rfxId, pageNo } = useParams()
  const [initialLoad, setInitialLoad] = useState(true)
  const [loading, setLoading] = useState(true)
  const [PDFData, setPDFData] = useState({})
  const [error, setError] = useState('')
  const [s3Obj, sets3Obj] = useState({})
  const [PDFUrl, setPDFUrl] = useState('')

  useEffect(() => {
    dispatch(fetchRFx(rfxId))
    async function initalizeData() {
      const s3 = await initalizeS3()
      sets3Obj(s3)
    }
    initalizeData()
  }, [])

  useEffect(() => {
    async function initalizeData() {
      const newRfxObj = cloneDeep(rfx)

      if (initialLoad && !isEmpty(rfx) && !isEmpty(s3Obj)) {
        let error = false
        let url = await getPDFURL(newRfxObj)
        url = await getSignedUrl(url, s3Obj).catch(() => {
          setError('RFx pdf not found')
          error = true
        })
        setInitialLoad(false)
        if (!error) {
          setPDFUrl(url)
          setLoading(false)
          setPDFData(newRfxObj)
        }
      } else {
        if (!isEmpty(rfx)) {
          setPDFData(newRfxObj)
        }
      }
    }
    initalizeData()
  }, [rfx, s3Obj])

  const getPDFURL = async (data) => {
    const {
      pdf_location,
      compressed_location,
      linearized_compress_location,
      linearized_location
    } = data
    const uri =
      linearized_compress_location ||
      compressed_location ||
      linearized_location ||
      pdf_location

    return uri
  }

  const handlePdfBack = () => {
    navigate(-1)
  }

  if (loading) {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{ width: '100%', height: '100vh' }}
      >
        <Loader loading={loading} caption="opening your rfx" />
      </Grid>
    )
  }

  return (
    <Fragment>
      <RfxPDFView
        setShowPDF={handlePdfBack}
        PDFData={PDFData}
        PDFUrl={PDFUrl}
        pageNo={pageNo}
      />
    </Fragment>
  )
}

export default RfxPDFViewer
