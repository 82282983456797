import { useState, useEffect, useReducer } from 'react'
import Dialog from '@mui/material/Dialog'
import CreateLibraryMeta from '../CreateLibraryMeta'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'

const AddToLibrary = (props) => {
  const {
    libraryData = {},
    currentCollection = {},
    show,
    setShow,
    onCreateCallback = () => {},
    redirectOnCreate = false,
    setUnSavedChanges = () => {}
  } = props
  const { htmlString, metaData = {}, title = '' } = libraryData

  return (
    <Modal
      fullWidth={true}
      disableEscapeKeyDown
      open={show}
      onClose={() => setShow(false)}
      sx={{
        padding: '100px',
        borderRadius: '10px'
      }}
    >
      <Box
        className="libContainer"
        sx={{
          width: '100%',
          height: '100%',
          background: 'white',
          borderRadius: '10px'
        }}
      >
        <CreateLibraryMeta
          openEditor
          htmlString={htmlString}
          activeCollection={currentCollection}
          onClose={() => setShow(false)}
          metaData={metaData}
          title={title}
          redirectOnCreate={redirectOnCreate}
          onCreateCallback={() => {
            onCreateCallback(libraryData)
          }}
          setUnSavedChanges={setUnSavedChanges}
        />
      </Box>
    </Modal>
  )
}

export default AddToLibrary
