import { makeStyles } from '@mui/styles'
import { useColor } from '../../ThemeContext'

const useStyles = makeStyles((theme) => {
  const { selectedColor } = useColor() // Get the selected color from the context

  return {
    rootContainer: {
      // background: 'var(--grey-800)',
      paddingLeft: '6px',
      paddingRight: '3px',
      borderRadius: '4px',
      marginRight: '5px',
      // border: '1px solid var(--grey-300)',
      // marginTop: '10px'
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid var(--grey-300)',
        borderRadius: '8px'
      },
    },
    radioSelect: {
      '& .MuiInputBase-input': {
        padding: '3px 32px 3px 11px',
        fontSize: '12px',
        background: 'white'
      },
      '& .MuiInputBase-root': {
        '& .MuiSelect-multiple': {
          paddingTop: '3px !important',
          paddingBottom: '3px !important',
        }
      }
    },
    fullWidthContainer: {
      width: '40%'
    },
    fullWidthWrapper: {
      width: '100%'
    },
    /* imageSetter:{
    width:"10%"
  }, */
    closeIcon: {
      padding: '0px 3px 2px 0 !important',
      color: 'red',
    },
    multiSelect: {
      marginLeft: '3px',
      '& .MuiInputBase-root': {
        '& .MuiSelect-multiple': {
          paddingTop: '3px !important',
          paddingBottom: '3px !important',
          fontSize: '12px'
        }
      }
    },
    imageWrapper: {
      marginLeft: '2px',
    },
    wrapper: {
      fontSize: '10px'
      // paddingTop: '5px',
      // paddingBottom: '5px'
    }
  }
})

export { useStyles }
