import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useStyles } from './styles'
import Button from '@mui/material/Button'
import { Grid } from '@mui/material'
import Button2 from '../Button/Button2'

const useConfirmation = () => {
  const [confirmConfig, setConfirmConfig] = useState(null)
  const [isConfirmVisible, setConfirmVisible] = useState(false)
  const classes = useStyles()
  const {
    actionContianer,
    cancelButton,
    submitButton,
    dialogHeader,
    dialogContents,
    contentWrapper
  } = classes

  const showConfirmDialog = (props) => {
    const {
      onConfirm,
      onCancel,
      deleteType = false,
      confirmationMessageTitle = 'Are you sure?',
      confirmationMessage = '',
      confirmButtonText = "Yes, I'm sure",
      cancelButtonText = 'Cancel',
      extraButtons = []
    } = props || {}
    setConfirmConfig({
      onConfirm,
      onCancel,
      deleteType,
      confirmationMessageTitle,
      confirmationMessage,
      confirmButtonText,
      cancelButtonText,
      extraButtons
    })
    setConfirmVisible(true)
  }

  const {
    onConfirm = () => {},
    onCancel = () => {},
    deleteType,
    confirmationMessageTitle = 'Are you sure?',
    confirmationMessage = '',
    confirmButtonText = "Yes, I'm sure",
    cancelButtonText = 'No',
    extraButtons = []
  } = confirmConfig || {}

  const handleConfirm = () => {
    onConfirm()
    setConfirmVisible(false)
  }

  const handleCancel = () => {
    onCancel()
    setConfirmVisible(false)
  }

  return {
    isConfirmVisible,
    showConfirmDialog,
    ConfirmDialog: (
      <Dialog open={isConfirmVisible} onClose={handleCancel}>
        <Grid className={contentWrapper}>
          <DialogTitle id="responsive-dialog-title" className={dialogHeader}>
            {confirmationMessageTitle}
          </DialogTitle>
          <div className="text-grey-600">
            <DialogContentText className={dialogContents}>
              {confirmationMessage}
            </DialogContentText>
          </div>
          <div
            className={actionContianer}
            style={{
              marginTop: '14px'
            }}
          >
            <Button2
              secondary
              noOutline
              onClick={(e) => {
                e.stopPropagation()
                handleCancel()
              }}
            >
              {cancelButtonText}
            </Button2>
            {deleteType ? (
              <Button2
                secondary={deleteType}
                noOutline
                style={{
                  backgroundColor: '#ef4444',
                  color: 'white'
                }}
                onClick={(e) => {
                  e.stopPropagation()
                  handleConfirm()
                }}
              >
                {confirmButtonText}
              </Button2>
            ) : (
              <Button2
                primary={!deleteType}
                secondary={deleteType}
                style={
                  {
                    // background: deleteType ? '#ff5252 important' : '',
                    // color: 'white',
                    // border: 'none !important'
                  }
                }
                onClick={(e) => {
                  e.stopPropagation()
                  handleConfirm()
                }}
              >
                {confirmButtonText}
              </Button2>
            )}
            {extraButtons.map((button, index) => (
              <Button
                key={index}
                id={button.id}
                onClick={(e) => {
                  e.stopPropagation()
                  button.onClick()
                  setConfirmVisible(false)
                }}
                className={submitButton}
              >
                {button.text}
              </Button>
            ))}
          </div>
        </Grid>
      </Dialog>
    )
  }
}

export default useConfirmation
