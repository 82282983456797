import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import {
  LexicalTypeaheadMenuPlugin,
  TypeaheadOption,
  useBasicTypeaheadTriggerMatch
} from '@lexical/react/LexicalTypeaheadMenuPlugin'
import { MutableRefObject, useCallback, useMemo, useState, useRef } from 'react'
import {
  $isRangeSelection,
  $insertNodes,
  $isRootOrShadowRoot,
  TextNode,
  $getSelection,
  $getRoot,
  $createTextNode,
  $createParagraphNode
} from 'lexical'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { $wrapNodeInElement } from '@lexical/utils'
import { useSelector } from 'react-redux'
import PostAddIcon from '@mui/icons-material/PostAdd'
import Popover from '@mui/material/Popover'
import Box from '@mui/material/Box'
import Button from '../../../../components/Button'
import { generateAPIPrompt } from '../../../../store/api'
import Loader from '../../../../components/Loader'
import {
  Container,
  Section,
  SectionFixed
} from '../../../../components/Container'
import { ReactComponent as Robot } from '../../../../assets/images/robot.svg'
import SvgIcon from '@material-ui/core/SvgIcon'
import _, { isEmpty } from 'lodash'
import TextIncreaseIcon from '@mui/icons-material/TextIncrease'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import CloseIcon from '@mui/icons-material/Close'
import CopyButton from '../../../../components/CopyButton'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import Tooltip from '@mui/material/Tooltip'
import { textToHtml, htmlToText, stripHTML } from '../../../../utils/CopyHTML'
import trackEvent from '../../../../utils/TrackEvent/TrackEvent'
import eventMapping from '../../../../config/eventMapping'
import TextField from '@mui/material/TextField'
import Draggable from 'react-draggable'
import mixpanelEvents from '../../../../config/mixpanelEvents'
import NewMentionsPlugin from '../MentionsPlugin'

type GenerateType = {
  promptType: string
  placeholderText: string[]
  placeholder: string
  label: string
  name: string
  keywords: string[]
  prompt: string[]
  followUpPrompt: null | string[]
  button_text?: string
  help_text?: string
}
class ComponentPickerOption extends TypeaheadOption {
  title: string
  icon?: JSX.Element
  keywords: Array<string>
  keyboardShortcut?: string
  onSelect: (queryString: string | null) => GenerateType
  constructor(
    title: string,
    options: {
      icon?: JSX.Element
      keywords?: Array<string>
      keyboardShortcut?: string
      onSelect: (queryString: string | null) => GenerateType
    }
  ) {
    super(title)
    this.title = title
    this.keywords = options.keywords || []
    this.icon = options.icon
    this.keyboardShortcut = options.keyboardShortcut
    this.onSelect = options.onSelect.bind(this)
  }
}

const ComponentPickerMenuItem = ({
  index,
  isSelected,
  onClick,
  onMouseEnter,
  option
}: {
  index: number
  isSelected: boolean
  onClick: (e: any) => void
  onMouseEnter: () => void
  option: ComponentPickerOption
}) => {
  return (
    <Box
      key={option.key}
      tabIndex={-1}
      sx={{
        border: '1px solid transperent',
        height: '30px',
        background: isSelected ? '#eee' : '',
        cursor: 'pointer'
      }}
      ref={option.setRefElement}
      role="option"
      aria-selected={isSelected}
      id={'typeahead-item-' + index}
      onMouseEnter={onMouseEnter}
      onClick={(e) => {
        onClick(e)
      }}
      onFocus={onMouseEnter}
    >
      <Box
        sx={{
          display: 'flex',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        {option.icon}
        <Box sx={{ marginLeft: '5px' }}>{option.title}</Box>
      </Box>
    </Box>
  )
}

type userState = {
  authenticate: {
    user: {
      domain: {
        domain_id: string
      }
    }
  }
  common: {
    prompts: Array<GenerateType>
  }
}

type QueryMatch = {
  leadOffset: number
  matchingString: string
  replaceableString: string
}
interface AIPluginInferface {
  textPromptMode?: boolean
  selectPromptMode?: boolean
  boxAnchor?: any
  selectedText?: string
  cancelMarkings?: () => void
}

const defaultPrompt: GenerateType = {
  label: 'Generate Content',
  name: 'Generate',
  keywords: ['generate', 'create', 'start'],
  prompt: ['Question:%prompt%\\nAnswer'],
  placeholderText: ['Write a poem', 'Write a haiku', 'Write a blogpost'],
  placeholder: 'Write a poem',
  promptType: 'create',
  followUpPrompt: [],
  button_text: 'Generate',
  help_text: 'Generate'
}

const AIGeneratePlugin = ({
  textPromptMode,
  selectPromptMode,
  boxAnchor,
  selectedText = '',
  cancelMarkings
}: AIPluginInferface): JSX.Element => {
  const [editor] = useLexicalComposerContext()
  const editorEditable = editor!.isEditable()
  const [queryString, setQueryString] = useState<string | null>(null)
  const [anchorEl, setAnchorEl] = React.useState<any>(null)
  const [generateType, setGenerateType] = React.useState<GenerateType | null>(
    null
  )
  const [loading, setLoading] = React.useState(false)
  const [inputText, setInputText] = React.useState('')
  const [inputPrompt, setInputPrompt] = React.useState('')
  const [resultText, setResultText] = useState('')
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [propsAnchor, setPropsAnchor] = React.useState<any>(null)
  const [selectPromptSelected, setSelectPromptSelected] = React.useState<
    number | null
  >(null)
  const [followUpsPrompts, setFollowUpsPrompts] = React.useState<
    Array<GenerateType>
  >([])
  const checkForTriggerMatch = useBasicTypeaheadTriggerMatch('/', {
    minLength: 0
  })
  const auth = useSelector((userState: userState) => userState?.authenticate)
  const domain = auth?.user?.domain
  const { domain_id } = domain || {}
  const common = useSelector((userState: userState) => userState?.common)
  const { prompts = [] } = common
  const handleClose = () => {
    setAnchorEl(null)
    setGenerateType(null)
    setInputText('')
    setResultText('')
    setInputPrompt('')
    setPropsAnchor('')
    setFollowUpsPrompts([])
    setSelectPromptSelected(null)
    setQueryString('')
    if (cancelMarkings) {
      cancelMarkings()
    }
  }

  const handleGenerate = async (inputText: string, generateType: any) => {
    if (inputText && generateType) {
      setLoading(true)
      document.getElementById('generate_button')?.focus()
      const prompt =
        generateType?.prompt[
          Math.floor(Math.random() * generateType?.prompt.length)
        ]
      const userprompt = prompt.replace('%prompt%', inputText).trim()
      const strippedText = htmlToText(userprompt)
      const promptPrefix = generateType.label
      setInputPrompt(promptPrefix)

      const res = await generateAPIPrompt(strippedText, domain_id)
      if (res?.data?.status === 200) {
        const results = res?.data?.results?.results
          ? res.data.results.results
          : res.data.results
        if (results) {
          setResultText(results)
          // trackEvent(eventMapping["AI_GENERATE"], "SUCCESS", {}, {})
          trackEvent(mixpanelEvents.AI_GENERATE_USED, 'SUCCESS', {}, {})
          if (generateType.followUpPrompt) {
            const fup: any[] = []
            generateType.followUpPrompt.forEach((i: string) => {
              const val = _.find(prompts, { id: i })
              if (val) {
                fup.push(val)
              }
            })
            if (!_.isEmpty(fup)) {
              setFollowUpsPrompts(fup)
            }
          }
        } else {
          // trackEvent(eventMapping["AI_GENERATE"], "FAILED", {}, {})
          trackEvent(mixpanelEvents.AI_GENERATE_USED, 'FAILED', {}, {})
          setResultText(res?.data?.message || 'Generation Failed')
        }
      } else {
        // trackEvent(eventMapping["AI_GENERATE"], "FAILED", {}, {})
        trackEvent(mixpanelEvents.AI_GENERATE_USED, 'FAILED', {}, {})
        setResultText(res?.data?.message || 'Generation Failed')
      }
      setLoading(false)
      const element = document.activeElement as HTMLElement
      element && element?.blur()
    }
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const options = useMemo(() => {
    let optionsArray = prompts
    if (optionsArray?.length === 0) {
      optionsArray = [defaultPrompt]
    }
    let optionsList = []
    const promptOptions = _.filter(optionsArray, { promptType: 'create' })
    const selectOptions = _.filter(optionsArray, { promptType: 'select' })
    if (textPromptMode) {
      optionsList = [...promptOptions, ...selectOptions]
    } else {
      optionsList = [...selectOptions, ...promptOptions]
    }
    const listDisplayOrder = [
      'Create Bullet Points',
      'Create Title',
      'Make Longer',
      'Make Shorter',
      'Rewrite',
      'Simplify',
      'Summarize',
      'Harmonize',
      'Remove Line Breaks'
    ]
    const typeOrderMap = new Map()
    listDisplayOrder.forEach((key, index) => {
      typeOrderMap.set(key, index)
    })

    const hiddenList = ['Improve Content', 'Grammer Check']
    /* let baseOptions = [] */
    // Sort array1 based on the order in array2
    optionsList.sort((a, b) => {
      const orderA = typeOrderMap.get(a.name)
      const orderB = typeOrderMap.get(b.name)
      return orderA - orderB
    })

    optionsList = optionsList.filter((obj) =>
      listDisplayOrder.includes(obj.name)
    )

    let baseOptions: ComponentPickerOption[] = []
    baseOptions = [
      ...optionsList.map(
        (gen) =>
          new ComponentPickerOption(gen.name, {
            icon: <PostAddIcon />,
            keywords: gen.keywords,
            onSelect: () => {
              let randomPlaceHolder =
                gen?.placeholderText[
                  Math.floor(Math.random() * gen?.placeholderText.length)
                ]
              randomPlaceHolder = randomPlaceHolder || 'Enter a prompt'
              const newGen = { ...gen, placeholder: randomPlaceHolder }
              setGenerateType(newGen)
              return newGen
            }
          })
      )
    ]
    let defaultQueryStr = 'Write your own prompt'
    let defaultpromptStr = '%prompt%'
    let defaultKeyword = queryString || ''
    if (selectPromptMode) {
      defaultQueryStr = queryString || 'Write your own prompt'
      defaultpromptStr = `${queryString || ''} \n%prompt%`
      defaultKeyword = queryString || ''
    }

    baseOptions = [
      ...baseOptions,
      new ComponentPickerOption(defaultQueryStr, {
        icon: <TextIncreaseIcon />,
        keywords: [defaultKeyword],
        onSelect: () => {
          let randomPlaceHolder =
            defaultPrompt?.placeholderText[
              Math.floor(Math.random() * defaultPrompt?.placeholderText.length)
            ]
          randomPlaceHolder = randomPlaceHolder || 'Enter a prompt'
          const newGen = {
            ...defaultPrompt,
            prompt: [defaultpromptStr],
            placeholder: randomPlaceHolder
          }
          setGenerateType(newGen)
          return newGen
        }
      })
    ]
    return queryString
      ? [
          ...baseOptions.filter((option) => {
            return new RegExp(queryString, 'gi').exec(option.title) ||
              option.keywords != null
              ? option.keywords.some((keyword) =>
                  new RegExp(queryString, 'gi').exec(keyword)
                )
              : false
          })
        ]
      : baseOptions
  }, [editor, queryString, anchorEl, textPromptMode])

  const onSelectOption = useCallback(
    (
      selectedOption: ComponentPickerOption,
      nodeToRemove: TextNode | null,
      closeMenu: () => void,
      matchingString: string
    ) => {
      editor.update(() => {
        if (nodeToRemove) {
          nodeToRemove.remove()
        }
        if (selectedOption?.ref && selectedOption.ref.current?.offsetParent) {
          const { offsetLeft, offsetTop } = selectedOption.ref.current
            ?.offsetParent as HTMLElement
          setAnchorEl({ clientX: offsetLeft, clientY: offsetTop })
        }
        selectedOption.onSelect(matchingString)
        closeMenu()
      })
    },
    [editor]
  )

  const handleInsert = () => {
    if (textPromptMode) {
      editor.update(() => {
        const textNode = $createTextNode(resultText)
        $insertNodes([textNode])
        if ($isRootOrShadowRoot(textNode.getParentOrThrow())) {
          $wrapNodeInElement(textNode, $createParagraphNode).selectEnd()
        }
      })
    } else {
      editor.update(() => {
        const selection = $getSelection()
        if ($isRangeSelection(selection)) {
          const selectedNodes = selection.getNodes()
          const selectedNodesLength = selectedNodes.length
          const lastIndex = selectedNodesLength - 1
          const lastNode = selectedNodes[lastIndex]
          const textNode = $createTextNode(resultText)
          lastNode.insertAfter($createParagraphNode().append(textNode))
        }
      })
    }
    handleClose()
  }

  const handleInsertBottom = () => {
    editor.update(() => {
      const root = $getRoot()
      const textNode = $createTextNode(resultText)
      root.append($createParagraphNode().append(textNode))
    })
    handleClose()
  }

  const handleReplace = () => {
    editor.update(() => {
      const textNode = $createTextNode(resultText)
      $insertNodes([textNode])
      if ($isRootOrShadowRoot(textNode.getParentOrThrow())) {
        $wrapNodeInElement(textNode, $createParagraphNode).selectEnd()
      }
      handleClose()
    })
  }

  const handleReGenerate = () => {
    handleGenerate(inputText, generateType)
  }

  const insertNewLineAtCursor = (
    contentEditableref: React.RefObject<HTMLDivElement>
  ) => {
    if (contentEditableref.current) {
      const selection = window.getSelection()
      if (selection) {
        const range = selection.getRangeAt(0)
        const br = document.createElement('br')
        range.insertNode(br)
        range.setStartAfter(br)
        range.setEndAfter(br)
        selection.removeAllRanges()
        selection.addRange(range)
      }
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Enter' && e?.shiftKey) {
      e.preventDefault()
      /* insertNewLineAtCursor(contentEditableref);  */
    } else if (e.key === 'Enter') {
      const textContent = (e.target as HTMLElement).textContent
      if (textContent) {
        handleGenerate(textContent || '', generateType)
        e.preventDefault()
      } else {
        setInputText(generateType?.placeholder || '')
        e.preventDefault()
      }
    }
  }

  React.useEffect(() => {
    if (selectPromptMode && !open) {
      document.addEventListener('keydown', handleKeyDownListViewer)
      return () => {
        document.removeEventListener('keydown', handleKeyDownListViewer)
      }
    }
  }, [selectPromptSelected, open, selectPromptMode, options])

  React.useEffect(() => {}, [inputPrompt])

  const handleKeyDownListViewer = (e: KeyboardEvent) => {
    if (e.key === 'ArrowDown') {
      let selected =
        selectPromptSelected === null ? 0 : selectPromptSelected + 1
      if (selected > options.length - 1) {
        selected = 0
      }
      setSelectPromptSelected(selected)
      scrollIntoViewIfNeeded(selected)
    } else if (e.key === 'ArrowUp') {
      const lastIndex = options.length - 1
      const selected =
        selectPromptSelected === null || selectPromptSelected === 0
          ? lastIndex
          : selectPromptSelected - 1
      setSelectPromptSelected(selected)
      scrollIntoViewIfNeeded(selected)
    } else if (e.key === 'Enter') {
      if (selectPromptSelected !== null && selectPromptSelected > -1) {
        setAnchorEl(boxAnchor)
        const textSelected = selectedText || ''
        const option = options[selectPromptSelected]
        const genSelected = option.onSelect(null)

        if (option.title !== 'Write your own prompt') {
          setInputText(textSelected)
          handleGenerate(textSelected, genSelected)
        } else {
          if (textSelected === '') {
            setInputText('\n')
          } else {
            setInputText(`\n\n"""${textSelected}"""`)
          }
          setInputPrompt('Write your own prompt')
        }
      }
    }
  }

  const scrollIntoViewIfNeeded = (index: number) => {
    const target = document.getElementById('typeahead-item-' + index)
    const container = document.getElementById('typeahead-menu')
    if (container && target) {
      const containerRect = container.getBoundingClientRect()
      const targetRect = target.getBoundingClientRect()
      if (targetRect.bottom > containerRect.bottom) {
        target.scrollIntoView(false)
      } else if (targetRect.top < containerRect.top) {
        target.scrollIntoView()
      }
    }
  }

  const editorElement = document.getElementsByClassName(
    'ContentEditable__root'
  )?.[0]
  const editorBounds = editorElement?.getBoundingClientRect()
  const { x = 0, width = 400, height = 0 } = editorBounds || {}

  let topOffset = 0
  let leftOffset = 0

  if (selectPromptMode) {
    const { current = {} } = anchorEl || {}
    topOffset = current?.style?.top ? parseInt(current.style?.top) : 100
    leftOffset = x + 28
  } else {
    const { clientY = 0 } = anchorEl || {}
    topOffset = clientY + 25
    leftOffset = x + 28
  }

  if (topOffset + 530 > height) {
    topOffset = height - 530
  }

  React.useEffect(() => {
    if (boxAnchor?.current && !propsAnchor && isEmpty(propsAnchor)) {
      const boxListAnchorTop = boxAnchor?.current?.style?.top
        ? parseInt(boxAnchor.current.style.top)
        : 0
      let boxListAnchorLeft = boxAnchor?.current?.style?.left
        ? parseInt(boxAnchor.current.style.left)
        : 0
      if (boxListAnchorLeft < leftOffset) {
        boxListAnchorLeft = leftOffset
      }
      setPropsAnchor({ top: boxListAnchorTop, left: boxListAnchorLeft })
    } else if (
      boxAnchor?.clientY &&
      boxAnchor?.clientX &&
      isEmpty(propsAnchor)
    ) {
      const boxListAnchorTop = boxAnchor?.clientY
        ? parseInt(boxAnchor.clientY)
        : 0
      let boxListAnchorLeft = boxAnchor?.clientX
        ? parseInt(boxAnchor.clientX)
        : 0
      if (boxListAnchorLeft < leftOffset) {
        boxListAnchorLeft = leftOffset
      }
      setPropsAnchor({ top: boxListAnchorTop, left: boxListAnchorLeft })
    }
  }, [])

  React.useEffect(() => {}, [loading])

  const checkAnchor = (current: any) => {
    let anchorTop = current?.style?.top ? parseInt(current.style.top) : 0
    let anchorLeft = current?.style?.left ? parseInt(current.style.left) : 0
    if (anchorLeft < leftOffset) {
      anchorLeft = leftOffset
    }
    if (anchorTop + 250 > height) {
      anchorTop = height - 250
    }
    // const style = current.getAttribute('style')
    // if (!style?.includes('z-index')) {
    //   current.setAttribute('style', 'z-index:1')
    // }
    current.setAttribute(
      'style',
      `top: ${anchorTop}px; left: ${anchorLeft}px; position:absolute`
    )
    // current.setAttribute('style', 'z-index:1')
    return current
  }

  const handleInputChanges = (input: any) => {
    setInputText(input)
  }

  return (
    <>
      {/* <Popover
          open={open}
          anchorReference="anchorPosition"
          anchorPosition={{ left: leftOffset, top: topOffset }}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        > */}
      {open && (
        <Draggable defaultPosition={{ x: 100, y: 200 }} bounds="body">
          <div
            onClick={(e) => {
              e.stopPropagation()
            }}
            style={{
              position: 'absolute',
              left: position.x,
              top: position.y,
              cursor: 'all-scroll',
              width: '65%',
              zIndex: '10000',
              borderRadius: '5px',
              background: 'white',
              boxShadow:
                'rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px'
            }}
          >
            <Box sx={{ padding: '10px' }}>
              <Container>
                <SectionFixed>
                  <Box
                    sx={{
                      marginBottom: '5px',
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <span
                      style={{
                        marginRight: '5px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <SvgIcon>
                        <Robot />
                      </SvgIcon>
                      <span style={{ marginLeft: '5px', marginTop: '3px' }}>
                        {inputPrompt && inputPrompt}
                      </span>
                    </span>
                    <div>
                      <CloseIcon
                        sx={{ cursor: 'pointer' }}
                        onClick={() => handleClose()}
                      />
                    </div>
                  </Box>
                </SectionFixed>
                <Section overFlow>
                  <ResultBox
                    inputText={inputText}
                    focusOn={!loading}
                    setInputText={handleInputChanges}
                    generateType={generateType}
                    handleKeyDown={handleKeyDown}
                    background={'#fff'}
                    editState={true}
                    expandState={true}
                    hideExpandOnBlur
                    pageType={'lexical'}
                    focusAuto={
                      inputPrompt === 'Write your own prompt' ? 'start' : 'end'
                    }
                  />
                  {loading ? (
                    <Box
                      sx={{
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        marginTop: '20px'
                      }}
                    >
                      <Loader
                        loading={loading}
                        caption={'generating content'}
                      />
                    </Box>
                  ) : (
                    <>
                      {resultText && (
                        <ResultBox
                          focusOn={false}
                          pageType={''}
                          inputText={resultText}
                          setInputText={setResultText}
                          handleKeyDown={() => {}}
                          background={'#fff'}
                          editState={false}
                          animatedText
                          expandState={true}
                        />
                      )}
                      {followUpsPrompts.length > 0 && (
                        <Box sx={{ display: 'flex' }}>
                          {followUpsPrompts.map((prm, index) => (
                            <Box
                              key={index}
                              sx={{
                                width: 'fit-content',
                                background: '#eaeaea',
                                border: '0.5px solid #949494',
                                borderRadius: '8px',
                                padding: '5px 12px',
                                margin: '10px 5px',
                                color: '#000000',
                                fontStyle: 'italic',
                                cursor: 'pointer',
                                backdropFilter: 'blur(2px)',
                                display: 'flex',
                                alignItems: 'center'
                              }}
                              onClick={() => {
                                setGenerateType(prm)
                                handleGenerate(resultText, prm)
                              }}
                            >
                              {prm.label}
                            </Box>
                          ))}
                        </Box>
                      )}
                    </>
                  )}
                </Section>
                <SectionFixed>
                  <Box
                    sx={{
                      marginTop: '10px',
                      display: 'flex',
                      justifyContent: 'start'
                    }}
                  >
                    <Button
                      disabled={loading}
                      id="generate_button"
                      onClick={() => handleReGenerate()}
                    >
                      {resultText
                        ? 'Regenerate'
                        : generateType?.button_text
                        ? generateType?.button_text
                        : 'Generate'}
                    </Button>
                    {!loading && resultText && (
                      <>
                        {selectPromptMode && selectedText && editorEditable && (
                          <Button
                            variant="outline"
                            disabled={loading}
                            onClick={() => handleReplace()}
                            sx={{ marginLeft: '10px', border: '1px solid' }}
                          >
                            Replace
                          </Button>
                        )}
                        {editorEditable && (
                          <Button
                            variant="outline"
                            disabled={loading}
                            onClick={() => handleInsert()}
                            sx={{ marginLeft: '10px', border: '1px solid' }}
                          >
                            Insert
                          </Button>
                        )}
                      </>
                    )}
                    {editorEditable && (
                      <Button
                        variant="outline"
                        disabled={loading}
                        onClick={() => handleClose()}
                        sx={{ marginLeft: '10px', border: '1px solid' }}
                      >
                        Discard
                      </Button>
                    )}
                    {resultText && (
                      <CopyButton
                        style={{ marginLeft: '10px' }}
                        content={resultText}
                      />
                    )}
                  </Box>
                </SectionFixed>
              </Container>
            </Box>
          </div>
        </Draggable>
      )}
      {/* </Popover> */}
      {textPromptMode ? (
        <LexicalTypeaheadMenuPlugin<ComponentPickerOption>
          onQueryChange={setQueryString}
          onSelectOption={onSelectOption}
          triggerFn={checkForTriggerMatch}
          options={options}
          menuRenderFn={(
            anchorElementRef,
            { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex }
          ) =>
            anchorElementRef.current && options?.length
              ? ReactDOM.createPortal(
                  <Box
                    sx={{
                      padding: '10px',
                      width: '250px',
                      background: 'white',
                      boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.3)',
                      border: '1px solid lightgrey',
                      marginTop: '25px'
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        color: 'black',
                        alignItems: 'center',
                        marginBottom: '10px',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Box>AI Generate</Box>
                      <SvgIcon>
                        <Robot />
                      </SvgIcon>
                    </Box>
                    <Box
                      style={{
                        maxHeight: '300px',
                        overflowY: 'auto',
                        background: '#fff'
                      }}
                    >
                      {options.map((option, i: number) => (
                        <ComponentPickerMenuItem
                          index={i}
                          isSelected={selectedIndex === i}
                          onClick={(e) => {
                            setAnchorEl(e)
                            setHighlightedIndex(i)
                            selectOptionAndCleanUp(option)
                          }}
                          onMouseEnter={() => {
                            setHighlightedIndex(i)
                          }}
                          key={option.key}
                          option={option}
                        />
                      ))}
                    </Box>
                  </Box>,
                  checkAnchor(anchorElementRef.current)
                )
              : null
          }
        />
      ) : (
        selectPromptMode &&
        propsAnchor && (
          <Popover
            open={propsAnchor && !open}
            anchorReference="anchorPosition"
            anchorPosition={propsAnchor}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
          >
            <Box
              sx={{
                padding: '10px',
                width: '250px',
                background: 'white',
                boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.3)',
                border: '1px solid lightgrey'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  color: 'black',
                  alignItems: 'center',
                  marginBottom: '10px',
                  justifyContent: 'space-between'
                }}
              >
                <Box>AI Generate</Box>
                <SvgIcon>
                  <Robot />
                </SvgIcon>
              </Box>

              <Box
                style={{
                  maxHeight: '300px',
                  overflowY: 'auto',
                  background: '#fff'
                }}
                id={'typeahead-menu'}
              >
                {options.map((option, i: number) => (
                  <ComponentPickerMenuItem
                    index={i}
                    isSelected={selectPromptSelected === i}
                    onClick={(e) => {
                      setAnchorEl(boxAnchor)
                      const textSelected = selectedText || ''
                      setInputText(textSelected)
                      const genSelected = option.onSelect(null)
                      if (option.title !== 'Write your own prompt') {
                        setInputText(textSelected)
                        handleGenerate(textSelected, genSelected)
                      } else {
                        if (textSelected === '') {
                          setInputText('\n')
                        } else {
                          setInputText(`\n\n"""${textSelected}"""`)
                        }
                        setInputPrompt('Write your own prompt')
                      }
                    }}
                    onMouseEnter={() => {
                      setSelectPromptSelected(i)
                    }}
                    key={option.key}
                    option={option}
                  />
                ))}
              </Box>
            </Box>
          </Popover>
        )
      )}
    </>
  )
}
interface ResultInterface {
  inputText: string
  focusOn: boolean
  pageType: string | null
  setInputText: (e: any) => void
  generateType?: GenerateType | null
  handleKeyDown: (e: any) => void
  background: string
  isEdit?: boolean
  isExpand?: boolean
  editState?: boolean
  expandState?: boolean
  hideExpandOnBlur?: boolean
  animatedText?: boolean
  focusAuto?: string
}

const ResultBox = (props: ResultInterface): JSX.Element => {
  const {
    animatedText = false,
    focusOn = false,
    hideExpandOnBlur = false,
    editState = false,
    expandState = false,
    isExpand = true,
    isEdit = true,
    inputText,
    setInputText,
    generateType,
    handleKeyDown,
    background = '#fff',
    focusAuto = 'end',
    pageType = ''
  } = props
  const [edit, setEdit] = React.useState<boolean>(editState)
  const [expand, setExpand] = React.useState<boolean>(expandState)
  const htmlInputText = textToHtml(inputText)
  const contentEditableref = useRef<HTMLDivElement>(null)
  const [text, setText] = useState<string>('')
  const [animateDone, setAnimateDone] = React.useState<boolean>(!animatedText)
  const [showExpand, setShowExpand] = useState(false)
  const [localInputText, setLocalInputText] = useState('')
  const inputRef = useRef<HTMLInputElement | null>(null)

  React.useEffect(() => {
    if (pageType === '') {
      if (inputRef.current) {
        inputRef.current.value = inputText
      }
      setLocalInputText(inputText)
    } else {
      const formatedText = htmlToText(inputText.replaceAll('<br>', ''))
      if (inputRef.current) {
        inputRef.current.value = formatedText.replaceAll(/\n{2,}/g, '\n')
      }
      setLocalInputText(
        focusAuto !== 'start'
          ? formatedText.replaceAll(/\n{2,}/g, '\n')
          : `\n${formatedText.replaceAll(/\n{2,}/g, '\n')}`
      )
    }
  }, [inputText])

  const handleFocus = () => {
    if (inputRef.current) {
      const inputValueCount = inputText.replaceAll(/\n{2,}/g, '\n').length
      inputRef.current.setSelectionRange(inputValueCount, inputValueCount)
    }
  }

  React.useEffect(() => {
    console.log('Inpu values', inputText, focusOn)
  }, [localInputText, focusOn])

  const ref = React.createRef<any>()

  const handleEdit = (val: boolean) => {
    if (animatedText) {
      if (animateDone) {
        setEdit(false)
      }
      if (hideExpandOnBlur) {
        setExpand(false)
      }
    } else {
      if (hideExpandOnBlur) {
        setExpand(false)
      }
      setEdit(val)
    }
  }

  React.useEffect(() => {
    if (animatedText) {
      const animateText = () => {
        let i = 0
        const interval = setInterval(() => {
          setText((prevText) => prevText + htmlInputText.charAt(i))
          i++
          if (i === htmlInputText.length) {
            clearInterval(interval)
            setAnimateDone(true)
          }
        }, 2)
      }
      animateText()
    }
  }, [htmlInputText])

  React.useEffect(() => {
    const { current } = ref
    if (current) {
      current.scrollTop = current.scrollHeight
    }
  }, [text])

  const textToDisplay = animatedText
    ? text
    : htmlInputText || generateType?.placeholder
  React.useLayoutEffect(() => {
    const { current } = ref
    if (current) {
      const { clientHeight, scrollHeight } = current
      if (clientHeight < scrollHeight) {
        setShowExpand(true)
      } else {
        setShowExpand(false)
      }
    }
  }, [ref])

  return edit ? (
    <>
      <TextField
        id="outlined-multiline-static"
        ref={inputRef}
        label=""
        multiline
        minRows={1}
        maxRows={4}
        inputRef={focusOn ? (input) => input && input.focus() : () => {}}
        onFocus={(e) =>
          e.currentTarget.setSelectionRange(
            focusAuto === 'start' ? 0 : localInputText.length,
            focusAuto === 'start' ? 0 : localInputText.length
          )
        }
        defaultValue={localInputText}
        onChange={(event) => {
          setInputText(event.target.value)
        }}
        style={{ width: '100%', marginBottom: '5px' }}
        onKeyDown={(e) => handleKeyDown(e)}
      />
    </>
  ) : (
    <Box
      sx={{
        padding: '0px',
        overflowY: 'auto',
        marginBottom: '8px',
        background,
        border: '1px solid #eee'
      }}
    >
      <Box
        ref={ref}
        sx={{
          padding: '0px 8px',
          maxHeight: '250px',
          minHeight: '50px'
        }}
        onClick={() => handleEdit(true)}
        dangerouslySetInnerHTML={{ __html: textToDisplay }}
      ></Box>
      <>
        {!animatedText && showExpand && (
          <Box
            sx={{
              padding: '0px 8px'
            }}
          >
            {expandState && expand ? (
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => setExpand(false)}
              >
                Show less
              </span>
            ) : (
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => setExpand(true)}
              >
                Show More
              </span>
            )}
          </Box>
        )}
      </>
    </Box>
  )
}

const AIGenerateSlashPrompt = (): JSX.Element => {
  return <AIGeneratePlugin textPromptMode />
}

const MentionsPlugin = (): JSX.Element => {
  return <NewMentionsPlugin />
}

const AIGenerateSelectPrompt = (props: AIPluginInferface): JSX.Element => {
  return <AIGeneratePlugin selectPromptMode {...props} />
}

export {
  MentionsPlugin,
  AIGenerateSlashPrompt,
  AIGenerateSelectPrompt,
  htmlToText,
  textToHtml,
  ResultBox,
  ComponentPickerMenuItem,
  ComponentPickerOption
}
