import { FETCH_CLIENTS_LIST, UPDATE_CLIENTS_LIST } from '../../actionTypes'
import { findIndex, cloneDeep } from 'lodash'
const initialState = {
  clientsList: null,
  hasMoreData: false
}

function clientsList(state = initialState, action) {
  const { type, payload = {} } = action

  switch (type) {
    case FETCH_CLIENTS_LIST: {
      let clientListClone = cloneDeep(state.clientsList) ?? []
      const {
        has_next_page = false,
        page_num = 1,
        page_size = 10,
        clients = []
      } = payload

      if (page_num === 1) {
        clientListClone = clients
      } else {
        clientListClone.push(...clients)
      }
      return {
        ...state,
        clientsList: clientListClone,
        hasMoreData: has_next_page
      }
    }
    case UPDATE_CLIENTS_LIST: {
      const clientList = _.cloneDeep(state.clientsList)
      const { client_group_id = '', hidden = false, ...rest } = payload
      if (client_group_id) {
        const index = clientList.findIndex(
          (item) => item.id === client_group_id
        )
        if (index !== -1) {
          if (hidden) {
            clientList[index] = { ...clientList[index], hidden: true }
          } else {
            const newVariationList = [
              ...clientList[index].variation_list,
              { ...rest }
            ]
            const updatedClient = {
              ...clientList[index],
              variation_list: newVariationList
            }
            clientList[index] = updatedClient
          }
        }
      }
      return {
        ...state,
        clientsList: clientList
      }
    }
    default:
      return state
  }
}

export default clientsList
