import React, { useState, useRef, useEffect } from 'react'
import IconButton from '@mui/material/IconButton'
import { useStyles } from './styles'
import _ from 'lodash'
import { checkExpiry, getSignedUrl } from '../../utils/AWS'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { Loader, ImageGallery, ImageRender } from '../../components'
import { PDFDocument } from 'pdf-lib'
import MarkdownIt from 'markdown-it'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'

import { Card, CardContent, Fade, Menu } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import Box from '@material-ui/core/Box'
import ImageIcon from '@mui/icons-material/Image'
import { useColor } from '../../ThemeContext'
import EditNoteIcon from '@mui/icons-material/EditNote'
import { styled } from '@mui/material/styles'

import { TextareaAutosize } from '@mui/base/TextareaAutosize'
import {
  changeProjectInfo,
  saveMasterPeopleNotes,
  saveProjectNotes
} from '../../store/api'
import { Button } from '@material-ui/core'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'

import StyleIcon from '@mui/icons-material/Style'
import Drawer from '@mui/material/Drawer'
import TagCenterUpdate from '../TagCenterUpdate'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { updateProjectTag } from '../../store/Project/Actions'
import TextLineLimiter from '../../components/TextLineLimiter'
import { initalizeDownload } from '../../utils/DownloadFromS3/DownloadFromS3'
import DownloadIcon from '@mui/icons-material/Download'
import { checkUserRoleViewer } from '../../utils/User'
import Notebook from '../Notebook/Notebook'
import Button2 from '../../components/Button/Button2'
import {
  MaximizeIcon,
  RedirectIcon,
  ThreeDotsIcon,
  PencilIcon,
  ProjectIcon
} from '../../components/Icons/Icons'
import SearchNew from '../ListFilter/SearchNew'
import DisplayTags from '../ResumeResult/DisplayTags'
import { toast } from 'react-toastify'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'

const ScrollablePictures = (props) => {
  const { images = [], s3Obj, viewImage = () => {}, type = '' } = props
  const [imagesUrl, setImagesUrl] = useState([])
  const [loading, setLoading] = useState(true)

  const s3Urls = async (images, s3Obj) => {
    const newImagesUrl = []
    setLoading(true)

    try {
      await Promise.all(
        images.map(async (imageUrl) => {
          const signedSrc = await checkExpiry(
            imageUrl?.src ? imageUrl?.src : imageUrl,
            s3Obj
          )
          newImagesUrl.push(signedSrc)
        })
      )
      setImagesUrl(newImagesUrl)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    s3Urls(images, s3Obj)
  }, [images, s3Obj])

  return (
    <Box id="Hori-Scroll-Box" style={{ height: '250px', maxWidth: '100%' }}>
      {loading ? (
        <Loader loading={loading} caption={''} />
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            height: '250px',
            overflowX: 'auto',
            maxWidth: '100%',
            overflowY: 'hidden'
          }}
        >
          {imagesUrl.map((image, index) => (
            <div
              key={index}
              style={{
                // position: 'relative',
                marginRight: '10px',
                margin: '8px',
                borderRadius: '10px',
                height: '250px',
                maxWidth: '100%', // Ensure the inner div doesn't exceed the parent's width
                flexShrink: 0
              }}
              onClick={() => viewImage([{ src: image }])}
            >
              <img
                src={image}
                alt={`Image ${index}`}
                style={{
                  width: '100%',
                  maxHeight: '220px',
                  borderRadius: '10px'
                }}
              />
            </div>
          ))}
        </div>
      )}
    </Box>
  )
}

const Text = (props) => {
  const { text, style } = props
  return <span style={style} dangerouslySetInnerHTML={{ __html: text }} />
}

const ProjectResult = (props) => {
  const {
    singleData,
    data = {},
    openProject,
    openPdf,
    currentProject,
    handleCloseProject = () => {},
    s3Obj,
    openType = 'tab',
    thumbnail_url_list = [],
    handleVariationIndexChange = () => {},
    screenshotIndex = 0,
    originProposalNames = [],
    handlePDFViewer = () => {},
    handleNotesUpdate = () => {},
    notesList = [],
    tagState = {},
    projectId = '',
    showBackButton = true
  } = props

  const [selectedImage, setSelectedImage] = useState([])
  const md = new MarkdownIt() // this is used to convert textToHtml
  const [timeline, setTimeline] = useState('')
  const [signedThumbnailList, setSignedThumbnailList] = useState([])
  const { selectedColor } = useColor()
  const [showTagDrawer, setShowTagDrawer] = useState(false)
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const isUserViewer = checkUserRoleViewer()
  const [editName, setEditName] = useState(null)
  const auth = useSelector((state) => state.authenticate)
  const domain = auth?.user?.domain
  const { domain_id = '' } = domain || {}

  const gets3Urls = async (images, s3Obj) => {
    const newImagesUrl = []
    try {
      await Promise.all(
        images.map(async (imageUrl) => {
          const signedSrc = await checkExpiry(
            imageUrl?.src ? imageUrl?.src : imageUrl,
            s3Obj
          )
          newImagesUrl.push(signedSrc)
        })
      )
      const thumbnailList = []
      newImagesUrl.forEach((image, index) => {
        thumbnailList.push({
          screenShot: image,
          proposal_name: originProposalNames[index],
          proposal_id: currentProject?.proposal_id,
          page_number: currentProject?.page_number,
          clientId: currentProject?.client_group?.id
        })
      })
      setSignedThumbnailList(thumbnailList)
      setLoading(false)
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (thumbnail_url_list.length > 0) {
      gets3Urls(thumbnail_url_list, s3Obj)
    } else {
      setLoading(false)
    }
  }, [thumbnail_url_list, s3Obj])

  const preprocessMarkdown = (markdownText) => {
    let format = markdownText.replace(/<br>/g, '  \n')
    format = format.replace(/\*/g, '\n+')
    format = format.replace(/<mark>/g, '')
    format = format.replace(/<\/mark>/g, '')
    return format
  }

  const convertToHTML = (markdownText) => {
    markdownText = markdownText || ' '
    const preprocessedText = preprocessMarkdown(markdownText)
    return md.render(preprocessedText)
  }

  const VariationIndexChange = (index) => {
    handleVariationIndexChange(index - 1)
  }

  const {
    project_name = '',
    project_location = '',
    project_description = '',
    images = [],
    project_timeline = {},
    project_team_members = {},
    square_footage = '',
    total_project_cost = ''
  } = { ...singleData.data[0], ...singleData.data[0].data }

  const [editNotes, setEditNotes] = useState(false)
  const [editedNote, setEditedNote] = useState('')
  const [tags, setTags] = useState([])
  const [prevNote, setPrevNote] = useState('')
  const handleSaveNotes = async () => {
    const saveData = {
      project_id: projectId,
      notes: editedNote
    }
    setEditNotes(false)
    const res = await saveProjectNotes(saveData)
    if (res.status === 200) {
      handleNotesUpdate(editedNote, projectId)
    }
  }

  const isSuperUser = false
  const [activeTab, setActiveTab] = useState(0)
  const [notesData, setNotesData] = useState(singleData?.notes ?? [])
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredProjects, setFilteredProjects] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)
  const [basicInfo, setBasicInfo] = useState({
    name: project_name,
    logo: '',
    tags: currentProject?.tags
  })

  const open = Boolean(anchorEl)

  const handleCancelNotes = () => {
    setEditedNote(prevNote)
    setEditNotes(false)
  }

  useEffect(() => {
    const item = notesList.filter((note) => note.id === projectId)[0]
    const notes = item?.notes
    const tags = item?.tags
    setEditedNote(notes)
    setPrevNote(notes)
    setTags(tags)
  }, [notesList])

  useEffect(() => {
    if (typeof project_timeline === 'string') {
      setTimeline(project_timeline)
    } else {
      const line =
        project_timeline?.start_date +
        (project_timeline?.end_date ? ' - ' + project_timeline?.end_date : '')
      setTimeline(line)
    }
  }, [project_timeline])

  const renderObjectProperties = (object) => {
    return Object.keys(object).map((key) => (
      <div key={key}>
        {object?.[key] && (
          <>
            <Text
              text={_.startCase(key) + ' - '}
              style={{ fontWeight: 'bold' }}
            />
            {typeof object[key] === 'object' ? (
              renderObjectProperties(object[key])
            ) : (
              <Text text={object[key] + '<br/>'} />
            )}
          </>
        )}
      </div>
    ))
  }

  const renderKeyTeamMembers = (teamMembers) => {
    if (typeof teamMembers === 'string') {
      return (
        <div>
          <Text text={teamMembers + '<br/>'} />
        </div>
      )
    }
    return teamMembers?.map((member, index) => (
      <div key={index}>
        <Text
          text={
            (member?.name ? member?.name : '') +
            (member?.role ? ', ' + member?.role : '') +
            (member?.responsibilities ? ', ' + member?.responsibilities : '') +
            '<br/>'
          }
        />
      </div>
    ))
  }
  const downloadImage = async (payload) => {
    const { proposalName, screenShot } = payload
    const name = proposalName
      ? proposalName.split('.').slice(0, -1).join('.')
      : 'image'
    await initalizeDownload(screenShot, name + '.png', s3Obj)
  }

  const handleDownloadCustomPdf = async (proposalId, pageNumber, pdfName) => {
    const toastId = toast.loading('Downloading Resume PDF...')

    const pdfUrl = `https://${process.env.REACT_APP_PDF_BUCKET}.s3.amazonaws.com/raw-pdfs/${domain_id}/${proposalId}.pdf`

    const finalUrl = await getSignedUrl(pdfUrl, s3Obj, `${proposalId}.pdf`)
    const arrayBuffer = await fetch(finalUrl).then((res) => res.arrayBuffer())
    const pdfDoc = await PDFDocument.load(arrayBuffer)
    const newPdf = await PDFDocument.create()

    const pagesToBeCopied = [pageNumber]
    const copiedPages = await newPdf.copyPages(pdfDoc, pagesToBeCopied)

    for (const copiedPage of copiedPages) {
      newPdf.addPage(copiedPage)
    }

    const pdfBytes = await newPdf.save()
    const blob = new Blob([pdfBytes], { type: 'application/pdf' })

    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = pdfName
    link.click()

    trackEvent(mixpanelEvents.PROJECT_RESUME_DOWNLOAD, 'SUCCESS', {
      proposal_id: proposalId
    })

    toast.dismiss(toastId)
  }

  const viewImage = (image) => {
    setSelectedImage(image)
  }

  const renderTagDrawer = () => {
    const id = projectId
    const document_type = 'project'
    return (
      id && (
        <Drawer
          anchor={'right'}
          open={showTagDrawer}
          onClose={() => setShowTagDrawer(false)}
        >
          <Box sx={{ padding: '20px', width: '400px', height: '100%' }}>
            <TagCenterUpdate
              id={id}
              filterTags="project"
              document_type={document_type}
              onUpdateCallback={onUpdateCallback}
              cancelCallback={() => setShowTagDrawer(false)}
            />
          </Box>
        </Drawer>
      )
    )
  }

  const onUpdateCallback = (id, mode, tagsFormated) => {
    if (mode === 'tags') {
      const newTags = []
      tagsFormated.forEach((tag) => {
        newTags.push(tag.id)
      })
      const payload = {
        id,
        tags: newTags
      }
      const callback = () => {
        setTags(newTags)
        setBasicInfo({
          ...basicInfo,
          tags: newTags
        })
      }
      dispatch(updateProjectTag(payload, callback))
    }
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleNameChange = async (newName) => {
    const newToast = toast.info('Updating project name...')
    const data = {
      project_group_id: projectId,
      project_title: newName
    }
    const callback = () => {
      setBasicInfo({
        ...basicInfo,
        name: newName
      })
      toast.dismiss(newToast)
    }
    const res = await changeProjectInfo(data)
    if (res.status === 200) {
      callback()
    } else {
      toast.error('Unable to update project name')
    }
  }

  return openType === 'search_results' ? (
    <>
      <Dialog
        open={openProject}
        onClose={handleCloseProject}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>
          <span dangerouslySetInnerHTML={{ __html: project_name }} />
        </DialogTitle>
        <DialogContent>
          {images?.length > 0 && (
            <ScrollablePictures
              images={images}
              s3Obj={s3Obj}
              type={'view'}
              viewImage={viewImage}
            />
          )}
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '30% 70%',
              gap: '0px',
              gridAutoFlow: 'row'
            }}
          >
            <div style={{ gridColumn: '1', margin: '5px' }}>
              {project_location && (
                <Box
                  sx={{
                    marginTop: '8px'
                  }}
                >
                  <Text text={'Location : '} style={{ fontWeight: 'bold' }} />
                  <Text
                    text={project_location + '<br/>'}
                    style={{ fontStyle: 'italic' }}
                  />
                </Box>
              )}
              {total_project_cost && (
                <Box
                  sx={{
                    marginTop: '8px'
                  }}
                >
                  <Text text={'Budget : '} style={{ fontWeight: 'bold' }} />
                  <Text
                    text={total_project_cost + '<br/>'}
                    style={{ fontStyle: 'italic' }}
                  />
                </Box>
              )}
              {square_footage && (
                <Box
                  sx={{
                    marginTop: '8px'
                  }}
                >
                  <Text text={'Area : '} style={{ fontWeight: 'bold' }} />
                  <Text
                    text={square_footage + '<br/>'}
                    style={{ fontStyle: 'italic' }}
                  />
                </Box>
              )}
              {timeline !== '' && (
                <Box
                  sx={{
                    marginTop: '8px'
                  }}
                >
                  <Text text={'Duration : '} style={{ fontWeight: 'bold' }} />
                  <Text
                    text={timeline + '<br/>'}
                    style={{ fontStyle: 'italic' }}
                  />
                </Box>
              )}
              {project_team_members.length > 0 && (
                <Box
                  sx={{
                    marginTop: '8px'
                  }}
                >
                  <Text
                    text={'Key Team Members -'}
                    style={{ fontWeight: 'bold' }}
                  />
                  {renderKeyTeamMembers(project_team_members)}
                </Box>
              )}
            </div>
            <div style={{ gridColumn: '2', margin: '5px', overflow: 'auto' }}>
              {project_description && (
                <Box
                  sx={{
                    marginTop: '8px'
                  }}
                >
                  <Text
                    text={'About the project -  <br/>'}
                    style={{ fontWeight: 'bold' }}
                  />
                  <Text text={convertToHTML(project_description)} />
                </Box>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions
          style={{ position: 'absolute', top: '10px', right: '10px' }}
        >
          <IconButton onClick={handleCloseProject}>
            <CloseIcon sx={{}} />
          </IconButton>
        </DialogActions>
      </Dialog>
      {selectedImage.length > 0 && (
        <ImageGallery
          images={selectedImage}
          onClose={() => setSelectedImage([])}
          s3Obj={s3Obj}
        />
      )}
    </>
  ) : loading ? (
    <Loader loading={loading} caption={''} flex />
  ) : (
    <>
      <div className="w-full h-full">
        <div className="h-full">
          <div
            style={{
              width: '70%',
              margin: 'auto',
              padding: '20px',
              paddingBottom: '0px'
            }}
          >
            <div className="flex gap-2 items-center text-xxs text-grey-500 mb-3">
              <span className="cursor-pointer" onClick={handleCloseProject}>
                Project
              </span>{' '}
              <span> {'>'} </span>{' '}
              <span className="text-grey-700 text-capitalize w-44 text-truncate">
                {basicInfo.name}
              </span>
            </div>
            <div className="flex gap-2 items-center">
              <div
                style={{
                  width: '50px',
                  height: '50px',
                  padding: '6px',
                  borderRadius: '10px',
                  border: '1px solid var(--grey-200)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'var(--grey-100)'
                }}
              >
                <ProjectIcon className="size-4 text-grey-400 mt-1" />
              </div>
              <div className="flex flex-col w-full">
                <div className="flex gap-2 items-center w-full">
                  <div className="w-full">
                    <p className="m-0 text-lg font-medium leading-5 flex items-center gap-1 text-capitalize mb-2 w-full">
                      {editName !== null ? (
                        <input
                          type="text"
                          className="input-base capitalize outline-none text-lg w-full"
                          autoFocus
                          value={editName?.name}
                          onClick={(e) => {
                            e.stopPropagation()
                          }}
                          onChange={(e) => {
                            e.stopPropagation()
                            setEditName({
                              id: projectId,
                              name: e.target.value
                            })
                          }}
                          onBlur={(e) => {
                            e.stopPropagation()
                            if (e.target.value !== basicInfo.name) {
                              handleNameChange(e.target.value)
                            }
                            setEditName(null)
                          }}
                        />
                      ) : (
                        <>
                          {basicInfo.name}
                          <span
                            onClick={(e) => {
                              e.stopPropagation()
                              setEditName({
                                id: projectId,
                                name: basicInfo.name
                              })
                            }}
                          >
                            <PencilIcon className="size-4 text-grey-400 mt-1" />
                          </span>
                        </>
                      )}
                    </p>

                    <DisplayTags
                      tags={basicInfo?.tags ?? []}
                      setShowTagDrawer={setShowTagDrawer}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex mt-3">
              <p
                className="m-0 font-medium text-sm p-1 px-3 text-grey-700 cursor-pointer"
                onClick={() => setActiveTab(0)}
                style={{
                  borderBottom: activeTab === 0 && '2px solid var(--grey-700)'
                }}
              >
                Files
              </p>
              <p
                className="m-0 font-medium text-sm p-1 px-3 text-grey-700 cursor-pointer"
                onClick={() => setActiveTab(1)}
                style={{
                  borderBottom: activeTab === 1 && '2px solid var(--grey-700)'
                }}
              >
                Notes
              </p>
            </div>
          </div>

          <div
            className="bg-grey-100 p-4"
            style={{
              height: 'calc(100% - 200px)'
            }}
          >
            <div
              className="rounded-lg"
              style={{
                width: '70%',
                margin: 'auto',
                height: 'calc(100% - 20px)',
                overflowY: 'auto',
                backgroundColor: 'white',
                padding: '0 20px'
              }}
            >
              {activeTab === 0 && (
                <div
                  style={{
                    padding: '10px 20px'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'end',
                      alignItems: 'center',
                      gap: '6px',
                      padding: '8px 0px',
                      marginBottom: '5px'
                    }}
                  >
                    <SearchNew
                      value={searchQuery}
                      onChange={(value) => {
                        handleSearch(value)
                      }}
                      placeholder="Search Docs"
                      onClear={() => {
                        handleSearch('')
                      }}
                    />
                  </div>
                  <div className="">
                    <div className="grid grid-col-5 gap-4">
                      {singleData.data?.map((project, index) => {
                        const {
                          proposal_id,
                          thumbnail_url = '',
                          screenshots = [],
                          page_number = 1,
                          proposal_name = '',
                          version = '',
                          screenShot,
                          client_group,
                          title
                        } = project
                        const coverImage = _.isEmpty(screenshots)
                          ? thumbnail_url
                          : screenshots[0]?.src
                        const imagePayload = _.isEmpty(screenshots)
                          ? thumbnail_url
                          : screenshots
                        const displayName = proposal_name || proposal_id
                        const sourceType =
                          client_group?.type === 'rfx' ? 'rfx' : 'proposal'

                        return (
                          <Box
                            key={index}
                            className="parent-card"
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                              border: '1px solid var(--grey-200)',
                              overflow: 'hidden',
                              borderRadius: '10px',
                              '&:hover img': {
                                opacity: '0.5'
                              },
                              '&:hover #overlayMenu': {
                                opacity: '0.8 !important'
                              }
                            }}
                          >
                            <Box
                              id={'project_' + index}
                              key={'project_' + index}
                              style={{
                                padding: '10px',
                                position: 'relative'
                              }}
                            >
                              <Box
                                sx={{
                                  justifyContent: 'center',
                                  width: '100%',
                                  display: 'flex',
                                  cursor: 'pointer'
                                }}
                              >
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}
                                >
                                  <ImageRender
                                    style={{
                                      height: '100%',
                                      width: '100%',
                                      minWidth: '180px',
                                      maxWidth: '200px',
                                      minHeight: '220px',
                                      objectFit: 'contain',
                                      overflow: 'hidden',
                                      transition: 'opacity 0.5s ease'
                                    }}
                                    src={thumbnail_url}
                                    s3Obj={s3Obj}
                                    effect="blur"
                                    overlay={
                                      <Box
                                        id="overlayMenu"
                                        style={{
                                          position: 'absolute',
                                          top: '0%',
                                          right: '0%',
                                          opacity: 0,
                                          backgroundColor: 'var(--grey-700)',
                                          padding: '8px',
                                          width: '100%',
                                          height: '100%',
                                          boxSizing: 'border-box',
                                          zIndex: 1000,
                                          transition: 'opacity 0.3s ease'
                                        }}
                                      >
                                        <div className="relative h-full">
                                          {thumbnail_url && (
                                            <div
                                              className="absolute"
                                              style={{
                                                top: '45%',
                                                left: '45%'
                                              }}
                                              onClick={() => {
                                                viewImage([
                                                  { src: thumbnail_url }
                                                ])
                                              }}
                                            >
                                              <MaximizeIcon className="size-6 text-grey-100" />
                                            </div>
                                          )}

                                          {client_group?.id && (
                                            <Button2
                                              secondary
                                              disableHover
                                              onClick={() => {
                                                window.open(
                                                  `/library/clients/${client_group?.id}`,
                                                  '_blank'
                                                )
                                              }}
                                              style={{
                                                border:
                                                  '1px solid var(--grey-100)',
                                                fontSize: '12px',
                                                position: 'absolute',
                                                bottom: '10px',
                                                width: '100%',
                                                color: 'var(--grey-100)',
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '8px'
                                              }}
                                            >
                                              View Client{' '}
                                              <RedirectIcon className="size-3" />
                                            </Button2>
                                          )}
                                        </div>
                                      </Box>
                                    }
                                    fallback={
                                      <>
                                        <Box
                                          sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            wordBreak: 'break-word'
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              fontWeight: '600',
                                              fontSize: '16px',
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis',
                                              '-webkit-line-clamp': 2,
                                              display: '-webkit-box',
                                              '-webkit-box-orient': 'vertical'
                                            }}
                                          >
                                            {displayName}
                                          </Box>
                                        </Box>
                                      </>
                                    }
                                  />
                                </Box>
                              </Box>
                            </Box>
                            <div className="bg-grey-100 p-3 flex justify-between items-center">
                              {proposal_name ? (
                                <Tooltip title={proposal_name}>
                                  <p
                                    className="m-0 font-medium text-xs cursor-pointer"
                                    style={{
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap'
                                    }}
                                    onClick={() => {
                                      openPdf(proposal_id, page_number, sourceType)
                                    }}
                                  >
                                    {proposal_name}
                                  </p>
                                </Tooltip>
                              ) : (
                                <p className="m-0 font-medium text-sm">
                                  Untitled Project
                                </p>
                              )}

                              <div className="relative h-full w-16 card-options">
                                <div className="text-grey-700 rounded-md flex justify-end">
                                  <span
                                    onClick={handleClick}
                                    className="cursor-pointer grid place-content-center"
                                  >
                                    <ThreeDotsIcon
                                      className="size-5"
                                      strokeWidth={2}
                                    />
                                  </span>
                                  <Menu
                                    elevation={2}
                                    TransitionComponent={Fade}
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleCloseMenu}
                                    MenuListProps={{
                                      'aria-labelledby': 'basic-button'
                                    }}
                                    className="p-1"
                                  >
                                    <Button2
                                      noOutline
                                      secondary
                                      style={{
                                        width: '100%'
                                      }}
                                      onClick={() =>
                                        handleDownloadCustomPdf(
                                          proposal_id,
                                          page_number - 1,
                                          project_name
                                        )
                                      }
                                    >
                                      Download Resume
                                    </Button2>
                                    <Button2
                                      noOutline
                                      secondary
                                      style={{
                                        width: '100%'
                                      }}
                                      onClick={() => handleMove(resume)}
                                    >
                                      Move Resume
                                    </Button2>
                                    <Button2
                                      noOutline
                                      secondary
                                      style={{
                                        width: '100%'
                                      }}
                                      onClick={() => {
                                        handleMasterMappingDelete(
                                          people,
                                          index,
                                          'delete'
                                        )
                                      }}
                                    >
                                      Remove Resume
                                    </Button2>
                                  </Menu>
                                </div>
                              </div>
                            </div>
                          </Box>
                        )
                      })}
                    </div>
                  </div>
                </div>
              )}

              {activeTab === 1 && (
                <Notebook
                  notesData={notesData}
                  source={{
                    id: projectId,
                    type: 'project',
                    name: project_name
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {selectedImage.length > 0 && (
        <ImageGallery
          images={selectedImage}
          onClose={() => setSelectedImage([])}
          s3Obj={s3Obj}
        />
      )}
      {renderTagDrawer()}
    </>
  )
}

export default ProjectResult
