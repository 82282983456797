import React from 'react'
import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material'
import ViewList from '@mui/icons-material/ViewList'
import Window from '@mui/icons-material/Window'
import Button2 from '../Button/Button2'

const ViewMode = (props) => {
  const { viewMode = '', setViewMode = () => {}, disabled = false } = props

  const [anchorEl, setAnchorEl] = React.useState(null)
  // const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const setMode = (mode) => {
    setViewMode(mode)
    handleClose()
  }

  return viewMode === 'grid' ? (
    <Button2 secondary onClick={() => setMode('table')}>
      <ViewList
        sx={{
          height: '16px',
          width: '16px'
        }}
      />
    </Button2>
  ) : (
    <Button2 secondary onClick={() => setMode('grid')}>
      <Window
        sx={{
          height: '16px',
          width: '16px'
        }}
      />
    </Button2>
  )
}

export default ViewMode
