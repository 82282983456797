import { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import _, { set } from 'lodash'
import {
  getChatSessionHistory,
  getSingleChatHistory,
  updateSessionTitle,
  updateSessionChatFeedback,
  updateChatSessionHistory,
  deleteChatSessionHistory,
  getSessionChat
} from '../../store/Chat/Actions'
import DeleteIcon from '@mui/icons-material/Delete'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'
import { generateTitle, chatShare } from '../../store/api'
import { useStyles } from './styles'
import {
  Grid,
  TextField,
  Box,
  IconButton,
  Tooltip,
  Dialog,
  DialogContent,
  DialogTitle
} from '@mui/material'
import ChatBot from '../ChatBot'
import clsx from 'clsx'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useResizeDetector } from 'react-resize-detector'
import Search from '../../sections/ListFilter/search'
import { ReactComponent as SearchImg } from '../../assets/images/search.svg'
import { ReactComponent as Chat_Session } from '../../assets/images/Chat_Session.svg'
import SvgIcon from '@material-ui/core/SvgIcon'
import ClearIcon from '@mui/icons-material/Clear'
import DoneIcon from '@mui/icons-material/Done'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ShareIcon from '@mui/icons-material/Share'
import CloseIcon from '@material-ui/icons/Close'
import Select from 'react-select'
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined'
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined'
import { ReactComponent as Robot } from '../../assets/images/robot.svg'
import Avatar from '@mui/material/Avatar'
import { toast } from 'react-toastify'
import ThreePOutlinedIcon from '@mui/icons-material/ThreePOutlined'
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined'
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined'
import { initalizeS3 } from '../../utils/AWS'
import {
  ImageRender,
  UserAvatar,
  MenuButton,
  Loader,
  Button,
  Container,
  Section,
  SectionFixed,
  useConfirmation
} from '../../components'
import { textToHtml } from '../../utils/CopyHTML'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'
import { UPDATE_SESSION_CHAT_PARTIAL } from '../../store/actionTypes'

const TextGenearate = (props) => {
  const { text, onFinish = () => {}, delay = 40 } = props
  const [charIndex, setCharIndex] = useState(1)

  useEffect(() => {
    if (text) {
      let current
      clearTimeout(current)
      if (charIndex < text.length) {
        current = setTimeout(() => setCharIndex(charIndex + 1), delay)
      } else {
        onFinish()
      }
      return () => clearTimeout(current) // cleanup on unmount
    }
  }, [charIndex, text])

  return text ? text.substr(0, charIndex) : ''
}

const SessionChat = () => {
  const { ConfirmDialog, showConfirmDialog } = useConfirmation()
  const userData = useSelector((state) => state.authenticate?.user)
  const domainData = userData?.domain
  const { domain_users = [] } = domainData || {}
  const chatState = useSelector((state) => state.chat)
  const { sessionChatHistory = {} } = chatState
  const {
    sessions = {},
    hasMoreData = false,
    sessionsCount
  } = sessionChatHistory
  const classes = useStyles()
  const { sessionId: sessionIdParams = '' } = useParams()
  const dispatch = useDispatch()
  const location = useLocation()
  const stateData = location.state ?? {}
  const { searchKey = '' } = stateData

  const [openDrawer, setOpenDrawer] = useState(true)
  const [selectedSession, setSelectedSession] = useState('')
  const [selectedSessionPath, setSelectedSessionPath] = useState({})
  const [sessionChat, setSessionChat] = useState(null)
  const [isEdit, setEdit] = useState({})
  const [loadingSessionHistory, setLoadingSessionHistory] = useState(true)
  const { width, height, ref } = useResizeDetector()
  const [pageSize, setPageSize] = useState('')
  const [page, setPage] = useState(1)
  const [searchValue, setSearchValue] = useState('')
  const [showSearch, setShowSearch] = useState(false)
  const {
    loaderContainer,
    outerContainer,
    selectedHistoryOption,
    newChatButton,
    historySessionTitle,
    historyChatTitle,
    addHighlight,
    titleActions,
    newChat,
    disabledButton,
    tooltip
  } = classes
  const [loadingMoreSessions, setLoadingMoreSessions] = useState(false)
  const titleRef = useRef()

  const [animationId, setAnimationId] = useState('')
  const [animationText, setAnimationText] = useState(null)

  const [showShareModal, setShowShareModal] = useState(false)
  const [shareObj, setShareObj] = useState({})
  const [shareLoading, setShareLoading] = useState(false)

  const [s3Obj, sets3Obj] = useState({})

  const [currentActiveMenu, setCurrentActiveMenu] = useState(null)

  useEffect(() => {
    trackEvent(mixpanelEvents.CHAT_TAB_CLICKED, 'SUCCESS', {}, {})
    async function initalizeData() {
      const s3 = await initalizeS3()
      sets3Obj(s3)
    }
    initalizeData()
  }, [])

  useEffect(() => {
    if (!height || pageSize) return
    const size = getPageCount(height)
    setPageSize(size)
  }, [height])

  const getPageCount = (containerHeight) => {
    const elementHeight = 33
    const effectiveH = containerHeight - 3 * 35
    const numVertical = Math.floor(effectiveH / elementHeight)
    return (numVertical + 5) * 2
  }

  const checkHorizontalScrollbar = (target) => {
    const element = document.getElementById(target)
    return element ? element.scrollHeight !== element.clientHeight : false
  }

  useEffect(() => {
    if (_.isEmpty(sessions) && pageSize) {
      fetchSessionChat(pageSize)
    } else if (!_.isEmpty(sessions)) {
      setLoadingSessionHistory(false)
    }
  }, [pageSize])

  useEffect(() => {
    if (hasMoreData && pageSize && !_.isEmpty(sessions)) {
      const hasScroll = checkHorizontalScrollbar('scrollableDiv')
      if (!hasScroll) {
        fetchMoreData()
      }
    }
  }, [pageSize, hasMoreData])

  const fetchMoreData = () => {
    if (!loadingMoreSessions) {
      setLoadingMoreSessions(true)
      const nextPage = page + 1
      setPage(nextPage)
      const req = {
        keyword: searchValue,
        page_num: nextPage,
        page_size: pageSize
      }
      const callback = () => {
        setLoadingMoreSessions(false)
      }
      dispatch(getChatSessionHistory(req, callback))
    }
  }

  const fetchSessionChat = () => {
    setLoadingSessionHistory(true)
    const req = {
      keyword: searchValue,
      page_num: page,
      page_size: pageSize
    }

    const callback = () => {
      setLoadingSessionHistory(false)
    }
    dispatch(getChatSessionHistory(req, callback))
  }

  const updateURL = (newSessionId) => {
    const newUrl = `/chat/${newSessionId}`
    window.history.pushState(null, '', newUrl)
  }

  const handleNewChat = (data) => {
    trackEvent(mixpanelEvents.CHAT_NEW_SESSION, 'SUCCESS', {}, {})
    setSelectedSession('new')
    setSessionChat({})
  }

  useEffect(() => {
    if (selectedSession !== 'new' && !_.isEmpty(sessions)) {
      const { sessionIndex, classifier, sessionId } = selectedSessionPath
      const sessionData = sessions?.[classifier]?.[sessionIndex]?.[sessionId]
      if (sessionData) {
        setSessionChat(_.cloneDeep(sessionData))
      } else {
        if (!selectedSession && sessionIdParams && sessionIdParams !== 'new') {
          const obj = findSessionById(sessionIdParams, sessions)
          if (obj) {
            const { key, index } = obj
            const sessionVal = sessions[key][index][sessionIdParams]
            setSessionChat(_.cloneDeep(sessionVal))
            setSelectedSessionPath({
              sessionIndex: index,
              classifier: key,
              sessionId: sessionIdParams
            })
            setSelectedSession(sessionIdParams)
          } else {
            const callback = () => {
              setSelectedSession('new')
              setSessionChat({})
            }
            dispatch(getSingleChatHistory(sessionIdParams, callback))
          }
        } else {
          setSelectedSession('new')
          setSessionChat({})
        }
      }
    }
  }, [sessions])

  function findSessionById(idToFind, sessions) {
    for (const key in sessions) {
      if (Array.isArray(sessions[key])) {
        const index = sessions[key].findIndex((item) => item[idToFind])
        if (index !== -1) {
          return { key, index }
        }
      }
    }
    return null
  }

  const handleMenuShare = (data) => {
    const { sessionData } = data
    setShowShareModal(sessionData)
    setShareObj({
      ...shareObj,
      session: sessionData,
      shareAll: true
    })
  }

  const handleMenuRename = (data) => {
    const { currentSessionId } = data
    setEdit((prev) => {
      return {
        ...prev,
        [currentSessionId]: true
      }
    })
  }
  const handleMenuDeleteSession = (data) => {
    const { sessionsIndex, title, currentSessionId, sessionData } = data
    handleDeleteSession(sessionsIndex, title, currentSessionId, sessionData)
  }

  const handleMenuTitleGeneration = (data) => {
    const { sessionData } = data
    handleTitleGeneration(sessionData?.id, '', true)
  }

  const menuOptions = [
    {
      label: 'Rename',
      icon: <DriveFileRenameOutlineIcon />,
      onClick: handleMenuRename
    },
    {
      label: 'Delete',
      icon: <DeleteIcon />,
      onClick: handleMenuDeleteSession
    },
    {
      label: 'Share',
      icon: <ShareIcon />,
      onClick: handleMenuShare
    },
    {
      label: 'Generate Title',
      icon: (
        <SvgIcon>
          <Robot />
        </SvgIcon>
      ),
      onClick: handleMenuTitleGeneration
    }
  ]

  const handleSessionSelection = (
    currentSessionId,
    data,
    sessionIndex,
    title
  ) => {
    const clone = _.cloneDeep(data)
    trackEvent(mixpanelEvents.CHAT_SESSION_OPENED, 'SUCCESS', {}, {})
    setSessionChat(clone)
    setSelectedSessionPath({
      sessionIndex,
      classifier: title,
      sessionId: currentSessionId
    })
    setSelectedSession(currentSessionId)
  }

  const handleEditSession = (index, sessionsIndex, id, oldValue) => {
    const inputValue = titleRef.current.value
    dispatch(
      updateChatSessionHistory(
        { id, title: inputValue, sessionsIndex, index },
        { id, title: oldValue, sessionsIndex, index }
      )
    )
    setEdit((prev) => {
      return {
        ...prev,
        [id]: false
      }
    })
  }

  const handleDeleteSession = (index, sessionsIndex, id, oldValue) => {
    showConfirmDialog({
      onConfirm: async () => {
        dispatch(
          deleteChatSessionHistory(
            { id, sessionsIndex, index },
            { id, data: oldValue, sessionsIndex, index }
          )
        )
      },
      confirmationMessageTitle: `Are you sure you want to delete this session ?`
    })
  }

  useEffect(() => {
    if (selectedSession) {
      updateURL(selectedSession)
    }
  }, [selectedSession])

  const updateSessionsChatData = (
    payload,
    regenerateTitle = false,
    partialUpdate = false,
    callback = () => {}
  ) => {
    const { id, newId, data } = payload
    if (partialUpdate) {
      dispatch({ type: UPDATE_SESSION_CHAT_PARTIAL, payload, callback })
    } else {
      if (id === 'new') {
        setSessionChat(_.cloneDeep(data[newId]))
        setSelectedSessionPath({
          sessionIndex: 0,
          classifier: 'today',
          sessionId: newId
        })
        setSelectedSession(newId)
        handleTitleGeneration(newId, data[newId]?.chat.chats[0]?.data?.text)
      } else if (regenerateTitle) {
        const { chats = [] } = payload || {}
        const { data = {} } = chats[0] || {}
        const { text = '' } = data || {}
        if (text) {
          handleTitleGeneration(id, text)
        }
      }
      dispatch(getSessionChat(payload, callback))
    }
  }

  const handleTitleGeneration = async (id, input, isUserRequested = false) => {
    if (id) {
      const response = await generateTitle({ session_id: id, title: input })
      if (response.status === 200) {
        if (isUserRequested) {
          trackEvent(mixpanelEvents.CHAT_SESSION_REGEN_TITLE, 'SUCCESS', {}, {})
        } else {
          trackEvent(mixpanelEvents.CHAT_SESSION_GEN_TITLE, 'SUCCESS', {}, {})
        }
        const newTitle = response.data.results
        setAnimationText(newTitle)
        setAnimationId(id)
        dispatch(
          updateSessionTitle({ id, title: newTitle, regenerateTitle: false })
        )
      } else {
        if (isUserRequested) {
          trackEvent(mixpanelEvents.CHAT_SESSION_REGEN_TITLE, 'FAILURE', {}, {})
          toast.error(
            'Unable to generator a suitable title. Please try again later'
          )
        } else {
          trackEvent(mixpanelEvents.CHAT_SESSION_GEN_TITLE, 'FAILURE', {}, {})
          dispatch(updateSessionTitle({ id, regenerateTitle: true }))
        }
      }
    }
  }

  const updateFeedbackCallback = (data) => {
    dispatch(updateSessionChatFeedback(data))
  }

  const searchOnEnter = (event) => {
    if (event?.keyCode === 13) {
      setLoadingSessionHistory(true)
      const req = {
        keyword: searchValue,
        page_num: 1,
        page_size: pageSize
      }
      setPage(1)
      const callback = () => {
        setLoadingSessionHistory(false)
      }
      dispatch(getChatSessionHistory(req, callback))
    }
  }

  const removeAnimation = () => {
    setAnimationId('')
    setAnimationText(null)
  }

  const handleShareSession = async () => {
    setShareLoading(true)
    const {
      session = {},
      selectedMessages = [],
      users = [],
      shareAll = true
    } = shareObj
    if (_.isEmpty(users)) {
      toast.error('Please select users to share')
      setShareLoading(false)
      return
    }
    if (!shareAll && selectedMessages.length === 0) {
      toast.error('Please select messages to share')
      setShareLoading(false)
      return
    }
    const shareMessages = shareAll ? [] : selectedMessages
    const { id: session_id } = session
    const usersArray = users.map((data) => data.id)
    const payload = {
      session_id,
      user_ids: usersArray,
      selected_chat_ids: shareMessages
    }
    const res = await chatShare(payload)
    if (res.status === 200) {
      trackEvent(mixpanelEvents.CHAT_SESSION_SHARED, 'SUCCESS', {}, {})
      toast.success('Chat shared successfully')
    } else {
      trackEvent(mixpanelEvents.CHAT_SESSION_SHARED, 'FAILURE', {}, {})
      toast.error('Something went wrong. Please try again later')
    }
    setShareLoading(false)
    closeShareModal()
  }

  const closeShareModal = () => {
    setShowShareModal(false)
    setShareObj({})
  }

  const handleShareUserSelect = (e) => {
    setShareObj({
      ...shareObj,
      users: e
    })
  }

  const domainUsersOptions = domain_users.map((data) => {
    return {
      label: data?.user_name,
      value: data?.id,
      ...data
    }
  })

  const handleSharedMessageSelect = (id, checked) => {
    if (checked) {
      setShareObj((prevShareObj) => {
        const updatedSelectedMessages = [
          ...(prevShareObj.selectedMessages || []),
          id
        ]
        return { ...prevShareObj, selectedMessages: updatedSelectedMessages }
      })
    } else {
      setShareObj((prevShareObj) => {
        const updatedSelectedMessages = (
          prevShareObj.selectedMessages || []
        ).filter((data) => data !== id)
        return { ...prevShareObj, selectedMessages: updatedSelectedMessages }
      })
    }
  }

  const toggleShareAll = () => {
    setShareObj({
      ...shareObj,
      shareAll: !shareAll
    })
  }

  const handleRenameKeyDown = (
    event,
    sessionsIndex,
    title,
    currentSessionId,
    sessionTitle
  ) => {
    if (event.key === 'Enter') {
      handleEditSession(sessionsIndex, title, currentSessionId, sessionTitle)
    } else if (event.key === 'Escape') {
      setEdit((prev) => {
        return {
          ...prev,
          [currentSessionId]: false
        }
      })
    }
  }

  const { session: shareSession = {}, shareAll } = shareObj
  const { chat = {} } = shareSession || {}
  const { chats = [] } = chat || []

  const isSessionEmpty = Object.values(sessions).every(
    (arr) => Array.isArray(arr) && arr.length === 0
  )

  return (
    <Container>
      <Section className={outerContainer} overFlow>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            height: '100%',
            position: 'relative'
          }}
        >
          <Grid container>
            {openDrawer && (
              <Grid
                item
                xs={12}
                sm={3}
                lg={3}
                xl={3}
                sx={{
                  borderRight: '1px solid rgb(229, 229, 229)',
                  height: '100%'
                }}
              >
                <Container>
                  <SectionFixed>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '10px'
                      }}
                    >
                      <Button
                        onClick={() => handleNewChat()}
                        className={newChatButton}
                      >
                        + New Chat
                      </Button>
                      <Box sx={{ display: 'flex' }}>
                        <Box
                          className={classes?.iconContainer}
                          id="searchInput"
                        >
                          <Box onClick={() => setShowSearch(!showSearch)}>
                            <Tooltip title="Search" classes={{ tooltip }} arrow>
                              <SvgIcon
                                component="span"
                                className={
                                  showSearch
                                    ? classes?.iconActive
                                    : classes?.iconInActive
                                }
                              >
                                <SearchImg />
                              </SvgIcon>
                            </Tooltip>
                          </Box>
                          {showSearch && (
                            <>
                              <Search
                                variant="standard"
                                handleInputChange={(val) => {
                                  setSearchValue(val)
                                }}
                                value={searchValue}
                                id="searchInputs"
                                handleEnterKey={searchOnEnter}
                              />
                            </>
                          )}
                        </Box>
                        <Tooltip
                          classes={{ tooltip }}
                          title={'Hide Sidebar'}
                          placement="right"
                          arrow
                        >
                          <IconButton
                            className={classes?.chatSessionIcon}
                            onClick={() => {
                              setOpenDrawer(!openDrawer)
                            }}
                            disableRipple
                            sx={{ padding: '0px' }}
                          >
                            <Chat_Session />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </div>
                  </SectionFixed>
                  <Section overFlow>
                    <div
                      id="scrollableDiv"
                      className={classes?.scrollableDiv}
                      ref={ref}
                    >
                      {loadingSessionHistory ? (
                        <Container>
                          <Section>
                            <Box className={loaderContainer}>
                              <Loader
                                loading={loadingSessionHistory}
                                caption={''}
                              />
                            </Box>
                          </Section>
                        </Container>
                      ) : isSessionEmpty ? (
                        <Box
                          sx={{
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          No sessions found
                        </Box>
                      ) : (
                        <InfiniteScroll
                          dataLength={sessionsCount || 0}
                          next={() => fetchMoreData()}
                          hasMore={hasMoreData}
                          loader={
                            sessionsCount && (
                              <Loader loading={true} caption={''} />
                            )
                          }
                          scrollableTarget="scrollableDiv"
                        >
                          <>
                            {Object.entries(sessions).map(
                              (data, titleIndex) => {
                                const title = data[0]
                                const sessionsData = data[1]
                                return sessionsData?.length > 0 ? (
                                  <>
                                    <div
                                      id="session-title"
                                      className={historySessionTitle}
                                    >
                                      {_.startCase(title)}
                                    </div>
                                    {sessionsData.map(
                                      (sessions, sessionsIndex) => {
                                        return Object.entries(sessions).map(
                                          (session, sessionIndex) => {
                                            const sessionTitle =
                                              session[1]?.title ?? ''
                                            const currentSessionId = session[0]
                                            const selectedEdit =
                                              isEdit?.[currentSessionId]
                                            const { type } = session[1]
                                            const isMenuOpen =
                                              currentActiveMenu ===
                                              currentSessionId
                                            return (
                                              <>
                                                <div
                                                  className={
                                                    selectedSession !==
                                                    currentSessionId
                                                      ? isMenuOpen
                                                        ? clsx(
                                                            historyChatTitle,
                                                            addHighlight,
                                                            classes.menuOpen
                                                          )
                                                        : clsx(
                                                            historyChatTitle,
                                                            addHighlight
                                                          )
                                                      : clsx(
                                                          historyChatTitle,
                                                          selectedHistoryOption
                                                        )
                                                  }
                                                  style={{
                                                    display: 'flex',
                                                    justifyContent:
                                                      'space-between',
                                                    alignItems: 'center'
                                                  }}
                                                  onClick={() => {
                                                    handleSessionSelection(
                                                      currentSessionId,
                                                      session[1],
                                                      sessionsIndex,
                                                      title
                                                    )
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      marginRight: '5px',
                                                      fontSize: '22px',
                                                      display: 'flex',
                                                      alignItems: 'center'
                                                    }}
                                                  >
                                                    {type === 'workspace' ? (
                                                      <RateReviewOutlinedIcon
                                                        style={{
                                                          fontSize: 'inherit'
                                                        }}
                                                      />
                                                    ) : type === 'shared' ? (
                                                      <ThreePOutlinedIcon
                                                        style={{
                                                          fontSize: 'inherit'
                                                        }}
                                                      />
                                                    ) : (
                                                      <ChatOutlinedIcon
                                                        style={{
                                                          fontSize: 'inherit'
                                                        }}
                                                      />
                                                    )}
                                                  </div>
                                                  {selectedEdit ? (
                                                    <TextField
                                                      fullWidth
                                                      id="outlined-required"
                                                      label=""
                                                      onKeyDown={(e) =>
                                                        handleRenameKeyDown(
                                                          e,
                                                          sessionsIndex,
                                                          title,
                                                          currentSessionId,
                                                          sessionTitle
                                                        )
                                                      }
                                                      defaultValue={
                                                        sessionTitle
                                                      }
                                                      inputRef={(input) => {
                                                        titleRef.current = input
                                                        if (input) {
                                                          input.focus()
                                                        }
                                                      }}
                                                      onClick={(e) =>
                                                        e.stopPropagation()
                                                      }
                                                      inputProps={{
                                                        padding: '5px 10px'
                                                      }}
                                                      onFocus={(e) =>
                                                        e.currentTarget.setSelectionRange(
                                                          e.currentTarget.value
                                                            .length,
                                                          e.currentTarget.value
                                                            .length
                                                        )
                                                      }
                                                    />
                                                  ) : (
                                                    <div
                                                      style={{
                                                        flex: '1',
                                                        overflow: 'hidden',
                                                        display: '-webkit-box',
                                                        WebkitLineClamp: '1',
                                                        WebkitBoxOrient:
                                                          'vertical'
                                                      }}
                                                    >
                                                      {animationId ===
                                                      currentSessionId ? (
                                                        <TextGenearate
                                                          text={animationText}
                                                          onFinish={
                                                            removeAnimation
                                                          }
                                                        />
                                                      ) : (
                                                        sessionTitle
                                                      )}
                                                    </div>
                                                  )}
                                                  <>
                                                    {selectedEdit ? (
                                                      <>
                                                        <div
                                                          style={{
                                                            display: 'flex'
                                                          }}
                                                        >
                                                          <IconButton
                                                            disableRipple
                                                            style={{
                                                              padding: '0px'
                                                            }}
                                                            className={
                                                              titleActions
                                                            }
                                                            onClick={(e) => {
                                                              e.stopPropagation()
                                                              handleEditSession(
                                                                sessionsIndex,
                                                                title,
                                                                currentSessionId,
                                                                sessionTitle
                                                              )
                                                            }}
                                                          >
                                                            <DoneIcon />
                                                          </IconButton>
                                                          <IconButton
                                                            disableRipple
                                                            style={{
                                                              padding: '0px'
                                                            }}
                                                            className={
                                                              titleActions
                                                            }
                                                            onClick={(e) => {
                                                              e.stopPropagation()
                                                              setEdit(
                                                                (prev) => {
                                                                  return {
                                                                    ...prev,
                                                                    [currentSessionId]: false
                                                                  }
                                                                }
                                                              )
                                                            }}
                                                          >
                                                            <ClearIcon />
                                                          </IconButton>
                                                        </div>
                                                      </>
                                                    ) : (
                                                      <>
                                                        <div
                                                          style={{
                                                            display: 'flex'
                                                          }}
                                                        >
                                                          <MenuButton
                                                            options={
                                                              menuOptions
                                                            }
                                                            menuOpenCallback={() => {
                                                              setCurrentActiveMenu(
                                                                currentSessionId
                                                              )
                                                            }}
                                                            menuCloseCallback={() => {
                                                              setCurrentActiveMenu(
                                                                null
                                                              )
                                                            }}
                                                            callBackData={{
                                                              sessionsIndex,
                                                              title,
                                                              currentSessionId,
                                                              sessionData:
                                                                session[1]
                                                            }}
                                                          />
                                                        </div>
                                                      </>
                                                    )}
                                                  </>
                                                </div>
                                              </>
                                            )
                                          }
                                        )
                                      }
                                    )}
                                  </>
                                ) : null
                              }
                            )}
                          </>
                        </InfiniteScroll>
                      )}
                    </div>
                  </Section>
                </Container>
              </Grid>
            )}
            <Grid
              item
              xs
              sm
              lg
              xl
              sx={{ alignContent: 'center', height: '100%' }}
            >
              <Container>
                {!openDrawer && (
                  <SectionFixed>
                    <div
                      id="top-element"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        background: 'white',
                        borderBottom: '1px solid #E5E5E5'
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          margin: '5px'
                        }}
                      >
                        {!openDrawer && (
                          <Tooltip
                            classes={{ tooltip }}
                            title={'Open Sidebar'}
                            placement="right"
                            arrow
                          >
                            <IconButton
                              sx={{ height: '30px' }}
                              disableRipple
                              className={classes?.chatSessionIcon}
                              onClick={() => {
                                setOpenDrawer(!openDrawer)
                              }}
                            >
                              <Chat_Session />
                            </IconButton>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </SectionFixed>
                )}
                <Section>
                  <ChatBot
                    initalPrompt={!loadingSessionHistory && searchKey}
                    historyProps={sessionChat}
                    chatType="session"
                    trackerId={selectedSession}
                    showEmptyChatElements
                    showHistoryByDefault={true}
                    handleChatAddition={updateSessionsChatData}
                    textBoxPlaceholder={'Send a message'}
                    imageTextBoxPlaceHolder={'Describe the image'}
                    updateFeedbackCallback={updateFeedbackCallback}
                    showFilter={true}
                    showImageGeneration={true}
                    s3Obj={s3Obj}
                  />
                </Section>
              </Container>
            </Grid>
          </Grid>
        </Box>
        <Dialog
          open={showShareModal}
          onClose={() => closeShareModal(false)}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            Share Chat Session
            <IconButton
              disableRipple
              edge="end"
              color="inherit"
              onClick={() => closeShareModal()}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent style={{}}>
            <Box sx={{ marginBottom: '20px' }}>
              Selected messages will be shared in a new chat session with the
              chosen users. Messages sent after sharing will not be reflected in
              the shared session.
            </Box>
            <Select
              options={domainUsersOptions}
              onChange={(e) => {
                handleShareUserSelect(e)
              }}
              placeholder="Select Users"
              isMulti
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: 'black !important',
                  marginBottom: '20px',
                  boxShadow: 'none !important'
                }),
                menuList: (baseStyles, state) => ({
                  ...baseStyles,
                  fontSize: '14px ',
                  paddingBottom: '0px !important',
                  height: '100%',
                  overflow: 'auto'
                }),
                option: (baseStyles, state) => ({
                  ...baseStyles,
                  borderBottom: '1px solid #D9D9D9 !important',
                  padding: '5px 10px !important',
                  wordBreak: 'break-word',
                  display: 'flex',
                  alignItems: 'center',
                  color: '#000000 !important'
                })
              }}
            />

            <Box sx={{ display: 'flex' }}>
              <Box sx={{ cursor: 'pointer' }} onClick={() => toggleShareAll()}>
                {shareAll ? (
                  <CheckBoxOutlinedIcon />
                ) : (
                  <CheckBoxOutlineBlankOutlinedIcon />
                )}
              </Box>
              <Box
                onClick={() => toggleShareAll()}
                sx={{ marginLeft: '3px', cursor: 'pointer' }}
              >
                Share the entire session
              </Box>
            </Box>
            {!shareAll && chats?.length > 0 && (
              <Box
                sx={{
                  maxHeight: '400px',
                  overflow: 'auto',
                  border: '1px solid black',
                  borderRadius: '4px',
                  marginBottom: '20px'
                }}
              >
                <List id="historyList" sx={{ padding: '0px' }}>
                  {chats?.map((message, index) => {
                    const { id, author, data = {}, type } = message || {}
                    const {
                      partialText,
                      text,
                      error,
                      imageUrls = []
                    } = data || {}
                    const errortext =
                      type === 'image'
                        ? 'Error during Image Generation\nSomething went wrong! Please try again.'
                        : 'Error\nSomething went wrong! Please try again.'
                    const textToRender = error
                      ? textToHtml(errortext)
                      : partialText
                      ? 'Generation Stopped by User'
                      : text
                    const checked = shareObj?.selectedMessages?.includes(id)
                    return (
                      <ListItem
                        sx={{
                          background: checked
                            ? author === 'Me'
                              ? '#DBEAFF !important'
                              : '#b1cdf4 !important'
                            : 'inherit'
                        }}
                        key={index}
                        className={
                          classes.message +
                          ' ' +
                          classes[`message${message.author}`]
                        }
                      >
                        <Box className={classes.messageWrapper}>
                          <Box
                            sx={{
                              height: '44px',
                              padding: '0px',
                              color: 'black',
                              margin: '0px 5px',
                              visibility:
                                author === 'Me' ? 'visible' : 'hidden',
                              cursor: 'pointer'
                            }}
                            onClick={() =>
                              handleSharedMessageSelect(id, !checked)
                            }
                          >
                            {checked ? (
                              <CheckBoxOutlinedIcon />
                            ) : (
                              <CheckBoxOutlineBlankOutlinedIcon />
                            )}
                          </Box>
                          <div>
                            <div className={classes.avatarWrapper}>
                              <Box className={classes.avatarCheckBox}>
                                {author === 'Them' ? (
                                  <Avatar className={classes.avatar}>
                                    <SvgIcon>
                                      <Robot />
                                    </SvgIcon>
                                  </Avatar>
                                ) : (
                                  <UserAvatar className={classes.avatar} />
                                )}
                              </Box>

                              {type === 'image' && !_.isEmpty(imageUrls) ? (
                                <div className={classes.imageContainer}>
                                  {imageUrls.map((url, idx) => {
                                    return (
                                      <Box
                                        key={idx}
                                        className={classes.imageWrapper}
                                      >
                                        <ImageRender
                                          style={{
                                            height: '100%',
                                            width: '100%',
                                            objectFit: 'contain',
                                            overflow: 'hidden'
                                          }}
                                          id={id + author + idx}
                                          effect="blur"
                                          src={url}
                                          s3Obj={s3Obj}
                                          showLoader={false}
                                        />
                                      </Box>
                                    )
                                  })}
                                </div>
                              ) : (
                                <div className={classes.messageText}>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: textToRender
                                    }}
                                    className={
                                      message?.data?.error
                                        ? classes.errorContainer
                                        : ''
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </Box>
                      </ListItem>
                    )
                  })}
                </List>
              </Box>
            )}
            <Button
              disabled={shareLoading}
              variant="contained"
              color="primary"
              onClick={handleShareSession}
              style={{ float: 'right' }}
            >
              {shareLoading ? 'Sharing...' : 'Share'}
            </Button>
          </DialogContent>
        </Dialog>
      </Section>
      {ConfirmDialog}
    </Container>
  )
}

export default SessionChat
