import { makeStyles } from '@mui/styles'
const useStyles = makeStyles((theme) => ({
  filterWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  addButtonWrapper: {
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    maxHeight: '20px',
    cursor: 'pointer',
    padding: '5px',
    // marginTop: '19px',
    '&:hover': {
      background: '#f4f4f4'
    }
  },
  addIcon: {
    width: '10px',
    height: '20px'
  },
  addIconText: {
    fontFamily: 'PoppinsRegular',
    fontWeight: '500',
    fontSize: '12px',
    color: '#949494',
    lineHeight: '19.5px',
    letterSpacing: '0.01em',
    paddingLeft: '5px'
  },
  fullSize: {
    width: '100%'
  }
}))

export { useStyles }
