import { DELETE_ANALYTIC, TOASTER_ALERT } from '../../../actionTypes'
import { delAnalytics } from '../../../api'
import trackEvent from '../../../../utils/TrackEvent/TrackEvent'
import eventMapping from '../../../../config/eventMapping'

import { toast } from 'react-toastify'
import mixpanelEvents from '../../../../config/mixpanelEvents'

const deleteAnalytic = (data) => async (dispatch) => {
  try {
    const payload = {
      list_of_ids: data
    }
    const response = await delAnalytics(payload)
    if (response.status === 200) {
      trackEvent(eventMapping[DELETE_ANALYTIC], 'SUCCESS', response?.data)
      trackEvent(
        mixpanelEvents.ANALYTICS_FILE_DELETED,
        'SUCCESS',
        {},
        { details: response?.data }
      )
      dispatch({
        type: TOASTER_ALERT,
        payload: {
          message: 'Analytics deleted',
          show: true,
          type: 'success'
        }
      })
      dispatch({
        type: DELETE_ANALYTIC,
        payload: data
      })
    } else {
      trackEvent(
        mixpanelEvents.ANALYTICS_FILE_DELETED,
        'FAILED',
        {},
        { details: response }
      )
    }
  } catch (e) {
    /* toast.error(e); */
    dispatch({
      type: TOASTER_ALERT,
      payload: {
        message: 'Failed to delete analytics',
        show: true,
        type: 'error'
      }
    })
  }
}
export default deleteAnalytic
