import React from 'react'
import Button2 from '../../components/Button/Button2'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { Fade } from '@mui/material'
import { AlphabetIcon, CrossIcon } from '../../components/Icons/Icons'

const AlphabetSearch = ({ alphabetKey, setAlphabetKey, meta }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const alphabets = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z'
  ]

  if (alphabetKey) {
    return (
      <Button2 primary onClick={() => setAlphabetKey(null)}>
        <div className="text-sm">{alphabetKey}</div>
        {/* <div
          className="flex items-center px-2 gap-1"
          onClick={() => {
            setAlphabetKey(null)
          }}
        >
          <div className="">{meta.totalCount}</div>
        </div> */}
        <CrossIcon className="size-3 text-white" />
      </Button2>
    )
  }

  return (
    <div>
      <Button2
        secondary
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{}}
      >
        <AlphabetIcon className="size-4 text-grey-600 mr-1" /> A - Z
      </Button2>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        elevation={1}
        TransitionComponent={Fade}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        sx={{
          '&.MuiMenu-paper': {
            boxShadow:
              '3px 2px 16px 2px rgb(117 117 117 / 20%), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)'
          }
        }}
      >
        <p className="m-0 mt-2 px-4  text-xs font-medium">Starts with</p>
        <div className="grid grid-col-5 gap-1 p-2">
          {alphabets.map((letter) => {
            return (
              <Button2
                secondary
                noOutline
                key={letter}
                style={{
                  justifyContent: 'center',
                  fontSize: '14px',
                  fontFamily: 'PoppinsMedium'
                }}
                onClick={() => {
                  setAlphabetKey(letter)
                  handleClose()
                }}
              >
                {letter}
              </Button2>
            )
          })}
        </div>
      </Menu>
    </div>
  )
}

export default AlphabetSearch
