import { useEffect, useState } from 'react'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  SvgIcon,
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField
} from '@mui/material'
import { useStyles } from './styles'
import { ReactComponent as SortUp } from '../../assets/images/SortUp.svg'
import { ReactComponent as SortDown } from '../../assets/images/SortDown.svg'
import moment from 'moment'
import clsx from 'clsx'
import AddIcon from '@mui/icons-material/Add'
import { useDispatch } from 'react-redux'
import {
  updateValue,
  updateMultiValue,
  addMultiValue
} from '../../store/TagCenter/Actions'
import { v4 as uuid } from 'uuid'
import { toast } from 'react-toastify'
import {
  Button,
  TextInput,
  ReactSelect,
  Container,
  Section,
  ContentEditable
} from '../../components'
import Select from 'react-select'
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined'
import Papa from 'papaparse'
import { defaultDocumentTypes } from '../../config/constants'
import Pagination from '@mui/material/Pagination'
import CircularProgress from '@mui/material/CircularProgress'
import { renderURL } from '../../utils/TagCenter'

function descendingComparator(a, b, orderBy) {
  const orderA = a[orderBy] || ''
  const orderB = b[orderBy] || ''
  if (orderB && moment(orderB).isValid() && moment(orderA).isValid()) {
    if (moment(orderB) < moment(orderA)) {
      return -1
    } else {
      return 1
    }
  }
  if (orderB < orderA) {
    return -1
  }
  if (orderB > orderA) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array = [], comparator) {
  const stabilizedThis = array.map((el, indexStableSort) => [
    el,
    indexStableSort
  ])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

const SortTable = (props) => {
  const {
    isDocumentType = false,
    tagData = {},
    tagKey = '',
    tagKeyEditable = true,
    showConfirmDialog = () => {},
    selectedRows = [],
    setSelectedRows = () => {},
    canCreate = false,
    canEdit = false,
    canDelete = false
  } = props

  const classes = useStyles()
  const {
    tableHeader,
    cellText,
    tablebody,
    tablesortlabel,
    tablerow1,
    tableStyle,
    sortLabel,
    sortIcon,
    sortIconActive,
    multiSelectWrapper
  } = classes

  const { data, type: keyType, synced: keySynced = true } = tagData || {}
  const keyDisabled = !keySynced
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('')
  const [showAddRow, setShowAddRow] = useState(false)
  const [showEditRow, setShowEditRow] = useState({})
  const [editVal, setEditVal] = useState({})
  const [editDocumentType, setEditDocumentType] = useState({})
  const [newVal, setNewVal] = useState('')
  const [newDocumentType, setNewDocumentType] = useState('')
  const [showMergeConfirm, setShowMergeConfirm] = useState(false)
  const [destinationValue, setDestinationValue] = useState('')
  const dispatch = useDispatch()
  const [currentRowData, setCurrentRowData] = useState([])
  const [showCSVDialog, setShowCSVDialog] = useState(false)
  const [csvValues, setCSVValues] = useState([])
  const [page, setPage] = useState(1)
  const [startImporting, setStartImporting] = useState(false)
  const [tablePage, setTablePage] = useState(1)
  const tablePageSize = 100

  useEffect(() => {
    const rows = []
    data.forEach((row, index) => {
      if (row?.value) {
        rows.push({
          id: row.id,
          valueTag: row.value,
          usageCount: row.usage_count,
          synced: row.synced,
          valueType: row.value_type,
          index
        })
      }
    })
    const currData = stableSort(rows, getComparator(order, orderBy))
    setCurrentRowData(currData)
  }, [data, order, orderBy])

  const rowSelection = (id) => {
    if (selectedRows.includes(id)) {
      const selectedIndex = selectedRows.indexOf(id)
      selectedRows.splice(selectedIndex, 1)
      setSelectedRows([...selectedRows])
    } else {
      setSelectedRows([...selectedRows, id])
    }
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    const isDesc = orderBy === property && order === 'desc'
    const newOrder = isAsc ? 'desc' : isDesc ? '' : 'asc'
    setOrder(newOrder)
    if (newOrder) {
      setOrderBy(property)
    } else {
      setOrderBy('')
    }
  }

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property)
  }

  const getCSVInput = () => {
    const fileInput = document.createElement('input')
    fileInput.type = 'file'
    fileInput.accept = '.csv'
    fileInput.addEventListener('change', (event) => handleCSVUpload(event))
    fileInput.click()
  }

  const handleCSVUpload = (event) => {
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.onload = (e) => {
      const content = e.target.result
      const lines = content.split('\n')
      let headers = Papa.parse(lines[0]).data[0]
      headers = _.compact(headers)
      if (headers.length > 0) {
        setShowCSVDialog({
          headers,
          lines
        })
        setPage(1)
        setStartImporting(false)
      } else {
        toast.error('Invalid CSV file')
      }
    }
    reader.readAsText(file)
  }

  const GetSortIcon = ({ sort }) => {
    return sort ? (
      <Box className={sortLabel}>
        <SvgIcon className={sort === 'asc' ? sortIconActive : sortIcon}>
          <SortUp />
        </SvgIcon>
        <SvgIcon className={sort === 'desc' ? sortIconActive : sortIcon}>
          <SortDown />
        </SvgIcon>
      </Box>
    ) : null
  }
  const headCells = isDocumentType
    ? [
        {
          id: 'valueTag',
          numeric: false,
          disablePadding: true,
          label: 'Tag Name',
          width: '30%'
        },
        {
          id: 'valueType',
          numeric: false,
          disablePadding: true,
          label: 'Value Type',
          width: '30%'
        },
        {
          id: 'usageCount',
          numeric: true,
          disablePadding: false,
          label: 'Number of files',
          width: '20%'
        },
        {
          id: 'tools',
          disablePadding: false,
          label: '',
          width: '20%'
        }
      ]
    : [
        {
          id: 'valueTag',
          numeric: false,
          disablePadding: true,
          label: 'Tag Name',
          width: '40%'
        },
        {
          id: 'usageCount',
          numeric: true,
          disablePadding: false,
          label: 'Number of files',
          width: '35%'
        },
        {
          id: 'tools',
          disablePadding: false,
          label: '',
          width: '25%'
        }
      ]

  const onSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = []
      currentRowData.forEach((n, index) => {
        const { synced = true, id, valueType = '' } = n
        const isDefault = valueType?.includes('default')
        const disabled = !synced || keyDisabled || isDefault
        if (!disabled) {
          newSelecteds.push(id)
        }
      })
      setSelectedRows(newSelecteds)
      return
    }
    setSelectedRows([])
  }

  const handleCancel = () => {
    setNewVal('')
    setShowAddRow(false)
    setNewDocumentType('')
  }

  const handleAddNewValue = () => {
    if (!newVal) {
      toast.error('Tag value is required')
      return
    }
    if (currentRowData.find((row) => row.valueTag === newVal)) {
      toast.error('Tag with the same key already exists')
      return
    }
    if (isDocumentType && !newDocumentType) {
      toast.error('Document type is required')
      return
    }
    const id = uuid()
    const req = {
      id,
      key: tagKey,
      value: newVal,
      status: 'create',
      type: keyType,
      synced: false,
      update_count: '',
      value_type: newDocumentType,
      editable: tagKeyEditable
    }
    dispatch(updateValue(req))
    setNewVal('')
    setShowAddRow(false)
  }

  const handleEditSave = (id) => {
    const index = currentRowData.findIndex((row) => row.id === id)
    if (index === -1) return
    let newValue = editVal[id]
    if (!newValue) {
      newValue = currentRowData[index].valueTag
    } else {
      if (currentRowData.find((row) => row.valueTag === newValue)) {
        toast.error('Tag with the same key already exists')
        return
      }
    }
    if (!newValue) {
      toast.error('Tag key is required')
      return
    }
    const document_type = editDocumentType[id] || currentRowData[id]?.valueType
    const row = currentRowData[index]
    const req = {
      id: row.id,
      key: tagKey,
      value: newValue,
      oldValue: row.valueTag,
      status: 'update',
      type: keyType,
      value_type: isDocumentType ? document_type : '',
      update_count: row.update_count,
      synced: false
    }
    dispatch(updateValue(req))
    setEditVal({
      ...editVal,
      [id]: ''
    })
    setShowEditRow({
      ...showEditRow,
      [id]: false
    })
  }

  useEffect(() => {
    if (currentRowData.length > 0) {
      const maxPage = Math.ceil(currentRowData.length / tablePageSize)
      if (tablePage > maxPage) {
        setTablePage(maxPage)
      }
    }
  }, [currentRowData])

  const handleSingleDelete = (id) => {
    const index = currentRowData.findIndex((row) => row.id === id)
    if (index === -1) return
    const row = currentRowData[index]
    const req = {
      id: row.id,
      key: tagKey,
      value: row.valueTag,
      type: keyType,
      update_count: row.update_count,
      synced: false,
      status: 'delete'
    }
    showConfirmDialog({
      onConfirm: () => {
        if (selectedRows.includes(id)) {
          const selectedIndex = selectedRows.indexOf(id)
          selectedRows.splice(selectedIndex, 1)
          setSelectedRows([...selectedRows])
        }
        dispatch(updateValue(req))
      },
      confirmationMessageTitle: 'Are you sure you want to delete this value?'
    })
  }

  const typeOptions = selectedRows.map((id) => {
    const row = currentRowData.findIndex((row) => row.id === id)
    if (row === -1) return []
    const { valueTag } = currentRowData[row]
    return {
      value: id,
      label: valueTag
    }
  })

  const handleMerge = async () => {
    setStartImporting(true)
    const primaryKey = destinationValue || typeOptions[0]?.value
    if (!primaryKey) {
      toast.error('Please select destination value')
      return
    }
    const chunkSize = 50
    for (let i = 0; i < selectedRows.length; i += chunkSize) {
      const chunk = selectedRows.slice(i, i + chunkSize)
      const tags = chunk
        .map((id) => {
          if (id !== primaryKey) {
            return { id }
          }
          return null
        })
        .filter(Boolean)
      await mergeChunk(tags, primaryKey)
    }
    setSelectedRows([])
    setStartImporting(false)
    setShowMergeConfirm(false)
  }

  const mergeChunk = (chunk, primaryKey) => {
    return new Promise((resolve) => {
      const reqData = {
        tags: chunk,
        status: 'merge',
        synced: false,
        key: tagKey,
        primary_tag_id: primaryKey
      }
      dispatch(updateMultiValue(reqData, resolve))
    })
  }

  const handleMultiDelete = () => {
    if (selectedRows?.length < 1) {
      toast.error('Please select at least one value')
      return
    }
    showConfirmDialog({
      onConfirm: async () => {
        const toastId = toast.info(
          <div style={{ display: 'flex' }}>
            {'Deleting Tags'}&nbsp;
            <CircularProgress size={20} />
          </div>,
          {
            autoClose: false,
            closeOnClick: false,
            closeButton: false,
            draggable: false
          }
        )
        const chunkSize = 500
        for (let i = 0; i < selectedRows.length; i += chunkSize) {
          const chunk = selectedRows.slice(i, i + chunkSize)
          const tags = chunk.map((id) => {
            return { id }
          })
          await deleteChunk(tags)
        }
        toast.dismiss(toastId)
        setSelectedRows([])
      },
      confirmationMessageTitle: `Are you sure you want to delete ${
        selectedRows.length
      } ${selectedRows.length > 1 ? 'values' : 'value'}?`
    })
  }

  const deleteChunk = (chunk) => {
    return new Promise((resolve) => {
      const reqData = {
        tags: chunk,
        status: 'delete',
        synced: false,
        key: tagKey
      }
      dispatch(updateMultiValue(reqData, resolve))
    })
  }

  const closeDialog = () => {
    if (startImporting) return
    setShowMergeConfirm(false)
  }

  const openDialog = () => {
    if (selectedRows?.length < 2) {
      toast.error('Please select at least two values')
      return
    }
    setShowMergeConfirm(true)
  }

  const renderEditDialog = () => {
    return (
      <Dialog
        fullWidth
        maxWidth="xs"
        open={showMergeConfirm}
        onClose={closeDialog}
      >
        <DialogTitle>{'Choose Destination Value for Merge'}</DialogTitle>
        <DialogContent>
          <Select
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              control: (baseStyles, state) => {
                if (state?.isFocused) {
                  return {
                    ...baseStyles,
                    borderColor: 'black !important',
                    boxShadow: 'none !important'
                  }
                }
                return {
                  ...baseStyles,
                  boxShadow: 'none !important'
                }
              },
              menuList: (baseStyles, state) => ({
                ...baseStyles,
                fontSize: '14px ',
                paddingBottom: '0px !important',
                height: '100%',
                overflow: 'auto'
              }),
              option: (baseStyles, state) => ({
                ...baseStyles,
                borderBottom: '1px solid #D9D9D9 !important',
                padding: '5px 10px !important',
                wordBreak: 'break-word',
                display: 'flex',
                alignItems: 'center',
                color: '#000000 !important'
              })
            }}
            options={typeOptions}
            onChange={(e) => {
              setDestinationValue(e.value)
            }}
            isDisabled={keyDisabled}
            placeholder="Destination Value"
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={startImporting} onClick={() => handleMerge()}>
            {startImporting ? <CircularProgress size={16} /> : 'Merge'}
          </Button>
          <Button
            disabled={startImporting}
            variant="outlined"
            onClick={closeDialog}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const closeDialogCSV = () => {
    if (startImporting) return
    setShowCSVDialog(false)
  }

  const convertHeaderToData = () => {
    const {
      selectedHeader = [],
      includeHeader = false,
      lines = []
    } = showCSVDialog || {}
    const valuesToAdd = []
    selectedHeader.forEach((header, index) => {
      const { value } = header
      const parsedHeader = Papa.parse(lines[0]).data[0]
      const headerIndex = parsedHeader.indexOf(value)
      console.log(parsedHeader, value, headerIndex)
      if (headerIndex === -1) return
      for (let i = includeHeader ? 0 : 1; i < lines.length; i++) {
        const parsedLine = Papa.parse(lines[i]).data[0]
        if (parsedLine) {
          const value = parsedLine[headerIndex]
          if (value) {
            valuesToAdd.push(value)
          }
        }
      }
    })
    const uniqueValues = _.uniq(_.compact(_.map(valuesToAdd, _.trim)))
    setCSVValues(uniqueValues)
  }

  useEffect(() => {
    convertHeaderToData()
  }, [showCSVDialog])

  const processChunk = (chunk) => {
    return new Promise((resolve) => {
      const tags = chunk.map((value) => ({
        id: uuid(),
        key: tagKey,
        value,
        status: 'create',
        type: keyType,
        synced: false,
        update_count: '',
        editable: tagKeyEditable
      }))
      const reqData = { tags }
      dispatch(addMultiValue(reqData, resolve))
    })
  }

  const handleCSVImport = async () => {
    if (csvValues.length < 1) {
      toast.error('No values to import')
    } else {
      setStartImporting(true)
      const chunkSize = 500
      for (let i = 0; i < csvValues.length; i += chunkSize) {
        const chunk = csvValues.slice(i, i + chunkSize)
        await processChunk(chunk)
      }
      setStartImporting(false)
      closeDialogCSV()
    }
  }

  const [selectedCSVValue, setSelectedCSVValue] = useState('')

  const renderCSVSelect = () => {
    const itemsPerPage = 500
    const numberOfPages = Math.ceil(csvValues.length / itemsPerPage)
    const options =
      showCSVDialog?.headers?.map((header, index) => {
        return {
          value: header,
          label: header
        }
      }) || []
    const isIncludeHeader = showCSVDialog?.includeHeader || false
    const selectedHeader = showCSVDialog?.selectedHeader || ''
    return (
      <Dialog
        fullWidth
        maxWidth="xs"
        open={showCSVDialog}
        onClose={closeDialogCSV}
      >
        <DialogTitle>Select Column to be Imported</DialogTitle>
        <DialogContent>
          <ReactSelect
            isMulti
            options={options}
            value={selectedHeader}
            onChange={(e) =>
              setShowCSVDialog({
                ...showCSVDialog,
                selectedHeader: e
              })
            }
          />
          <Box sx={{ marginTop: '10px' }}>
            <Checkbox
              disableRipple
              checked={isIncludeHeader}
              onChange={(e) =>
                setShowCSVDialog({
                  ...showCSVDialog,
                  includeHeader: e.target.checked
                })
              }
              color="primary"
            />
            <span>Include Header</span>
          </Box>
          {csvValues.length > 0 && (
            <>
              <List
                sx={{
                  marginTop: '10px',
                  border: '1px solid #E5E5E5',
                  maxHeight: '200px',
                  overflow: 'auto',
                  padding: '0px'
                }}
              >
                {csvValues
                  .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                  .map((value, index) => {
                    const isSelected = selectedCSVValue === index
                    return (
                      <ListItem
                        key={index}
                        sx={{
                          background: isSelected ? '#DBEAFF' : 'inherit',
                          padding: '0px'
                        }}
                      >
                        <ListItemButton
                          sx={{ padding: '0px' }}
                          onClick={() => {
                            if (isSelected) {
                              setSelectedCSVValue('')
                            } else {
                              setSelectedCSVValue(index)
                            }
                          }}
                        >
                          <ListItemText
                            primary={
                              <Box
                                sx={{ display: 'flex', alignItems: 'center' }}
                              >
                                <IconButton
                                  disableRipple
                                  onClick={(e) => {
                                    const newCSVValues = [...csvValues]
                                    newCSVValues.splice(index, 1)
                                    setCSVValues(newCSVValues)
                                    e.stopPropagation()
                                  }}
                                >
                                  <IndeterminateCheckBoxOutlinedIcon />
                                </IconButton>
                                {isSelected ? (
                                  <ContentEditable
                                    html={value}
                                    onClick={(e) => {
                                      e.stopPropagation()
                                    }}
                                    onDoubleClick={(e) => {
                                      e.stopPropagation()
                                    }}
                                    style={{
                                      width: '100%',
                                      borderRadius: '4px',
                                      border: '1px solid #000000',
                                      wordBreak: 'break-word'
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.key === 'Enter') {
                                        e.target.blur()
                                      }
                                    }}
                                    placeholder="File Name"
                                    onChange={(e) => {
                                      const newCSVValues = [...csvValues]
                                      newCSVValues[index] =
                                        e.currentTarget.innerText
                                      setCSVValues(newCSVValues)
                                    }}
                                  />
                                ) : (
                                  <Box sx={{ wordBreak: 'break-word' }}>
                                    {value}
                                  </Box>
                                )}
                              </Box>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                    )
                  })}
              </List>
              <Pagination
                count={numberOfPages}
                shape="rounded"
                page={page}
                sx={{
                  marginTop: '10px',
                  '& ul': {
                    justifyContent: 'center'
                  }
                }}
                onChange={(event, value) => setPage(value)}
              />
            </>
          )}
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: 'space-between', padding: '10px 24px' }}
        >
          {csvValues.length > 0 ? (
            <Box>
              Importing {csvValues.length}{' '}
              {csvValues.length > 1 ? 'values' : 'value'}
            </Box>
          ) : (
            <Box></Box>
          )}
          <Box sx={{ display: 'flex', gap: '10px' }}>
            <Button
              disabled={csvValues.length < 1 || startImporting}
              onClick={() => handleCSVImport()}
            >
              {startImporting ? <CircularProgress size={16} /> : 'Import'}
            </Button>
            <Button
              disabled={startImporting}
              variant="outlined"
              onClick={closeDialogCSV}
            >
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <Container>
      <Section overFlow>
        {renderCSVSelect()}
        {renderEditDialog()}
        {selectedRows.length > 0 ? (
          <Box className={classes.tableSaveButtonWrapper}>
            <Box>
              Selected {selectedRows.length}{' '}
              {selectedRows.length > 1 ? 'values' : 'value'}
            </Box>
            <Box className={classes.tableSaveButtons}>
              <Box
                className={clsx(
                  classes.tableOptions,
                  (keyDisabled || !canDelete) && classes.disabledRow
                )}
                onClick={() =>
                  !(keyDisabled || !canDelete) && handleMultiDelete()
                }
              >
                Delete Selected
              </Box>
              {!isDocumentType && (
                <Box
                  className={clsx(
                    classes.tableOptions,
                    (keyDisabled || !canDelete) && classes.disabledRow
                  )}
                  onClick={() => !(keyDisabled || !canDelete) && openDialog()}
                >
                  Merge Selected
                </Box>
              )}
              <Box
                className={clsx(
                  classes.tableOptions,
                  keyDisabled && classes.disabledRow
                )}
                onClick={() => !keyDisabled && setSelectedRows([])}
              >
                Cancel
              </Box>
              {[
                'multiselect',
                'singleselect',
                'multiurl',
                'singleurl'
              ].includes(keyType) && (
                <Box
                  className={clsx(
                    classes.tableOptions,
                    (keyDisabled || !canCreate) && classes.disabledRow
                  )}
                  onClick={() =>
                    !(keyDisabled || !canCreate) && setShowAddRow(true)
                  }
                >
                  New Value
                </Box>
              )}
              {[
                'multiselect',
                'singleselect',
                'multiurl',
                'singleurl'
              ].includes(keyType) &&
                !isDocumentType && (
                  <>
                    <Box
                      className={clsx(
                        classes.tableOptions,
                        (keyDisabled || !canCreate) && classes.disabledRow
                      )}
                      onClick={() =>
                        !(keyDisabled || !canCreate) && getCSVInput()
                      }
                    >
                      Import CSV
                    </Box>
                  </>
                )}
            </Box>
          </Box>
        ) : (
          ['multiselect', 'singleselect', 'multiurl', 'singleurl'].includes(
            keyType
          ) && (
            <Box className={classes.tableSaveButtonWrapper}>
              <Box></Box>
              <Box className={classes.tableSaveButtons}>
                <Box
                  className={clsx(
                    classes.tableOptions,
                    (keyDisabled || !canCreate) && classes.disabledRow
                  )}
                  onClick={() =>
                    !(keyDisabled || !canCreate) && setShowAddRow(true)
                  }
                >
                  New Value
                </Box>
                {!isDocumentType && (
                  <>
                    <Box
                      className={clsx(
                        classes.tableOptions,
                        (keyDisabled || !canCreate) && classes.disabledRow
                      )}
                      onClick={() =>
                        !(keyDisabled || !canCreate) && getCSVInput()
                      }
                    >
                      Import CSV
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          )
        )}
        {currentRowData.length > 0 && currentRowData.length > tablePageSize && (
          <Pagination
            count={Math.ceil(currentRowData.length / tablePageSize)}
            shape="rounded"
            page={tablePage}
            onChange={(event, value) => setTablePage(value)}
            sx={{ marginTop: '10px', '& ul': { justifyContent: 'center' } }}
          />
        )}
        <Table classes={tableStyle}>
          <TableHead>
            <TableRow>
              <TableCell
                className={clsx(tableHeader, multiSelectWrapper)}
                style={{ paddingLeft: '4x' }}
              >
                <Checkbox
                  disabled={keyDisabled}
                  color="primary"
                  checked={selectedRows.length > 0}
                  onChange={onSelectAllClick}
                  size="small"
                  indeterminate={selectedRows.length > 0}
                />
              </TableCell>
              {headCells.map((headCell, indexTableRow) => {
                const { id, sort } = headCell
                return (
                  <TableCell
                    className={tableHeader}
                    sx={{ width: headCell.width }}
                    key={indexTableRow}
                    sortDirection={orderBy === id ? order : sort}
                  >
                    <TableSortLabel
                      active={false}
                      IconComponent={() => (
                        <GetSortIcon sort={orderBy === id ? order : sort} />
                      )}
                      onClick={createSortHandler(id)}
                      className={tablesortlabel}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody classes={tablebody}>
            {currentRowData
              .slice((tablePage - 1) * tablePageSize, tablePage * tablePageSize)
              .map((row, indexStableSort) => {
                const { synced = true, id, valueType = '' } = row
                const isDefault = valueType?.includes('default')
                const disabled = !synced || keyDisabled || isDefault
                const isEdit = showEditRow[id]
                const value = ['multidate', 'singledate'].includes(keyType)
                  ? moment(row.valueTag).format('ll')
                  : row.valueTag
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={indexStableSort}
                    className={clsx(
                      tablerow1,
                      disabled && !isDefault && classes.disabledRow
                    )}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        disabled={disabled}
                        className={disabled && classes.disabledRow}
                        checked={selectedRows.includes(id)}
                        onChange={() => {
                          rowSelection(id)
                        }}
                        size="small"
                      />
                    </TableCell>
                    {isEdit ? (
                      <TableCell
                        colSpan={1}
                        component={'span'}
                        className={clsx(cellText, classes.tableTextInput)}
                      >
                        <TextInput
                          placeholder="Tag Value"
                          value={editVal[id] || row.valueTag}
                          variant="standard"
                          autoFocus
                          onKeyDown={(event) => {
                            if (!disabled) {
                              if (event.key === 'Enter') {
                                handleEditSave(id)
                              } else if (event.key === 'Escape') {
                                setShowEditRow({
                                  ...showEditRow,
                                  [id]: false
                                })
                              }
                            }
                          }}
                          disabled={keyDisabled}
                          handleChange={(e) => {
                            setEditVal({
                              ...editVal,
                              [id]: e.target.value
                            })
                          }}
                        />
                      </TableCell>
                    ) : (
                      <TableCell component={'span'} className={cellText}>
                        {keyType?.includes('url') ? renderURL(value) : value}{' '}
                        {isDefault && <span>(default)</span>}
                      </TableCell>
                    )}
                    {isDocumentType && (
                      <>
                        {isEdit ? (
                          <TableCell
                            colSpan={1}
                            component={'span'}
                            className={cellText}
                          >
                            <ReactSelect
                              value={{
                                value: editDocumentType[id] || row.valueType,
                                label:
                                  editDocumentType[id]?.split('_')[2] ||
                                  row.valueType?.split('_')[2]
                              }}
                              options={defaultDocumentTypes.map((value) => {
                                return {
                                  value: 'document_type_' + value,
                                  label: value
                                }
                              })}
                              onChange={(e) => {
                                setEditDocumentType({
                                  ...editDocumentType,
                                  [id]: e.value
                                })
                              }}
                              isDisabled={keyDisabled}
                              placeholder="Document Types"
                            />
                          </TableCell>
                        ) : (
                          <TableCell component={'span'} className={cellText}>
                            {valueType?.split('_')[2]}
                          </TableCell>
                        )}
                      </>
                    )}
                    <TableCell component={'span'} className={cellText}>
                      {row.usageCount}
                    </TableCell>
                    <TableCell className={cellText}>
                      <Box className={classes.tableOptionsWrapper}>
                        {isEdit ? (
                          <>
                            <Box
                              className={clsx(
                                classes.tableOptions,
                                disabled && classes.disabledRow
                              )}
                              onClick={() => {
                                !disabled && handleEditSave(id)
                              }}
                              sx={{
                                wordBreak: 'keep-all'
                              }}
                            >
                              Save
                            </Box>
                            <Box
                              className={clsx(
                                classes.tableOptions,
                                disabled && classes.disabledRow
                              )}
                              sx={{
                                wordBreak: 'keep-all'
                              }}
                              onClick={() => {
                                !disabled &&
                                  setShowEditRow({
                                    ...showEditRow,
                                    [id]: false
                                  })
                              }}
                            >
                              Cancel
                            </Box>
                          </>
                        ) : (
                          <>
                            {[
                              'multiselect',
                              'singleselect',
                              'multiurl',
                              'singleurl'
                            ].includes(keyType) && (
                              <Box
                                className={clsx(
                                  classes.tableOptions,
                                  (disabled || !canEdit) && classes.disabledRow
                                )}
                                sx={{
                                  wordBreak: 'keep-all'
                                }}
                                onClick={() => {
                                  !(disabled || !canEdit) &&
                                    setShowEditRow({
                                      ...showEditRow,
                                      [id]: true
                                    })
                                }}
                              >
                                Edit
                              </Box>
                            )}
                            {!isDocumentType && (
                              <Box
                                className={clsx(
                                  classes.tableOptions,
                                  (disabled || !canDelete) &&
                                    classes.disabledRow
                                )}
                                sx={{
                                  wordBreak: 'keep-all'
                                }}
                                onClick={() => {
                                  !(disabled || !canDelete) && rowSelection(id)
                                }}
                              >
                                Merge
                              </Box>
                            )}
                            <Box
                              className={clsx(
                                classes.tableOptions,
                                (disabled || !canDelete) && classes.disabledRow
                              )}
                              sx={{
                                wordBreak: 'keep-all'
                              }}
                              onClick={() => {
                                !(disabled || !canDelete) &&
                                  handleSingleDelete(id)
                              }}
                            >
                              Delete
                            </Box>
                          </>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                )
              })}
            <TableRow className={tablerow1}>
              {showAddRow ? (
                <>
                  <TableCell padding="checkbox"></TableCell>
                  <TableCell
                    colSpan={1}
                    component={'span'}
                    className={clsx(cellText, classes.tableTextInput)}
                  >
                    {['multiselect', 'singleselect'].includes(keyType) && (
                      <TextInput
                        placeholder="Tag Value"
                        value={newVal}
                        variant="standard"
                        disabled={keyDisabled}
                        autoFocus
                        onKeyDown={(event) => {
                          if (!keyDisabled) {
                            if (event.key === 'Enter') {
                              handleAddNewValue()
                            } else if (event.key === 'Escape') {
                              setShowAddRow(false)
                            }
                          }
                        }}
                        handleChange={(e) => setNewVal(e.target.value)}
                      />
                    )}

                    {['multiurl', 'singleurl'].includes(keyType) && (
                      <TextInput
                        placeholder="Enter URL or use the format: [Link text](https://example.com)"
                        value={newVal}
                        variant="standard"
                        multiline
                        rows={3}
                        disabled={keyDisabled}
                        autoFocus
                        onKeyDown={(event) => {
                          if (!keyDisabled) {
                            if (event.key === 'Enter') {
                              handleAddNewValue()
                            } else if (event.key === 'Escape') {
                              setShowAddRow(false)
                            }
                          }
                        }}
                        handleChange={(e) => setNewVal(e.target.value)}
                      />
                    )}
                  </TableCell>
                  {isDocumentType && (
                    <TableCell
                      colSpan={1}
                      component={'span'}
                      className={cellText}
                    >
                      <ReactSelect
                        options={defaultDocumentTypes.map((value) => {
                          return {
                            value: 'document_type_' + value,
                            label: value
                          }
                        })}
                        onChange={(e) => setNewDocumentType(e.value)}
                        isDisabled={keyDisabled}
                        placeholder="Document Types"
                      />
                    </TableCell>
                  )}
                  <TableCell></TableCell>
                  <TableCell className={cellText}>
                    <Box className={classes.tableOptionsWrapper}>
                      <Box
                        className={clsx(
                          classes.tableOptions,
                          keyDisabled && classes.disabledRow
                        )}
                        onClick={() => !keyDisabled && handleAddNewValue()}
                      >
                        Add
                      </Box>
                      <Box
                        className={clsx(
                          classes.tableOptions,
                          keyDisabled && classes.disabledRow
                        )}
                        onClick={() => !keyDisabled && handleCancel()}
                      >
                        Cancel
                      </Box>
                    </Box>
                  </TableCell>
                </>
              ) : (
                <>
                  <TableCell padding="checkbox">
                    <Box className={classes.emptyCheckbox}></Box>
                  </TableCell>
                  <TableCell
                    colSpan={3}
                    component={'span'}
                    className={cellText}
                  >
                    {[
                      'multiselect',
                      'singleselect',
                      'multiurl',
                      'singleurl'
                    ].includes(keyType) && (
                      <Box
                        className={clsx(
                          classes.tableAdd,
                          (keyDisabled || !canCreate) && classes.disabledRow
                        )}
                        onClick={() =>
                          !(keyDisabled || !canCreate) && setShowAddRow(true)
                        }
                      >
                        <AddIcon fontSize="16" /> Value Tag
                      </Box>
                    )}
                  </TableCell>
                </>
              )}
            </TableRow>
          </TableBody>
        </Table>
      </Section>
    </Container>
  )
}

export default SortTable
