function formatTimeAgo(date, v2 = false) {
  const now = new Date()
  const localTime = convertUTCToLocalTime(date)
  const diffInMilliseconds = now - new Date(localTime)
  const diffInSeconds = Math.floor(diffInMilliseconds / 1000)
  const diffInMinutes = Math.floor(diffInSeconds / 60)
  const diffInHours = Math.floor(diffInMinutes / 60)
  const diffInDays = Math.floor(diffInHours / 24)

  // conditions -> 1 day ago - yesterday, 2 days ago - 2 days ago, 12 days ago - 12 days ago

  if (v2) {
    if (diffInDays > 0) {
      if (diffInDays === 1) {
        return `yesterday`
      } else {
        return `${diffInDays} days ago`
      }
    } else if (diffInMinutes > 0) {
      // time h:mm
      return new Date(localTime).toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit'
      })
    } else {
      return `just now`
    }
  }

  if (diffInDays > 0) {
    return `${diffInDays} day${diffInDays > 1 ? 's' : ''} ago`
  } else if (diffInHours > 0) {
    return `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago`
  } else if (diffInMinutes > 0) {
    return `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''} ago`
  } else {
    return `just now`
  }
}

export default formatTimeAgo

function convertUTCToLocalTime(utcDateString) {
  // Parse the UTC date string into a Date object
  const utcDate = new Date(utcDateString)

  // Convert the date to the current time zone
  const localDateString = utcDate.toLocaleString()

  return localDateString
}
