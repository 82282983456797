import { useState, useEffect } from 'react'
import { Box, Grid, IconButton } from '@mui/material'
import { SelectDropDown } from '../../components'
import MultiSelectDropDown from '../../components/MultiSelectDropDown'
import { useStyles } from './styles'
import CloseIcon from '../../assets/images/CloseIcon.svg'
import Image from '../../components/ImageElement'
import DateRangePicker from '../../components/DateRangerPicker/dateRangerPicker'
import useDidMountEffect from '../../hooks'
import clsx from 'clsx'
import { CrossIcon } from '../../components/Icons/Icons'

const AdvancedFilter = (props) => {
  const {
    id = 'advanced-filter',
    keyId = '',
    selectedFilter,
    handleValueSelection,
    removeFilter,
    fullWidth = false,
    selectLabel = 'Select Filter',
    showCloseIcon = true,
    valueWidth = 100
  } = props
  const classes = useStyles()
  const {
    radioSelect,
    rootContainer,
    closeIcon,
    multiSelect,
    imageWrapper,
    wrapper,
    fullWidthContainer,
    imageSetter,
    fullWidthWrapper
  } = classes

  const [selectedKey, setSelectedKey] = useState('')
  const [selectedValue, setSelectedValue] = useState([])
  const [keyOptions, setKeyOptions] = useState([])
  const [valueOptions, setValueOptions] = useState([])
  const [keyIndex, setKeyIndex] = useState(0)
  const [showCloseIconOnHover, setShowCloseIconOnHover] = useState(false)

  useEffect(() => {
    const { key, value, keyList, valueList } = selectedFilter
    setSelectedKey(key)
    setSelectedValue(value)
    setKeyOptions(keyList)
    setValueOptions(valueList)
  }, [selectedFilter])

  const handleKeyChange = (value, selection, id) => {
    setSelectedKey(value)
    handleValueSelection(value, [], keyId, 'key', id)
    setKeyIndex(id)
  }

  const handleValueChange = (selectedItem) => {
    setSelectedValue(selectedItem)
    handleValueSelection(selectedKey, selectedItem, keyId, 'value', keyIndex)
  }

  const handleClose = () => {
    removeFilter(keyId)
  }

  const keyValue = selectedKey.includes('tag_')
    ? selectedKey.replace('tag_', '')
    : selectedKey
  return (
    <Grid className={rootContainer}>
      <Grid
        container
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'center'}
        id="advanced-filter"
        className={wrapper}
        onMouseEnter={() => setShowCloseIconOnHover(true)}
        onMouseLeave={() => setShowCloseIconOnHover(false)}
      >
        <Grid
          className={
            fullWidth ? clsx(radioSelect, fullWidthContainer) : radioSelect
          }
        >
          <SelectDropDown
            id={id}
            options={keyOptions}
            handleChange={handleKeyChange}
            value={keyValue}
            placeholder={selectLabel}
          />
        </Grid>
        {selectedKey !== 'Created On' ? (
          <Grid
            id="multi-select-checklist"
            className={
              fullWidth ? clsx(multiSelect, fullWidthContainer) : multiSelect
            }
          >
            <MultiSelectDropDown
              disabled={!keyValue}
              value={selectedValue}
              placeholder="All"
              options={valueOptions}
              handleChange={handleValueChange}
              fullWidth={fullWidth}
              width={valueWidth}
            />
          </Grid>
        ) : (
          <Box className={multiSelect}>
            <DateRangePicker
              setRange={handleValueChange}
              range={selectedValue}
              placeholder={'All'}
            />
          </Box>
        )}

        {showCloseIcon && (
          <Grid
            className={
              fullWidth ? clsx(imageSetter, imageWrapper) : imageWrapper
            }
            sx={{
              display: !showCloseIconOnHover ? 'none' : 'flex'
            }}
          >
            <IconButton onClick={() => handleClose()} className={closeIcon}>
              {/* <Image src={CloseIcon} /> */}
              <CrossIcon  className="size-4 "/>
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

/* className={ fullWidth? clsx(imageSetter, imageWrapper ) : imageWrapper} */

export default AdvancedFilter
