import {
  CLEAR_PEOPLE,
  FETCH_PEOPLE,
  UPDATE_PEOPLE,
  ADD_PEOPLE,
  DELETE_PEOPLE,
  MERGE_PEOPLE,
  UPDATE_PEOPLE_TAG,
  UPDATE_PEOPLE_NAME,
  UPDATE_PEOPLE_NOTES
} from '../../actionTypes'
import { cloneDeep, findIndex, isArray } from 'lodash'

const initialState = {
  peopleList: null,
  hasMoreData: false,
  currentPage: null,
  pageSize: null,
  filters: {}
}

function poepleList(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case FETCH_PEOPLE:
      return {
        ...state,
        ...payload
      }

    case CLEAR_PEOPLE:
      return {
        ...state,
        peopleList: 'loading',
        hasMoreData: false,
        currentPage: null,
        pageSize: null,
        peopleData: null
      }

    case ADD_PEOPLE: {
      const { peopleList = [], currentPage, hasMoreData, filters } = payload
      return {
        ...state,
        peopleList: [...(state.peopleList || []), ...peopleList],
        currentPage,
        hasMoreData,
        filters
      }
    }
    case UPDATE_PEOPLE: {
      const newPeopleList = cloneDeep(state.peopleList)
      const { id, type, masterId, updateObj } = payload
      if (type === 'master') {
        const index = findIndex(newPeopleList, { id })
        if (index !== -1) {
          const { resumes = '' } = updateObj
          if (isArray(resumes) && _.isEmpty(resumes)) {
            newPeopleList.splice(index, 1)
            // count = count - 1
          } else {
            newPeopleList[index] = { ...newPeopleList[index], ...updateObj }
          }
        }
      } else {
        const index = findIndex(newPeopleList, { id: masterId })
        if (index !== -1) {
          const resumeIndex = findIndex(newPeopleList[index].resumes, { id })
          if (resumeIndex !== -1) {
            newPeopleList[index].resumes[resumeIndex] = {
              ...newPeopleList[index].resumes[resumeIndex],
              ...updateObj
            }
          }
        }
      }
      return {
        ...state,
        peopleList: newPeopleList
      }
    }
    case DELETE_PEOPLE: {
      const id = payload
      const newPeopleList = cloneDeep(state.peopleList)
      const index = findIndex(newPeopleList, { id })
      if (index !== -1) {
        newPeopleList.splice(index, 1)
        // count = count - 1
      }
      return {
        ...state,
        peopleList: newPeopleList
      }
    }
    case MERGE_PEOPLE: {
      const { group_ids = [] } = payload
      const newPeopleList = cloneDeep(state.peopleList)
      group_ids.forEach((id, index) => {
        if (index !== 0) {
          const index = findIndex(newPeopleList, { id })
          if (index !== -1) {
            newPeopleList.splice(index, 1)
            // count = count - 1
          }
        }
      })
      const primaryId = group_ids[0]
      const primaryIndex = findIndex(newPeopleList, { id: primaryId })
      newPeopleList[primaryIndex].status = 'merging'
      return {
        ...state,
        peopleList: newPeopleList
      }
    }
    case UPDATE_PEOPLE_TAG: {
      const { type, id, tags } = payload
      const newPeopleList = cloneDeep(state.peopleList)
      if (newPeopleList) {
        if (type === 'masterresume') {
          newPeopleList.forEach((people) => {
            if (id.includes(people.id)) {
              people.tags = tags
            }
          })
        } else if (type === 'resume') {
          const { resume_id } = payload
          const index = newPeopleList.findIndex((people) => people.id === id)
          if (index !== -1) {
            const resumeIndex = newPeopleList[index].resumes.findIndex(
              (resume) => resume.id === resume_id
            )
            if (resumeIndex !== -1) {
              newPeopleList[index].resumes[resumeIndex].tags = tags
            }
          }
        }
      }
      return {
        ...state,
        peopleList: newPeopleList
      }
    }

    case UPDATE_PEOPLE_NAME: {
      const { id, employee_name } = payload
      const newPeopleList = cloneDeep(state.peopleList)
      if(newPeopleList === null){
        return state
      }
      const index = newPeopleList.findIndex((people) => people.id === id)
      newPeopleList[index].name = employee_name
      return {
        ...state,
        peopleList: newPeopleList
      }
    }

    case UPDATE_PEOPLE_NOTES: {
      const { id, notes } = payload
      const newPeopleList = cloneDeep(state.peopleList)
      const index = newPeopleList.findIndex((people) => people.id === id)
      newPeopleList[index].notes = notes

      return {
        ...state,
        peopleList: newPeopleList
      }
    }

    default:
      return state
  }
}

export default poepleList
