import React from 'react'
import { Box, Divider, Menu, MenuItem } from '@mui/material'
import { useStyles } from './styles'
import ToolTipButton from './TooltipButton'
import { ReactComponent as ExpandDownIcon } from '../../assets/svg/ExpandDown.svg'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import { Button } from '../../components'
import Button2 from '../Button/Button2'

const GridSort = (props) => {
  const {
    disabled = false,
    columns = [],
    GetSortIcon = () => <></>,
    handleColumnClick = () => {},
    sortOrder = {},
    formatHeader = (e) => {
      return e
    }
  } = props

  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSort = (column) => {
    handleColumnClick(column)
    handleClose()
  }

  const sortOrderKeys = Object.keys(sortOrder)

  return (
    <Box sx={{ display: 'flex' }}>
      <Button2 secondary disabled={disabled} onClick={handleClick}>
        <ToolTipButton
          title="Sort Grid"
          onBreak={<SwapVertIcon sx={{ fontSize: '18px' }} />}
        >
          <Box className="flex items-center gap-1 text-xxs">
            <SwapVertIcon sx={{ fontSize: '16px' }} />
            <Box className={classes.buttonText}>
              Sort by
              {sortOrderKeys.length > 0 ? `: ${sortOrderKeys.length}` : ''}
            </Box>
            <ExpandDownIcon sx={{ fontSize: '18px' }} />
          </Box>
        </ToolTipButton>
      </Button2>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        elevation={1}
        className={classes.sortMenu}
      >
        {columns.map((column, index) => (
          <Box key={index}>
            <MenuItem onClick={() => handleSort(column)}>
              <Box className={classes.hideMenuListText}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                    width: '100%',
                    gap: '10px'
                  }}
                >
                  <GetSortIcon
                    id={column}
                    isSelected={Object.keys(sortOrder).includes(column)}
                    order={sortOrder[column]}
                  />
                  {formatHeader(column)}
                </Box>
              </Box>
            </MenuItem>
            {index < columns.length - 1 && (
              <Divider className={classes.hideMenuDivider} />
            )}
          </Box>
        ))}
      </Menu>
    </Box>
  )
}

export default GridSort
