import {
  DELETE_LIBRARY_DOCUMENT,
  UPDATE_LIBRARY_STATUS
} from '../../../actionTypes'
import { deleteLibrary } from '../../../api'
import trackEvent from '../../../../utils/TrackEvent/TrackEvent'
import eventMapping from '../../../../config/eventMapping'
import mixpanelEvents from '../../../../config/mixpanelEvents'
import { toast } from 'react-toastify'

const deleteLibraryDocument =
  (data, callback = () => {}) =>
  async (dispatch) => {
    dispatch({
      type: UPDATE_LIBRARY_STATUS,
      payload: { id: data.id, status: 'deleting' }
    })
    const response = await deleteLibrary(data)
    if (response.status === 200) {
      trackEvent(mixpanelEvents.KNOWLEDGE_CONTENT_DELETED, 'SUCCESS', {}, {})
      dispatch({
        type: DELETE_LIBRARY_DOCUMENT,
        payload: data
      })
      callback()
    } else {
      console.log('Error in deleting knowledge', response)
      trackEvent(mixpanelEvents.KNOWLEDGE_CONTENT_DELETED, 'FAILED', {}, {})
      toast.error('Knowledge deletion failed')
      dispatch({
        type: UPDATE_LIBRARY_STATUS,
        payload: { id: data.id, status: false }
      })
    }
  }

export { deleteLibraryDocument }
