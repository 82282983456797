import { useEffect, useState, isValidElement } from 'react'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import OutlinedInput from '@mui/material/OutlinedInput'
import { useStyles } from './styles'
import Image from '../ImageElement'
import clsx from 'clsx'

const SelectDropDown = (props) => {
  const {
    id = 0,
    menuProps = {},
    value,
    options = [],
    handleChange,
    placeholder = '',
    handleBlurChange = () => {}
  } = props
  const classes = useStyles()
  const {
    rootContainer,
    menuItems,
    selectedMenu,
    noMenuSelection,
    menuIcon,
    menuCategory
  } = classes
  const [selectedId, setSelectedId] = useState('')
  const [open, setOpen] = useState(false)

  const handleMenuChange = (selection, id) => {
    const { value = '' } = selection
    setOpen(false)
    setSelectedId(id)
    handleChange(value, selection, id)
  }

  return (
    <FormControl fullWidth id="select-form">
      <Select
        id={id}
        displayEmpty
        name={'some name'}
        label=""
        open={open}
        renderValue={() => {
          if (!value) {
            return <em style={{ fontStyle: 'normal' }}>{placeholder}</em>
          }
          return value
        }}
        sx={{
          '& .MuiSelect-outlined': {
            padding: '6px 10px',
            fontSize: '14px',
            borderColor: 'var(--grey-200)'
          }
        }}
        className={rootContainer}
        onBlur={handleBlurChange}
        input={
          <OutlinedInput
            onClick={() => {
              setOpen(!open)
            }}
          />
        }
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          ...menuProps
        }}
      >
        {options.map((option, key) => {
          const { label, icon, type = '' } = option
          return type === 'title' ? (
            <MenuItem className={menuCategory} sx={{ cursor: 'inherit' }}>
              {label}
            </MenuItem>
          ) : (
            <MenuItem
              id={`select-list-${key}`}
              onClick={() => {
                handleMenuChange(option, key)
              }}
              key={key}
              className={
                key === selectedId
                  ? options.length - 1 !== key
                    ? clsx(selectedMenu, menuItems)
                    : selectedMenu
                  : options.length - 1 !== key
                  ? clsx(noMenuSelection, menuItems)
                  : noMenuSelection
              }
            >
              {icon && isValidElement(icon)
                ? icon
                : icon && <Image className={menuIcon} src={icon} />}{' '}
              {label}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export default SelectDropDown
