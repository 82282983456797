import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import {
  useNavigate,
  createSearchParams,
  useSearchParams
} from 'react-router-dom'
import {
  Container,
  Section,
  SectionFixed,
  Loader,
  MenuButton,
  useConfirmation,
  ListTable
} from '../../components'
import moment from 'moment'
import ListFilter from '../../sections/ListFilter'
import _, { set, toInteger } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useStyles } from './styles'
import { deleteRfx, fetchRFxDocuments } from '../../store/RFx/Actions'
import { setToasterAlert } from '../../store/Common/Actions'
import useDidMountEffect from '../../hooks'
import { initalizeDownload } from '../../utils/DownloadFromS3/DownloadFromS3'
import EmptyRecord from '../../sections/EmptyRecords'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'
import { CloudUpload, MagicStar } from '../../components/Icons/Icons'
import Button2 from '../../components/Button/Button2'
import SearchNew from '../../sections/ListFilter/SearchNew'
import { setUploader } from '../../store/Uploader/Actions'
import HeadlessTable from '../../components/TableV2/HeadlessTable'
import DownloadIcon from '@mui/icons-material/Download'
import DeleteIcon from '@mui/icons-material/Delete'
import { checkUserRoleViewer } from '../../utils/User'
import RefreshIcon from '@mui/icons-material/Refresh'

const rfx = (props) => {
  const { isFocused } = props
  const { ConfirmDialog, showConfirmDialog } = useConfirmation()
  const [searchParams, setSearchParams] = useSearchParams()
  let filterParams, datefromParams, datetoParams, keywordParams
  if (isFocused) {
    filterParams = searchParams.get('filter')
    datefromParams = searchParams.get('from')
    datetoParams = searchParams.get('to')
    keywordParams = searchParams.get('keyword')
  }
  filterParams = filterParams ? JSON.parse(filterParams) : {}
  datefromParams = datefromParams ? new Date(JSON.parse(datefromParams)) : null
  datetoParams = datetoParams ? new Date(JSON.parse(datetoParams)) : null
  keywordParams = keywordParams ? JSON.parse(keywordParams) : ''

  const rfxDocumentsList = useSelector(
    (state) => state.rfxDocumentsList.rfxDocuments ?? []
  )
  const [rows, setRows] = useState([])
  const [filteredRows, setFilteredRows] = useState([])
  const [filterData, setFilterData] = useState({})
  const [page, setPage] = useState(localStorage.getItem('rfxPage') || 1)
  const [PDFData, setPDFData] = useState({})
  const dispatch = useDispatch()
  const [showDrawer, setShowDrawer] = useState(false)
  const [loading, setLoading] = useState(true)
  const [dateRange, setDateRange] = useState([datefromParams, datetoParams])
  const [selectedFilter, setSelectedFilter] = useState(filterParams)
  const [keyValueFilter, setkeyValueFilter] = useState({})
  const [searchValue, setSearchValue] = useState(keywordParams)
  const [advancedFilter, setAdvancedFilter] = useState({})
  const [selectedFilterData, setSelectedFilterData] = useState([])
  const [activeTableRow, setActiveTableRow] = useState(null)
  const navigate = useNavigate()
  const classes = useStyles({ showDrawer })
  const { grid, tableContainer } = classes
  const [activeRows, setActiveRows] = useState([])
  const [selectedActiveRows, setSelectedActiveRows] = useState([])
  const [pageSize, setPageSize] = useState(15)

  const [currentView, setCurrentView] = useState('others')
  const currentUserName = useSelector(
    (state) => state.authenticate.user?.domain?.user_name || ''
  )

  const isUserViewer = checkUserRoleViewer()

  const menuOpenCallback = (data) => {
    const { row, index } = data
    setActiveTableRow(index)
  }

  const renderTableActions = (row, index) => {
    const { rowLoading = false } = row
    return (
      <MenuButton
        disabled={rowLoading}
        options={menuOptions}
        callBackData={row}
        menuOpenCallback={menuOpenCallback}
        menuCloseCallback={() => setActiveTableRow(null)}
      />
    )
  }

  const handleonClickFile = (row) => {
    const rfxData = rfxDocumentsList?.filter(
      (rfx) => rfx.id === row.fileIndex
    )[0]

    const { status = '', location = '' } = rfxData
    if (status && status !== 'completed') {
      if (location) {
        setLoading(true)
        trackEvent(
          mixpanelEvents.RFX_FILE_OPENED,
          'SUCCESS',
          {},
          { message: 'Document insight inprogress..' }
        )
        dispatch(
          setToasterAlert({
            message: 'Document insight inprogress..',
            show: true,
            type: 'success'
          })
        )
        navigate(`/rfx/pdf/${rfxData.id}/0`)
      } else {
        trackEvent(
          mixpanelEvents.RFX_FILE_OPENED,
          'FAILED',
          {},
          { message: 'File not found. Upload is being processed.' }
        )
        dispatch(
          setToasterAlert({
            message: 'File not found. Upload is being processed.',
            show: true,
            type: 'error'
          })
        )
      }
    } else {
      if (location) {
        setLoading(true)
        trackEvent(
          mixpanelEvents.RFX_FILE_OPENED,
          'SUCCESS',
          {},
          { message: 'RFX file opened' }
        )
        navigate(`/rfx/pdf/${rfxData.id}/0`)
      } else {
        dispatch(
          setToasterAlert({
            message: 'RFx not found. Upload is being processed.',
            show: true,
            type: 'error'
          })
        )
      }
    }
  }

  const headCells = [
    {
      id: 'documentName',
      // disablePadding: true,
      label: 'RFx Document Name',
      accessorKey: 'documentName'
      // sort: true,
      // width: '45%',
      // onClick: handleonClickFile
    },
    {
      id: 'uploadedBy',
      // disablePadding: true,
      label: 'Uploaded By',
      accessorKey: 'uploadedBy'
      // sort: true,
      // width: '15%'
    },
    {
      id: 'status',
      // disablePadding: true,
      label: 'File Status',
      accessorKey: 'status'
      // sort: true,
      // width: '15%'
    },
    {
      id: 'date',
      // disablePadding: true,
      label: 'Created On',
      accessorKey: 'date'
      // sort: true,
      // width: '15%'
    },
    {
      id: 'moreOptions',
      // disablePadding: true,
      label: '',
      accessorKey: 'moreOptions',
      // sort: false
      style: {
        width: '20px'
      }
    }
  ]

  useEffect(() => {
    if (!rfxDocumentsList) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [rfxDocumentsList])

  useEffect(() => {
    if (currentView === 'myFiles') {
      if (_.isArray(rfxDocumentsList) && rfxDocumentsList.length > 0) {
        const data = []
        let statusOptions = []
        rfxDocumentsList &&
          rfxDocumentsList.forEach((rfx, indexRfxList) => {
            const el = {}
            el.documentName = rfx?.document_name
            const rfxStatus =
              rfx?.status.charAt(0).toUpperCase() + rfx?.status.slice(1)
            el.uploadedBy = rfx?.uploaded_by
            el.status = rfxStatus
            /* el.analyticsPdfLink = analytic?.processed_pdf_location ? el.documentName : "N/A" */
            const date = new Date(rfx?.created_at + 'Z')
            el.date = moment(date?.toString()).format('ll')
            el.index = indexRfxList
            el.id = rfx?.id
            el.moreOptions = renderTableActions(el, indexRfxList)
            el.fileIndex = rfx?.id
            statusOptions.push(el.status)
            if (rfx?.uploaded_by === currentUserName) {
              data.push(el)
            }
          })
        data?.sort((a, b) => {
          return new Date(b.date) - new Date(a.date)
        })
        setFilteredRows(data)
        setActiveRows(data.slice((page - 1) * pageSize, page * pageSize) || [])
        setRows(data)
        statusOptions = _.uniq(_.compact(statusOptions))
        const filterData = {
          status: {
            id: 'status',
            label: 'Status',
            data: statusOptions
          }
        }

        const newFilterData = {
          keyOptions: [
            { label: 'Status', value: 'Status' },
            { label: 'Created On', value: 'Created On' }
          ],
          valueOptions: {
            status: statusOptions
          }
        }
        setAdvancedFilter(newFilterData)
        setFilterData(filterData)
      }
    } else {
      if (_.isArray(rfxDocumentsList) && rfxDocumentsList.length > 0) {
        const data = []
        let statusOptions = []
        let uploadedByOptions = []
        rfxDocumentsList &&
          rfxDocumentsList.forEach((rfx, indexRfxList) => {
            const el = {}
            el.documentName = rfx?.document_name
            const rfxStatus =
              rfx?.status.charAt(0).toUpperCase() + rfx?.status.slice(1)
            el.uploadedBy = rfx?.uploaded_by
            el.status = rfxStatus
            /* el.analyticsPdfLink = analytic?.processed_pdf_location ? el.documentName : "N/A" */
            const date = new Date(rfx?.created_at + 'Z')
            el.date = moment(date?.toString()).format('ll')
            el.index = indexRfxList
            el.fileIndex = rfx?.id
            el.id = rfx?.id
            el.moreOptions = renderTableActions(el, indexRfxList)
            statusOptions.push(el.status)
            uploadedByOptions.push(el.uploadedBy)
            data.push(el)
          })
        data?.sort((a, b) => {
          return new Date(b.date) - new Date(a.date)
        })
        setFilteredRows(data)
        setActiveRows(data.slice((page - 1) * pageSize, page * pageSize) || [])
        setRows(data)
        statusOptions = _.uniq(_.compact(statusOptions))
        uploadedByOptions = _.uniq(_.compact(uploadedByOptions))
        const filterData = {
          status: {
            id: 'status',
            label: 'Status',
            data: statusOptions
          },
          uploadedBy: {
            id: 'uploadedBy',
            label: 'Uploaded By',
            data: uploadedByOptions
          }
        }

        const newFilterData = {
          keyOptions: [
            { label: 'Status', value: 'Status' },
            { label: 'Created On', value: 'Created On' },
            { label: 'Uploaded By', value: 'Uploaded By' }
          ],
          valueOptions: {
            status: statusOptions,
            uploadedBy: uploadedByOptions
          }
        }
        setAdvancedFilter(newFilterData)
        setFilterData(filterData)
      }
    }
  }, [rfxDocumentsList, currentView])

  useEffect(() => {
    if (!_.isEmpty(PDFData) && PDFData?.showDrawer) {
      setShowDrawer(true)
    }
  }, [PDFData])

  /* useEffect(() => {
    filter()
  }, [dateRange, selectedFilter]); */

  useEffect(() => {
    filter()
  }, [rows])

  useDidMountEffect(() => {
    if (selectedFilterData.length === 0) {
      handleSearch(searchValue)
    }
  }, [selectedFilterData])

  const refresh = () => {
    dispatch(fetchRFxDocuments())
  }

  const filter = (isReturn) => {
    const filters = selectedFilter
    const newKeyValueFilter = {}
    if (!Object.keys(filters).includes('keyvalue')) {
      setkeyValueFilter({})
    }
    let newRows = rows
    // Column Filter
    if (!_.isEmpty(filters)) {
      const filterKeys = Object.keys(filters)
      const filtered = []
      _.forEach(rows, (obj) => {
        let f = true
        filterKeys.forEach((key) => {
          if (key === 'keyvalue') {
            _.forEach(filters[key], (kv) => {
              if (!Object.keys(keyValueFilter).includes(kv)) {
                newKeyValueFilter[kv] = ''
              } else {
                newKeyValueFilter[kv] = keyValueFilter[kv]
              }
            })
          } else if (!filters[key].includes(obj[key])) {
            f = false
          }
        })
        if (f) filtered.push(obj)
      })
      newRows = filtered
    }
    // Date filter
    const from = dateRange[0]
    const to = dateRange[1]
    if (from && to) {
      newRows = _.filter(newRows, function (o) {
        return moment(o?.date).isBetween(from, to, 'day', '[]')
      })
    }
    if (!isReturn) {
      newRows = filterKeyValue(newKeyValueFilter, newRows)
      // setPage(0)
      setFilteredRows(newRows)
      if (newRows.length < (page - 1) * pageSize) {
        setPage(1)
        setActiveRows(newRows.slice(0, pageSize))
      } else {
        const pageNo = toInteger(localStorage.getItem('rfxPage')) || 1
        setPage(pageNo)
        setActiveRows(newRows.slice((pageNo - 1) * pageSize, pageNo * pageSize))
      }
    } else {
      return newRows
    }
  }

  const filterKeyValue = (currentkeyValueFilter, rows) => {
    let update = false
    if (!rows) {
      rows = filter(true)
      update = true
    }
    let newRows = rows
    if (!_.isEmpty(currentkeyValueFilter)) {
      newRows = []
      rows.forEach((row) => {
        const tags = row?.tags
        if (!_.isEmpty(tags)) {
          let flag = true
          Object.keys(currentkeyValueFilter).forEach((key) => {
            if (currentkeyValueFilter[key] === '') {
              if (!Object.keys(tags).includes(key)) {
                flag = false
              }
            } else {
              flag = tags[key]
                ?.toLowerCase()
                .includes(currentkeyValueFilter[key].toLowerCase())
            }
          })
          if (flag) {
            newRows.push(row)
          }
        }
      })
    }
    setkeyValueFilter(currentkeyValueFilter)
    if (update) {
      setPage(1)
      setFilteredRows(newRows)
      setActiveRows(newRows.slice(0, pageSize))
    }
    return newRows
  }

  const removeKeyValueFilter = (key) => {
    const newKeyValue = _.cloneDeep(keyValueFilter)
    delete newKeyValue[key]
    setkeyValueFilter(newKeyValue)
    const newSelectedFilter = _.cloneDeep(selectedFilter)
    if (newSelectedFilter.keyvalue?.includes(key)) {
      newSelectedFilter.keyvalue = _.remove(
        newSelectedFilter.keyvalue,
        function (n) {
          return n !== key
        }
      )
    }
    if (_.isEmpty(newSelectedFilter.keyvalue)) {
      delete newSelectedFilter.keyvalue
    }
    setSelectedFilter(newSelectedFilter)
  }

  const handleSearch = (key) => {
    setSearchValue(key)
    setPage(1)
    const searchKey = key.toLowerCase()
    let searchData = filteredRows
    if (searchKey === '' && selectedFilterData.length === 0) {
      setFilteredRows(rows)
      setActiveRows(rows.slice(0, pageSize))
      return
    } else if (searchKey === '' && selectedFilterData.length > 0) {
      handleFilter(selectedFilterData)
      return
    } else if (searchKey !== '' && selectedFilterData.length === 0) {
      searchData = rows
    }
    const filteredData = searchData.filter(
      (data) =>
        `${data?.client}`.toString().toLowerCase().includes(searchKey) ||
        `${data?.createdBy}`.toString().toLowerCase().includes(searchKey) ||
        `${data?.date}`.toString().toLowerCase().includes(searchKey) ||
        `${data?.documentName}`.toString().toLowerCase().includes(searchKey) ||
        `${data?.marketSector}`.toString().toLowerCase().includes(searchKey)
    )
    setFilteredRows(filteredData)
    setActiveRows(filteredData.slice(0, pageSize))
  }

  const handleFilter = (filterData = []) => {
    const newFilteredRecords = []
    setSelectedFilterData(filterData)
    rows.forEach((rfx, index) => {
      let tempRow = rfx
      filterData.forEach((data, filterIndex) => {
        let { key, value = [] } = data
        if (_.camelCase(key) === 'createdOn') {
          key = 'date'
        } else {
          key = _.camelCase(key)
        }
        if (key !== 'date') {
          if (!(value.length === 0 || value.includes(tempRow[key]))) {
            tempRow = ''
          }
        } else {
          const from = value[0]
          const to = value[1]
          if (from && to) {
            if (!moment(tempRow[key]).isBetween(from, to, 'day', '[]')) {
              tempRow = ''
            }
          }
        }
      })
      if (tempRow !== '') {
        newFilteredRecords.push(tempRow)
      }
    })
    setFilteredRows(newFilteredRecords)
    setActiveRows(newFilteredRecords.slice(0, pageSize))
  }

  const resetFilter = () => {
    setFilteredRows(rows)
    setActiveRows(rows.slice(0, pageSize))
  }

  const menuOptions = [
    {
      label: 'Remove',
      onClick: (row) => {
        const rfxData = rfxDocumentsList?.filter(
          (rfx) => rfx.id === row?.fileIndex
        )[0]

        showConfirmDialog({
          onConfirm: async () => {
            dispatch(deleteRfx([rfxData.id]))
          },
          confirmationMessageTitle: `Are you sure you want to delete "${rfxData.document_name}" ?`
        })
      },
      disabled: isUserViewer
    },
    {
      label: 'Download',
      onClick: (row) => {
        trackEvent(
          mixpanelEvents.RFX_FILE_DOWNLOAD,
          'SUCCESS',
          {},
          { message: 'RFX file downloaded' }
        )
        const rfxData = rfxDocumentsList?.filter(
          (rfx) => rfx.id === row?.fileIndex
        )[0]
        const rfx = rfxData ?? {}
        const originalUrl = rfx?.original_file_location
          ? rfx?.original_file_location
          : rfx.location
        const fileType = rfx?.file_extension
        const fileName = rfx.document_name.includes(`.${fileType}`)
          ? rfx.document_name
          : `${rfx.document_name}.${fileType}`
        initalizeDownload(originalUrl, fileName)
      }
    }
  ]

  const handleChangeView = (view) => {
    setCurrentView(view)
  }

  const handleChangePage = (newPage) => {
    const filteredRowsClone = _.cloneDeep(filteredRows)
    const newRows = filteredRowsClone.slice(
      (newPage - 1) * pageSize,
      newPage * pageSize
    )
    localStorage.setItem('rfxPage', newPage)
    setActiveRows(newRows)
    setPage(newPage)
  }

  const handleMultiDownload = async () => {
    const selectedRowsIds = selectedActiveRows
    const selectedRows = rows.filter((row) =>
      selectedRowsIds.includes(row.fileIndex)
    )
    const downloadLinks = selectedRows.map((row) => {
      const rfxData = rfxDocumentsList?.filter(
        (rfx) => rfx.id === row.fileIndex
      )[0]
      const rfx = rfxData ?? {}
      const originalUrl = rfx?.original_file_location
        ? rfx?.original_file_location
        : rfx.location
      let fileName
      try {
        const fileType = originalUrl.split('.').pop()
        fileName = rfx.document_name.includes(`.${fileType}`)
          ? rfx.document_name
          : `${rfx.document_name}.${fileType}`
      } catch (error) {
        fileName = rfx.document_name
        console.log('error - ', error)
      }

      return {
        url: originalUrl,
        fileName
      }
    })

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

    for (const link of downloadLinks) {
      await initalizeDownload(link.url, link.fileName)
      await delay(1000 + Math.random() * 500)
    }
  }

  const handleMultiDelete = () => {
    const selectedRowsIds = selectedActiveRows
    const selectedRows = rows.filter((row) =>
      selectedRowsIds.includes(row.fileIndex)
    )
    const selectedRowsNames = selectedRows.map((row) => row.documentName)
    showConfirmDialog({
      onConfirm: async () => {
        dispatch(deleteRfx(selectedRowsIds))
      },
      confirmationMessageTitle: `Are you sure you want to delete ${selectedRowsIds.length} RFx documents?`
    })
  }

  if (loading) {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        className={grid}
      >
        <Loader loading={loading} caption={'fetching your RFx document'} />
      </Grid>
    )
  }

  const handleRFXUpload = () => {
    dispatch(
      setUploader({
        uploaderType: 'rfx',
        showUploader: true,
        fullScreen: true
      })
    )
  }

  return (
    <Container>
      <SectionFixed>
        <div
          className="m-2 p-6 bg-grey-800 rounded-lg flex items-center justify-between bg-pattern"
          style={{}}
        >
          <div className="">
            <p className="m-0 text-2xl noto-sans-600 text-white flex items-center gap-3">
              <MagicStar className="size-6" />
              Unlock your RFQ insights
            </p>
            <div
              className="flex items-center gap-2 text-grey-200 text-xs mt-2 mb-2 ml-4"
              style={{
                marginLeft: '2.4rem'
              }}
            >
              <span className="">Real-time chat</span>
              <div className="size-1 bg-grey-100 rounded-full"></div>
              <span className="">Smart Summary</span>
              <div className="size-1 bg-grey-100 rounded-full"></div>
              <span className="">Compliance checks</span>
            </div>
          </div>
          <div>
            <Button2
              secondary
              noOutline
              style={{
                background: 'var(--grey-100)'
              }}
              onClick={handleRFXUpload}
            >
              Get started
            </Button2>
          </div>
        </div>
        <div className="flex items-center justify-end gap-2 px-4 p-2 border-b-1">
          <div className="flex items-center gap-2 mr-auto">
            <Button2
              secondary
              style={{
                padding: '4px 8px',
                fontSize: '10px',
                borderRadius: '6px',
                border:
                  currentView === 'others'
                    ? '1px solid var(--grey-400) !important'
                    : '1px solid var(--grey-200) !important',
                color:
                  currentView === 'others'
                    ? 'var(--grey-700)'
                    : 'var(--grey-600)',
                backgroundColor:
                  currentView === 'others' ? 'var(--grey-200)' : 'transparent'
              }}
              onClick={() => {
                setCurrentView('others')
              }}
            >
              All Files
            </Button2>
            <Button2
              secondary
              style={{
                padding: '4px 8px',
                fontSize: '10px',
                borderRadius: '6px',
                border:
                  currentView === 'myFiles'
                    ? '1px solid var(--grey-400) !important'
                    : '1px solid var(--grey-200) !important',
                color:
                  currentView === 'myFiles'
                    ? 'var(--grey-700)'
                    : 'var(--grey-600)',
                backgroundColor:
                  currentView === 'myFiles' ? 'var(--grey-200)' : 'transparent'
              }}
              onClick={() => {
                setCurrentView('myFiles')
              }}
            >
              My Uploads
            </Button2>
          </div>

          {selectedActiveRows.length > 0 ? (
            <>
              <Button2
                secondary
                onClick={() => handleMultiDelete()}
                disabled={isUserViewer}
              >
                <DeleteIcon className="size-4" />
                Delete
              </Button2>
              <Button2 secondary onClick={() => handleMultiDownload()}>
                <DownloadIcon className="size-4" />
                Download
              </Button2>
            </>
          ) : (
            <Button2 secondary onClick={() => refresh()}>
              <RefreshIcon className="size-4" />
            </Button2>
          )}

          <ListFilter
            // refresh={refresh}
            filterData={filterData}
            handleSearch={handleSearch}
            filter={filter}
            searchValue={searchValue}
            searchOnEnter={() => {}}
            keyValueFilter={keyValueFilter}
            filterKeyValue={filterKeyValue}
            removeKeyValueFilter={removeKeyValueFilter}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            dateRange={dateRange}
            setDateRange={setDateRange}
            pageType="rfx"
            advancedFilter={advancedFilter}
            handleFilter={handleFilter}
            resetFilter={resetFilter}
            handleChangeView={handleChangeView}
            currentView={currentView}
            isEditable={false}
            hideSearch={true}
          />

          <SearchNew
            value={searchValue}
            onChange={(value) => setSearchValue(value)}
            onEnter={(value) => {
              handleSearch(value)
            }}
            onClear={() => {
              setSearchValue('')
              handleSearch('')
            }}
            style={{
              padding: '6px 10px'
            }}
          />

          <Button2
            primary
            onClick={handleRFXUpload}
            style={{
              padding: '6px 10px'
            }}
          >
            <CloudUpload className="size-4" />
            Upload
          </Button2>
        </div>
      </SectionFixed>
      <div className={tableContainer}>
        <HeadlessTable
          leftAlign={true}
          columns={headCells}
          data={activeRows}
          // meta={meta}
          meta={{
            pageSize,
            totalCount: filteredRows?.length,
            page
          }}
          loading={loading}
          selectedData={selectedActiveRows}
          handleSelectedData={({ id }) => {
            if (selectedActiveRows.includes(id)) {
              setSelectedActiveRows(
                selectedActiveRows.filter((item) => item !== id)
              )
            } else {
              setSelectedActiveRows([...selectedActiveRows, id])
            }
          }}
          pageLoading={false}
          onRowClick={handleonClickFile}
          fetchPage={(page) => {
            handleChangePage(page)
          }}
          showOptions={true}
        />
      </div>
      {ConfirmDialog}
    </Container>
  )
}

export default rfx
