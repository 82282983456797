import { useState, useEffect } from 'react'
import LibraryList from '../../../containers/LibraryList'
import { Container, Section } from '../../../components/Container'
import { useStyles } from './styles'
import { useNavigate, useLocation } from 'react-router-dom'
import Dialog from '@mui/material/Dialog'
import Box from '@mui/material/Box'
import LibraryEditor from '../../../containers/LibraryEditor'
import Modal from '@mui/material/Modal'
import { useDispatch } from 'react-redux'
import { clearLibraryDocument } from '../../../store/Library/Actions'
import useConfirmation from '../../../components/ConfirmDialog'
import { ROUTES } from '../../../config/routes'
import { Slide } from '@mui/material'

const LibraryHome = (props) => {
  const classes = useStyles()
  const { outerContainer, libraryContainer } = classes
  const [showModel, setShowModel] = useState(false)
  const { libraryId } = props
  const [documentController, setDocumentController] = useState(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [unSavedChanges, setUnSavedChanges] = useState(false)
  const { ConfirmDialog, showConfirmDialog } = useConfirmation()
  const location = useLocation()
  const { LIBRARY_KNOWLEDGE } = ROUTES
  const isFocused = location.pathname.includes(LIBRARY_KNOWLEDGE)

  useEffect(() => {
    if (libraryId) {
      setShowModel(true)
      setUnSavedChanges(false)
    } else {
      setShowModel(false)
      if (documentController) {
        documentController.abort()
        setDocumentController(null)
      }
    }
  }, [libraryId])

  const handleClose = (event, reason) => {
    if (unSavedChanges) {
      showConfirmDialog({
        onConfirm: () => {
          closeModel()
        },
        confirmationMessageTitle: 'Are you sure you want to leave this page?',
        confirmationMessage: 'All unsaved changes will be lost.'
      })
    } else {
      closeModel()
    }
  }

  const closeModel = () => {
    setShowModel(false)
    navigate(LIBRARY_KNOWLEDGE, { replace: true })
    dispatch(clearLibraryDocument())
  }

  return (
    <Container>
      <Section className={outerContainer} overFlow>
        <LibraryList isFocused={isFocused} {...props} />
      </Section>
      <Dialog
        fullScreen
        className={libraryContainer}
        open={showModel}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none'
          }
        }}
      >
        <LibraryEditor
          onClose={handleClose}
          libraryId={libraryId}
          unSavedChanges={unSavedChanges}
          setUnSavedChanges={setUnSavedChanges}
          setDocumentController={setDocumentController}
          documentController={documentController}
        />
      </Dialog>
      {ConfirmDialog}
    </Container>
  )
}

export default LibraryHome
