import React, { useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  Divider,
  IconButton,
  Menu,
  MenuItem
} from '@mui/material'
import { useStyles } from './styles'
import ToolTipButton from './TooltipButton'
import { ReactComponent as ExpandDownIcon } from '../../assets/svg/ExpandDown.svg'
import { ReactComponent as ViewAllIcon } from '../../assets/svg/ViewAll.svg'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import clsx from 'clsx'
import { toast } from 'react-toastify'
import Button2 from '../Button/Button2'

const HideColumn = (props) => {
  const {
    columns = [],
    setColumns = () => {},
    visibleColumns = [],
    hiddenColumns = [],
    setHiddenColumns = () => {},
    disabled = false,
    defaultHidden = [],
    viewMode = 'table',
    formatHeader = (e) => {
      return e
    }
  } = props

  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [dragActive, setDragActive] = useState(false)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    if (columns.length === 0) return
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleColumnClick = (column) => () => {
    setHiddenColumns((prev) => {
      if (prev.includes(column)) {
        return prev.filter((item) => item !== column)
      } else {
        if (visibleColumns.length === 1) {
          toast.error('Atleast one column should be visible')
          return prev
        }
        return [...prev, column]
      }
    })
  }

  const handleDragEnter = (key) => {
    setDragActive(key)
  }

  const handleDragEnd = () => {
    setDragActive('')
  }
  const handleDragStart = (event, index) => {
    event.dataTransfer.setData('text/plain', index)
  }

  const handleDragOver = (event) => {
    event.preventDefault()
  }
  const handleDrop = (event, targetIndex) => {
    const draggedIndex = parseInt(event.dataTransfer.getData('text/plain'), 10)
    const newColumns = [...columns]
    const [draggedItem] = newColumns.splice(draggedIndex, 1)
    newColumns.splice(targetIndex, 0, draggedItem)
    setColumns(newColumns)
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Button2
        secondary
        color="neutral"
        onClick={handleClick}
        disabled={disabled || columns?.length === 0}
      >
        <ToolTipButton title={'Columns'} onBreak={<ViewAllIcon />}>
          <Box className="flex items-center gap-1">
            {<ViewAllIcon />}
            <Box className={classes.buttonText}>{'Columns'}</Box>
            <ExpandDownIcon sx={{ fontSize: '18px' }} />
          </Box>
        </ToolTipButton>
      </Button2>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        elevation={1}
        className={classes.hideMenu}
      >
        {viewMode === 'table' &&
          columns.map(
            (column, index) =>
              !defaultHidden.includes(column) && (
                <Box className={classes.columnMenu} key={index}>
                  <MenuItem
                    onClick={handleColumnClick(column)}
                    draggable
                    onDragStart={(event) => handleDragStart(event, index)}
                    onDragOver={handleDragOver}
                    onDragEnd={() => handleDragEnd()}
                    onDrop={(event) => handleDrop(event, index)}
                    onDragEnter={() => handleDragEnter(index)}
                    sx={{
                      borderTop: dragActive === index ? '1px solid red' : ''
                    }}
                  >
                    <Checkbox
                      color="neutral"
                      checked={!hiddenColumns.includes(column)}
                      size="small"
                    />
                    <Box className={classes.hideMenuListText}>
                      {formatHeader(column)}
                    </Box>
                    <IconButton disableRipple sx={{ cursor: 'grab' }}>
                      <DragHandleIcon />
                    </IconButton>
                  </MenuItem>
                  {column.length > 1 && (
                    <Divider className={classes.hideMenuDivider} />
                  )}
                </Box>
              )
          )}
        {viewMode === 'table' && (
          <Divider
            className={clsx(classes.hideMenuDivider, classes.blackDivider)}
          />
        )}
      </Menu>
    </Box>
  )
}

export default HideColumn
