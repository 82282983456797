import React, { useEffect, useState, useMemo, useRef } from 'react'
import { Grid } from '@material-ui/core'
import {
  useNavigate,
  createSearchParams,
  useLocation,
  useSearchParams,
  useParams
} from 'react-router-dom'
import ListTable from '../../components/ListTable'
import ListFilter from '../../sections/ListFilter'
import _, { isArray, cloneDeep } from 'lodash'
import { Container, Section, SectionFixed } from '../../components/Container'
import Loader from '../../components/Loader'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { ROUTES } from '../../config/routes'
import { useStyles } from './styles'
import EmptyRecord from '../../sections/EmptyRecords'
import { checkUserRoleSuperUser } from '../../utils/User'
import ClientResult from '../../sections/ClientResult'
import { initalizeS3 } from '../../utils/AWS'
import {
  mergeClients,
  getIndividualClient,
  getClearBitData,
  multiDocuments
} from '../../store/api'
import { toast } from 'react-toastify'
import CircularProgress from '@mui/material/CircularProgress'
import { setToasterAlert } from '../../store/Common/Actions'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'
import InfiniteScroll from 'react-infinite-scroll-component'
import fetchClientsList from '../../store/Clients/Actions'
import HeadlessTable from '../../components/TableV2/HeadlessTable'
import SearchNew from '../../sections/ListFilter/SearchNew'
import { ImageAvatarFallback } from '../../components'
import DisplayTags from '../../sections/ResumeResult/DisplayTags'
import { Box, Drawer } from '@mui/material'
import TagCenterUpdate from '../../sections/TagCenterUpdate'
import Button2 from '../../components/Button/Button2'
import { MergeIcon } from '../../components/Icons/Icons'
import Label from '@mui/icons-material/Label'
import { updateNewTagValue } from '../../store/TagCenter/Actions'
import AlphabetSearch from '../PeopleResume/AlphabetSearch'

const ClientList = (props) => {
  const { showSearch = false, isEditable = true, isSuperUser = false } = props
  const { LIBRARY_CLIENTS_RESUME } = ROUTES
  const isFocused = location.pathname.includes(LIBRARY_CLIENTS_RESUME)

  const clientsList = useSelector((state) => state?.clients?.clientsList)
  const hasMoreData = useSelector((state) => state?.clients?.hasMoreData)
  const [alphabetKey, setAlphabetKey] = useState(null)
  const { clientId } = useParams()
  const user = useSelector((state) => state.authenticate.user)
  const [loading, setLoading] = useState(true)
  const [rows, setRows] = useState([])
  const [meta, setMeta] = useState({})
  const [page, setPage] = useState(null)
  const [PDFData, setPDFData] = useState({})
  const dispatch = useDispatch()
  const [showDrawer, setShowDrawer] = useState(false)
  const [showTagDrawer, setShowTagDrawer] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [filteredRecord, setFilteredRecord] = useState([])
  const navigate = useNavigate()
  const classes = useStyles({ showDrawer })
  const { grid, tableContainer } = classes
  const [currentClientData, setCurrentClientData] = useState({})
  const [topVariatonList, setTopVariatonList] = useState([])
  const [topProposalScreenshot, setTopProposalScreenshot] = useState([])
  const [topProposalScreenshotOrigin, setTopProposalScreenshotOrigin] =
    useState([])
  const [openClient, setOpenClient] = useState(false)
  const [currentClient, setCurrentClient] = useState(null)
  const [screenshotIndex, setScreenshotIndex] = useState(0)
  const [s3Obj, sets3Obj] = useState(null)
  const [originProposalNames, setOriginProposalNames] = useState([])
  const [isMerge, setIsMerge] = useState(false)
  const [selectedData, setSelectedData] = useState([])
  const [selectedRowData, setSelectedRowData] = useState([])
  const [isSearchReloadRequired, setIsSearchReloadRequired] = useState(false)
  const [singleData, setSingleData] = useState(null)

  const handleCloseClient = () => {
    setOpenClient(false)
    setLoading(true)
    navigate(ROUTES.LIBRARY_CLIENTS_RESUME)
  }

  useEffect(() => {
    async function initalizeData() {
      const s3 = await initalizeS3()
      sets3Obj(s3)
    }
    handleSearch('')
    initalizeData()
  }, [])

  useEffect(() => {
    const savedPage = localStorage.getItem('clientsPage')
    if (savedPage) {
      const pageNo = parseInt(savedPage)
      if (isNaN(pageNo)) {
        setPage(1)
      } else {
        setPage(pageNo)
      }
    } else {
      setPage(1)
      localStorage.setItem('clientsPage', '1')
    }
  }, [])

  useEffect(() => {
    if (page && !clientId) {
      fetchMoreData(page)
      localStorage.setItem('clientsPage', page)
    }
  }, [page])

  useEffect(() => {
    if (page === 1) {
      fetchMoreData(page)
    } else {
      setPage(1)
    }
  }, [alphabetKey])

  useEffect(() => {
    if (isFocused) {
      if (!clientsList && showSearch) {
        setLoading(true)
        // fetchMoreData(1)
      }
    }
  }, [clientsList, isFocused, hasMoreData, clientId, singleData])

  useEffect(() => {
    if (clientId) {
      fetchSingleData()
      setOpenClient(true)
    }
  }, [clientId])

  const fetchSingleData = async () => {
    setLoading(true)
    const res = await getIndividualClient({ client_id: clientId })
    if (res.status === 200) {
      const result = res.data
      let client = result.clients

      const { domain_id, proposal_id } = client
      const thumbnail_url = `https://${process.env.REACT_APP_IMAGE_BUCKET}.s3.amazonaws.com/snapshots/${domain_id}/${proposal_id}/original/1.png`

      client = {
        ...client,
        thumbnail_url,
        projects: client?.data?.map((project) => {
          const { proposal_id: project_proposal_id } = project
          const thumbnail_url = `https://${process.env.REACT_APP_IMAGE_BUCKET}.s3.amazonaws.com/snapshots/${domain_id}/${project_proposal_id}/original/1.png`
          return {
            ...project,
            thumbnail_url
          }
        })
      }
      setLoading(false)
      setCurrentClient(client)
      setSingleData(client)
      // if (clientsList?.length > 0) {

      // } else {
      //   toast.error('Unable to fetch clients')
      // }
    } else {
      toast.error('Unable to fetch clients')
    }
  }

  const fetchMoreData = (page) => {
    // setPage(page)
    setLoading(true)
    dispatch(
      fetchClientsList(
        {
          page_num: page,
          page_size: 20,
          keyword: '',
          alphabetical: alphabetKey
        },
        'fetch',
        (data) => {
          console.log('client data', data)
          setRows(data?.clients)
          setLoading(false)
          setMeta({
            pageSize: data?.page_size,
            totalCount: data?.total_count,
            page: data?.page_num
          })
        }
      )
    )
  }

  const navigateOpenClient = (i, row) => {
    const clientId = row?.id
    navigate('/library/clients/' + clientId)
  }

  const columns = [
    {
      id: 'title',
      label: 'Client Name',
      accessorKey: 'title',
      children: (row) => {
        return (
          <span className="flex items-center gap-2 text-xs text-grey-800">
            <ImageAvatarFallback
              name={row?.title}
              profilePic={row?.enrichment_data?.logo}
              style={{
                fontSize: '8px',
                background: 'var(--grey-700)',
                height: '26px',
                width: '26px'
              }}
              openImage={() => {}}
              s3Obj={s3Obj}
            />
            {row?.title}
          </span>
        )
      },
      style: {
        fontFamily: 'PoppinsMedium',
        fontSize: '14px'
      }
    },
    {
      id: 'tags',
      label: 'Tags',
      children: (row) => {
        return (
          <div
            className="w-full flex items-center text-xs"
            onClick={(e) => {
              e.stopPropagation()
              setSelectedData([row.id])
            }}
          >
            <DisplayTags
              tags={row?.tags ?? []}
              setShowTagDrawer={() => setShowTagDrawer(row.id)}
            />
          </div>
        )
      }
    },
    {
      id: 'domain',
      label: 'Domain',
      accessorKey: 'domain'
    },
    {
      id: 'projectName',
      label: 'Project',
      accessorKey: 'title'
    },
    {
      id: 'documentLocation',
      label: 'Location',
      accessorKey: 'location',
      style: {
        fontStyle: 'italic',
        fontSize: '10px'
      }
    }
  ]

  const setInitialData = () => {
    const data = []
    clientsList &&
      clientsList.forEach((client) => {
        const el = {}
        el.id = client?.id
        el.documentName = client?.title
        el.projectName = client?.data?.project_name
        el.location = client?.data?.client_location
        el.logo = client?.enrichment_data?.logo
        el.domain = client?.enrichment_data?.domain
        data.push(el)
      })

    // setRows(data)
  }

  useEffect(() => {
    if (isArray(clientsList) && clientsList.length > 0) {
      setInitialData()
      setLoading(false)
      setFilteredRecord(clientsList)
    }
  }, [clientsList])

  useEffect(() => {
    if (!_.isEmpty(PDFData) && PDFData?.showDrawer) {
      setShowDrawer(true)
    }
  }, [PDFData])

  // const OpenClient = (e, i, row, list = clientsList) => {
  //   if (!list) {
  //     return
  //   }
  //   const clientId = row?.id
  //   const filteredJson = list?.filter((item) => item?.id === clientId)
  //   const thumbnail_url = filteredJson[0]?.thumbnail_url
  //   const thumbnail_url_list = filteredJson[0]?.variation_list?.map(
  //     (item) => item?.thumbnail_url
  //   )
  //   setCurrentClient(filteredJson[0])
  //   setTopProposalScreenshot([thumbnail_url, ...thumbnail_url_list])
  //   const thumbnail_url_origin =
  //     filteredJson[0]?.page_number === 1
  //       ? filteredJson[0]?.proposal_url.includes('rfx')
  //         ? 'rfx'
  //         : 'proposal'
  //       : 'resume'
  //   const thumbnail_url_list_origin = filteredJson[0]?.variation_list?.map(
  //     (item) =>
  //       item?.page_number === 1
  //         ? item?.proposal_url.includes('rfx')
  //           ? 'rfx'
  //           : 'proposal'
  //         : 'resume'
  //   )
  //   setTopProposalScreenshotOrigin([
  //     thumbnail_url_origin,
  //     ...thumbnail_url_list_origin
  //   ])
  //   setOriginProposalNames([
  //     filteredJson[0]?.proposal_name,
  //     ...filteredJson[0]?.variation_list?.map((item) => item?.proposal_name)
  //   ])
  //   setCurrentClientData({
  //     ...filteredJson[0]?.data,
  //     proposal_name: filteredJson[0]?.proposal_name,
  //     client_group_id: filteredJson[0]?.id,
  //     client_id: filteredJson[0]?.client_id
  //   })
  //   setTopVariatonList(filteredJson[0]?.variation_list)
  //   setScreenshotIndex(0)
  // }

  // const handleVariationIndexChange = (index) => {
  //   if (index >= 0) {
  //     setScreenshotIndex(index + 1)
  //     // setCurrentClientData(topVariatonList?.[index]?.data);
  //     setCurrentClientData({
  //       ...topVariatonList?.[index]?.data,
  //       proposal_name: topVariatonList?.[index]?.proposal_name
  //     })
  //     // setTopVariatonList([]);
  //   } else {
  //     setScreenshotIndex(0)
  //     // setCurrentClientData(currentClient?.data);
  //     setCurrentClientData({
  //       ...currentClient?.data,
  //       proposal_name: currentClient?.proposal_name
  //     })
  //     setTopVariatonList(currentClient?.variation_list)
  //   }
  // }

  const handleMultiTags = async (totalTags) => {
    if (selectedData.length === 0) {
      toast.error('Please select atleast one project to tag')
    } else {
      const datetags = {}
      const tags = {}
      const dateArr = {}
      const tagsArr = {}
      for (const key in totalTags) {
        if (
          ['multidate', 'singledate'].includes(totalTags?.[key]?.[0]?.tag_type)
        ) {
          datetags[key] = totalTags[key]
        } else {
          tags[key] = totalTags[key]
        }
      }
      Object.keys(datetags).forEach((key) => {
        datetags[key].forEach((item) => {
          if (dateArr[key]) {
            dateArr[key].push({
              tag_key: item.key,
              tag_value: item.label,
              tag_type: item.tag_type
            })
          } else {
            dateArr[key] = [
              {
                tag_key: item.key,
                tag_value: item.label,
                tag_type: item.tag_type
              }
            ]
          }
        })
      })
      Object.keys(tags).forEach((key) => {
        tags[key].forEach((item) => {
          if (tagsArr[key]) {
            tagsArr[key].push({
              tag_key: item.key,
              tag_value: item.value,
              tag_label: item.label
            })
          } else {
            tagsArr[key] = [
              {
                tag_key: item.key,
                tag_value: item.value,
                tag_label: item.label
              }
            ]
          }
        })
      })
      for (const key in dateArr) {
        if (dateArr[key].length === 0) {
          delete dateArr[key]
        }
      }
      for (const key in tagsArr) {
        if (tagsArr[key].length === 0) {
          delete tagsArr[key]
        }
      }
      if (_.isEmpty(tagsArr) && _.isEmpty(dateArr)) {
        toast.error('Please select atleast one tag')
      } else {
        setShowTagDrawer(false)
        setIsMerge('loadingTag')
        const toastId = toast.info(
          <div style={{ display: 'flex' }}>
            {'Tagging Selected Projects'}&nbsp;
            <CircularProgress size={20} />
          </div>,
          {
            autoClose: false,
            closeOnClick: false,
            closeButton: false,
            draggable: false
          }
        )
        const documents = selectedData.map((item) => {
          return {
            id: item,
            file_type: 'project'
          }
        })
        const req = {
          documents,
          action: 'tags',
          data: { tags: tagsArr, date_tags: dateArr }
        }
        const res = await multiDocuments(req)
        toast.dismiss(toastId)
        if (res.status === 200) {
          const { new_tags } = res.data
          const newTagPayload = {}
          new_tags.forEach((item) => {
            if (!newTagPayload[item.key]) {
              newTagPayload[item.key] = []
            }
            newTagPayload[item.key].push(item)
          })
          Object.keys(newTagPayload || {}).forEach((key) => {
            dispatch(updateNewTagValue({ new_tags: newTagPayload[key], key }))
          })

          fetchMoreData(page)
          const tagsFormated = {}
          const keysToRemove = []
          Object.keys(tagsArr || {}).forEach((key) => {
            keysToRemove.push(key)
            tagsArr[key].forEach((item) => {
              if (tagsFormated[key]) {
                tagsFormated[key].push({
                  tag_key: key,
                  tag_value: item.tag_label,
                  id: item.tag_value
                })
              } else {
                tagsFormated[key] = [
                  {
                    tag_key: key,
                    tag_value: item.tag_label,
                    id: item.tag_value
                  }
                ]
              }
            })
          })
          Object.keys(datetags || {}).forEach((key) => {
            const values = datetags[key]
            keysToRemove.push(key)
            const { data = [] } = tagsCenterStateTags[key] || {}
            values.forEach((item) => {
              const tag = data.find((element) => {
                return element.value === item.label
              })
              if (tag) {
                if (tagsFormated[key]) {
                  tagsFormated[key].push({
                    tag_key: key,
                    tag_value: tag.value,
                    id: tag.id
                  })
                } else {
                  tagsFormated[key] = [
                    {
                      tag_key: key,
                      tag_value: tag.value,
                      id: tag.id
                    }
                  ]
                }
              } else {
                const newTag = new_tags.find((element) => {
                  return element.value === item.label
                })
                if (newTag) {
                  if (tagsFormated[key]) {
                    tagsFormated[key].push({
                      tag_key: key,
                      tag_value: newTag.value,
                      id: newTag.id
                    })
                  } else {
                    tagsFormated[key] = [
                      {
                        tag_key: key,
                        tag_value: newTag.value,
                        id: newTag.id
                      }
                    ]
                  }
                }
              }
            })
          })
          selectedData.forEach((id) => {
            const index = projectList.findIndex((item) => item.id === id)
            if (index === -1) return null
            const oldTags = projectList[index].tags
            const tagsDict = {}
            oldTags.forEach((id) => {
              const item = tagState[id]
              if (tagsDict[item.key]) {
                tagsDict[item.key].push(id)
              } else {
                tagsDict[item.key] = [id]
              }
            })
            Object.keys(tagsFormated).forEach((key) => {
              tagsDict[key] = tagsFormated[key].map((item) => item.id)
            })
            const newTags = []
            Object.keys(tagsDict).forEach((key) => {
              newTags.push(...tagsDict[key])
            })
            const payload = {
              id,
              tags: newTags
            }
            dispatch(updateProjectTag(payload))
          })
          setIsMerge(false)
          setSelectedData([])
          setSelectedRowData([])
        }
      }
    }
  }

  const handleSearch = (key) => {
    setSearchValue(key)
  }

  const handleSearchQuery = (event, value = null) => {
    if (event?.keyCode === 13) {
      trackEvent('CLIENT_SEARCHED', 'SUCCESS', {}, { search_value: value })
      setIsSearchReloadRequired(true)
      setLoading(true)
      setPage(1)
      dispatch(
        fetchClientsList(
          {
            page_num: 1,
            page_size: 20,
            keyword: value,
            alphabetical: alphabetKey
          },
          'fetch',
          (data) => {
            setRows(data?.clients)
            setLoading(false)
            setMeta({
              pageSize: data?.page_size,
              totalCount: data?.total_count,
              page: data?.page_num
            })
          }
        )
      )
    }
  }

  // const handlePDFViewer = async (index) => {
  //   const pageNoList = [
  //     currentClient?.page_number,
  //     ...currentClient?.variation_list?.map((item) => item?.page_number)
  //   ]
  //   const proposalIdList = [
  //     currentClient?.proposal_id,
  //     ...currentClient?.variation_list?.map((item) => item?.proposal_id)
  //   ]
  //   const newPath = `/view/pdf/${proposalIdList?.[index]}/1`
  //   window.open(newPath, '_blank')
  // }

  const handleMerge = async () => {
    if (isMerge === false) {
      setIsMerge(true)
    } else {
      if (selectedData.length > 1) {
        setIsMerge('loading')
        const toastId = toast.info(
          <div style={{ display: 'flex' }}>
            {'Merging Clients'}&nbsp;
            <CircularProgress size={20} />
          </div>,
          {
            autoClose: false,
            closeOnClick: false,
            closeButton: false,
            draggable: false
          }
        )
        const req = {
          collection_list: selectedData
        }
        const res = await mergeClients(req)
        if (res?.status === 200) {
          // trackEvent(mixpanelEvents["PROJECTS_MERGED"], "SUCCESS", {}, { project_name: selectedRowData?.map(item => item?.data?.project_name).join(", ") })
          setIsMerge(false)
          setSelectedData([])
          setSelectedRowData([])
          toast.dismiss(toastId)
          dispatch(
            setToasterAlert({
              message: 'Clients merged successfully!',
              show: true,
              type: 'success'
            })
          )
          setLoading(true)
          fetchMoreData(page)
        } else {
          // trackEvent(mixpanelEvents["PROJECTS_MERGED"], "FAILED", {}, { project_name: selectedRowData?.map(item => item?.data?.project_name).join(", ") })
          toast.dismiss(toastId)
          setIsMerge(false)
          setSelectedData([])
          setSelectedRowData([])
          dispatch(
            setToasterAlert({
              message: 'Error while merging clients...',
              show: true,
              type: 'error'
            })
          )
        }
      } else {
        dispatch(
          setToasterAlert({
            message: 'Please select atleast 2 clients to merge',
            show: true,
            type: 'error'
          })
        )
      }
    }
  }

  let extraButtons = []

  if (isSuperUser) {
    extraButtons = [
      {
        text: 'clear',
        condition: searchValue !== '',
        onClick: () => {
          setSearchValue('')
          if (isSearchReloadRequired) {
            setIsSearchReloadRequired(false)
            setLoading(true)
            setPage(1)
          }
        },
        icon: '',
        tooltip: 'clear'
      },
      // {
      //   text: `${
      //     isMerge
      //       ? isMerge === 'loading'
      //         ? 'Merging...'
      //         : 'Merge Selected Clients'
      //       : 'Merge'
      //   } `,
      //   condition: true,
      //   onClick: handleMerge,
      //   icon: '',
      //   tooltip: `${
      //     isMerge
      //       ? isMerge === 'loading'
      //         ? 'Merging...'
      //         : 'Merge Selected Clients'
      //       : 'Merge'
      //   } `
      // },
      {
        text: 'cancel',
        condition: isMerge === true,
        onClick: () => {
          setIsMerge(false)
          setSelectedData([])
          setSelectedRowData([])
        },
        icon: '',
        tooltip: 'cancel'
      }
    ]
  }

  const checkHorizontalScrollbar = (target) => {
    const element = document.getElementById(target)
    return element ? element.scrollHeight !== element.clientHeight : false
  }

  const renderTagDrawer = () => {
    return (
      <Drawer
        anchor={'right'}
        open={showTagDrawer}
        onClose={() => setShowTagDrawer(false)}
      >
        <Box sx={{ padding: '20px', width: '400px', height: '100%' }}>
          {selectedData.length === 1 ? (
            <TagCenterUpdate
              id={selectedData?.[0]}
              filterTags="clientgroup"
              document_type={'clientgroup'}
              onUpdateCallback={() => {
                // onUpdateCallback
                setShowTagDrawer(false)
                fetchMoreData(page)
                setSelectedData([])
              }}
              cancelCallback={() => setShowTagDrawer(false)}
            />
          ) : (
            <TagCenterUpdate
              // id={selectedPeople?.[0]}
              isOnlySelect={true}
              filterTags="clientgroup"
              // document_type="masterresume"
              selectCallback={(e) => handleMultiTags(e)}
              cancelCallback={() => setShowTagDrawer(false)}
            />
          )}
        </Box>
      </Drawer>
    )
  }

  // if (loading) {
  //   return <div className="h-screen grid place-content-center">Loading...</div>
  // }

  if (clientId && currentClient) {
    return (
      <ClientResult
        currentClient={currentClient}
        data={currentClientData}
        openProject={openClient}
        handleCloseProject={handleCloseClient}
        s3Obj={s3Obj}
        openType={'tab'}
        thumbnail_url_list={topProposalScreenshot}
        refresh={fetchSingleData}
        screenshotIndex={screenshotIndex}
        originProposalNames={originProposalNames}
        // handlePDFViewer={handlePDFViewer}
        topProposalScreenshotOrigin={topProposalScreenshotOrigin}
        topVariatonList={topVariatonList}
        setTopVariatonList={setTopVariatonList}
        singleData={singleData}
        setSingleData={setSingleData}
      />
    )
  }

  console.log('selectedData', selectedData)
  return (
    <Container>
      {showSearch && !singleData && (
        <SectionFixed>
          {showSearch && (
            <div
              className="flex justify-between items-center gap-2"
              style={{
                padding: '10px 18px'
              }}
            >
              <AlphabetSearch
                alphabetKey={alphabetKey}
                setAlphabetKey={setAlphabetKey}
                meta={meta}
              />

              {selectedData.length > 0 && (
                <>
                  {/* <Button2 secondary onClick={() => handleMerge()}>
                    <MergeIcon className="size-4 " />
                    Merge
                  </Button2> */}
                  <Button2 secondary onClick={() => setShowTagDrawer(true)}>
                    <Label className="size-4 " />
                    Tag Projects
                  </Button2>
                </>
              )}
              <div className="flex gap-2 items-center">
                <SearchNew
                  value={searchValue}
                  onChange={(value) => handleSearch(value)}
                  onClear={() => {
                    handleSearch('')
                    handleSearchQuery({ keyCode: 13 }, '')
                  }}
                  onEnter={(value) => {
                    handleSearch(value)
                    handleSearchQuery({ keyCode: 13 }, value)
                  }}
                />
              </div>
            </div>
          )}
        </SectionFixed>
      )}
      <Section overFlow>
        <div className={tableContainer}>
          {openClient ? (
            <></>
          ) : (
            <>
              <HeadlessTable
                leftAlign={true}
                columns={columns}
                meta={meta}
                data={rows}
                loading={loading}
                fetchPage={(page) => {
                  setPage(page)
                }}
                onRowClick={(row) => {
                  navigateOpenClient(null, row)
                }}
                selectedData={selectedData}
                handleSelectedData={(row) => {
                  console.log('selectedData', selectedData, row)
                  if (selectedData.includes(row.id)) {
                    setSelectedData(
                      selectedData.filter((item) => item !== row.id)
                    )
                  } else {
                    setSelectedData([...selectedData, row.id])
                  }
                }}
              />
              {/* <div
                  id="scrollableDivClient"
                  style={{ height: '100%', overflow: 'auto' }}
                >
                  <InfiniteScroll
                    dataLength={rows?.length ? rows.length : 0}
                    next={() => fetchMoreData()}
                    hasMore={hasMoreData}
                    loader={
                      !openClient && <Loader loading={true} caption={''} />
                    }
                    scrollableTarget="scrollableDivClient"
                  >
                    <ListTable
                      page={page}
                      setPage={setPage}
                      rows={rows}
                      headCells={headCells}
                      onClickFile={navigateOpenClient}
                      onClickFileKey={['documentName']}
                      isEditable={false}
                      pageType={'project-list'}
                      rowAction={rowAction}
                      multiSelect={isMerge}
                      rowSelection={rowSelection}
                      selectedRows={selectedData}
                      isSortActive={false}
                      paginate={false}
                    />
                  </InfiniteScroll>
                </div> */}
            </>
          )}
        </div>
        {/* ) : (
          <EmptyRecord displayMessage={'No clients found'} />
         */}
      </Section>

      {renderTagDrawer()}
    </Container>
  )
}

export default ClientList
