import {
  FETCH_RFX_DOCUMENTS,
  FETCH_RFX,
  UPDATE_RFX,
  DELETE_RFX
} from '../../actionTypes'
import { findIndex, cloneDeep } from 'lodash'

const initialState = {
  rfx: {},
  rfxDocuments: null
}

function rfxDocumentsList(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case FETCH_RFX_DOCUMENTS:
      return {
        ...state,
        rfxDocuments: payload
      }
    case FETCH_RFX:
      return {
        ...state,
        rfx: payload
      }
    case UPDATE_RFX: {
      const newRfx = cloneDeep(state.rfxDocuments)
      const index = findIndex(newRfx, { id: payload.id })
      newRfx[index] = payload
      return {
        ...state,
        rfxDocuments: newRfx
      }
    }
    case DELETE_RFX: {
      const newRfxList = cloneDeep(state.rfxDocuments)
      for (let i = 0; i < payload.length; i++) {
        const indexdel = newRfxList.findIndex(
          (item) => item.id === payload[i]
        )
        if (indexdel > -1) {
          newRfxList.splice(indexdel, 1)
        }
      }
      return {
        ...state,
        rfxDocuments: newRfxList
      }
    }
    default:
      return state
  }
}

export default rfxDocumentsList
