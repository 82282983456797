import { FETCH_PROJECTS, UPDATE_PROJECT_TAG } from '../../actionTypes'
import { findIndex, cloneDeep } from 'lodash'
const initialState = {
  projectList: null,
  hasMoreData: false,
  filters: {}
}

function projectsList(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case FETCH_PROJECTS: {
      const {
        project = [],
        pageNo,
        has_next_page,
        filters,
        is_individual_resume = false
      } = payload
      let projectListClone = cloneDeep(state?.projectList ?? [])
      if (pageNo === 1) {
        projectListClone = project
      } else {
        projectListClone.push(...project)
      }
      return {
        ...state,
        projectList: projectListClone,
        hasMoreData: has_next_page,
        isIndividualResume: is_individual_resume,
        filters
      }
    }
    case UPDATE_PROJECT_TAG: {
      const { id, ...rest } = payload
      const projectList = cloneDeep(state.projectList)
      const projectIndex = projectList?.findIndex(
        (project) => project.id === id
      )
      if (projectIndex > -1) {
        projectList[projectIndex] = {
          ...projectList[projectIndex],
          ...rest
        }
      }
      return {
        ...state,
        projectList
      }
    }
    default:
      return state
  }
}

export default projectsList
